import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';
import AccordionAtom, { Props as AccordionAtomProps } from '../atoms/AccordionAtom';

export interface Props extends ComponentBase {
  /**
   * If true rounded corners are disabled.
   */
  square?: boolean;
  /**
   * SVG element use as an icon
   */
  expandIcon?: string;
  /**
   * array of accordions with its props, etc.
   */
  accordionProps: AccordionAtomProps[];
}

const AccordionList: React.FC<Props> = ({
  square = true,
  expandIcon,
  accordionProps
}) => {
 
  // hook is a Set type in order to make it easy & fast to add/remove selected strings and have no duplicates
  const [isExpandedSet, setExpandedSet] = React.useState<Set<string>>(new Set());
  const classes = useStyles();

  const handleClick = (accordionId: string) => {
    if(isExpandedSet.has(accordionId)) {
      setExpandedSet((prevSet) => {
        const newSet = new Set(prevSet);
        newSet.delete(accordionId);
        return newSet;
      });
    } else {
      setExpandedSet((prevSet) => {
        const newSet = new Set(prevSet);
        newSet.add(accordionId);
        return newSet;
      });
    }
  };

  return (
    <div
      className={classes.root}
    >
      {accordionProps.map((accordion, index) => {
        const key = `${accordion.headline
          .substr(0, 5)
          .split(' ')
          .join('')}-${index}`;
        return (
          <AccordionAtom
            {...accordion}
            key={key}
            isOpen={isExpandedSet.has(key)}
            expandIcon={expandIcon}
            square={square}
            handleClick={() => handleClick(key)}
          />
        );
      })}
    </div>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {}
    }),
  { name: 'Mui-AccordionList' }
);

export default AccordionList;
