import React, { Reducer } from 'react';
import { State, ActionRoot, BeginPayload, UserResponse } from './CoreTypes';
import { initState, radioReducer } from './RadioReducer';
import { Translator } from '../RenderFunctions/AddApiTranslators';

type Dispatch = (action: ActionRoot) => void;
type ActionTriggers = {
  beginStep: (payload: BeginPayload) => void;
  finishStep: () => void;
  registerResponseComponent: (payload: UserResponse) => void;
  logResponse: (payload: UserResponse) => void;
  logResponseAndComplete: (payload: UserResponse) => void;
  networkErrorReset: () => void;
  loadingStep: () => void;
  showLoadingScreen: () => void;
  skipNextLoadingScreen: () => void;
  resetLoadingScreen: () => void;
  updateApiTranslators: (payload: { apiTranslators: Translator[] }) => void;
};

// Action Creators
const actionBeginStep = (dispatch: Dispatch, payload: BeginPayload) => {
  const action = {
    type: 'BEGIN_STEP',
    payload
  };
  dispatch(action as ActionRoot);
};

const actionFinishStep = (dispatch: Dispatch) => {
  const action = {
    type: 'FINISH_STEP'
  };
  dispatch(action as ActionRoot);
};

const actionRegisterResponseComponent = (
  dispatch: Dispatch,
  payload: UserResponse
) => {
  const action = {
    type: 'REGISTER_RESPONSE_COMPONENT',
    payload
  };
  dispatch(action as ActionRoot);
};

const actionLogResponse = (dispatch: Dispatch, payload: UserResponse) => {
  const action = {
    type: 'LOG_RESPONSE',
    payload
  };
  dispatch(action as ActionRoot);
};

const actionLogResponseAndComplete = (
  dispatch: Dispatch,
  payload: UserResponse
) => {
  const action = {
    type: 'LOG_RESPONSE_AND_COMPLETE',
    payload
  };
  dispatch(action as ActionRoot);
};

const actionNetworkErrorReset = (dispatch: Dispatch) => {
  const action = {
    type: 'NETWORK_ERROR_RESET'
  };
  dispatch(action as ActionRoot);
};

const actionLoadingStep = (dispatch: Dispatch) => {
  const action = {
    type: 'LOADING_STEP'
  };
  dispatch(action as ActionRoot);
};

const actionShowLoadingScreen = (dispatch: Dispatch) => {
  const action = {
    type: 'DISPLAY_LOADING_SCREEN'
  };
  dispatch(action as ActionRoot);
};

const actionSkipNextLoadingScreen = (dispatch: Dispatch) => {
  const action = {
    type: 'SKIP_NEXT_LOADING_SCREEN'
  };
  dispatch(action as ActionRoot);
};

const actionResetLoadingScreen = (dispatch: Dispatch) => {
  const action = {
    type: 'RESET_LOADING_SCREEN'
  };
  dispatch(action as ActionRoot);
};

const actionUpdateApiTranslators = (dispatch: Dispatch, payload: { apiTranslators: Translator[] }) => {
  const action = {
    type: 'UPDATE_API_TRANSLATORS',
    payload
  };
  dispatch(action as ActionRoot);
};

export const RadioStateContext = React.createContext<State>(initState);
export const RadioDispatchContext = React.createContext<
ActionTriggers | undefined | Record<string, () => void>
>(undefined);

export const RadioProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer<Reducer<State, ActionRoot>>(
    radioReducer,
    initState
  );

  // Triggers

  const beginStep = React.useCallback(
    (payload: BeginPayload) => {
      actionBeginStep(dispatch, payload);
    },
    [dispatch]
  );

  const finishStep = React.useCallback(() => {
    actionFinishStep(dispatch);
  }, [dispatch]);

  const registerResponseComponent = React.useCallback(
    (payload: UserResponse) => {
      actionRegisterResponseComponent(dispatch, payload);
    },
    [dispatch]
  );

  const logResponse = React.useCallback(
    (payload: UserResponse) => {
      actionLogResponse(dispatch, payload);
    },
    [dispatch]
  );

  const logResponseAndComplete = React.useCallback(
    (payload: UserResponse) => {
      actionLogResponseAndComplete(dispatch, payload);
    },
    [dispatch]
  );

  const networkErrorReset = React.useCallback(() => {
    actionNetworkErrorReset(dispatch);
  }, [dispatch]);

  const loadingStep = React.useCallback(() => {
    actionLoadingStep(dispatch);
  }, [dispatch]);

  const showLoadingScreen = React.useCallback(() => {
    actionShowLoadingScreen(dispatch);
  }, [dispatch]);

  const skipNextLoadingScreen = React.useCallback(() => {
    actionSkipNextLoadingScreen(dispatch);
  }, [dispatch]);

  const resetLoadingScreen = React.useCallback(() => {
    actionResetLoadingScreen(dispatch);
  }, [dispatch]);

  const updateApiTranslators = React.useCallback((payload: { apiTranslators: Translator[] }) => {
    actionUpdateApiTranslators(dispatch, payload);
  }, [dispatch]);

  return (
    <RadioStateContext.Provider value={state}>
      <RadioDispatchContext.Provider
        value={{
          beginStep,
          finishStep,
          registerResponseComponent,
          logResponse,
          logResponseAndComplete,
          networkErrorReset,
          showLoadingScreen,
          loadingStep,
          skipNextLoadingScreen,
          resetLoadingScreen,
          updateApiTranslators
        }}
      >
        {children}
      </RadioDispatchContext.Provider>
    </RadioStateContext.Provider>
  );
};

export const useRadioState = () => {
  const context = React.useContext(RadioStateContext);
  if (context === undefined) {
    throw new Error('useRadioState must be used within RadioProvider.');
  }
  return context;
};

export const useRadioDispatch = () => {
  const context = React.useContext(RadioDispatchContext);
  if (context === undefined) {
    throw new Error('useRadioDispatch must be used within RadioProvider.');
  }
  return context;
};
