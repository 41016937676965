import { ThemeStrings } from '../ThemeTypes';

export const strings: ThemeStrings = {
  brand: 'YourCase',
  brandUrl: 'https://yourcase.com',
  errorToast: {
    default: {
      message: 'Looks like there was a connection error. Please try again.',
      buttonLabel: 'Try Again'
    },
    deadLock: {
      message:
        'Oops looks like something broke. Please visit yourcase.com while we fix your experience.',
      buttonLabel: 'YourCase.com'
    }
  }
};
