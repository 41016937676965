import React from 'react';
import { ComponentBase } from '../../models/models';
import { useRadioDispatch } from '../Core/radioContext';

export type Translator = {
  translator: string,
  args: {
    category: string,
    translatorKeys: string[],
    accountId?: string | string[],
    controller?: string
  }
};

export interface Props extends ComponentBase {
  /**
   * an array of translators that will be used by the complete call.
   */
  translators: Translator[];
}

const AddApiTranslators: React.FC<Props> = ({ translators }) => {
  const { updateApiTranslators } = useRadioDispatch();

  React.useEffect(() => {
    updateApiTranslators({ apiTranslators: translators});
  }, []);
  return null;
};

export default AddApiTranslators;