import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { randomId } from '../../helpers/randomId';

type Props = {
  codeLength?: number;
  onCode: (value: Record<string, string>) => void;
};

const internalId = randomId();

const AuthCodeInput: React.FC<Props> = ({ codeLength = 6, onCode }) => {
  const inputRefs = React.useRef<Record<string, HTMLInputElement>>({});
  const [inputValues, setInputValues] = React.useState<Record<string, string>>(
    {}
  );

  /**
   * Initialize input fields.
   */
  React.useEffect(() => {
    const initInputValues: Record<string, string> = {};

    for (let i = 0; i < codeLength; i += 1) {
      // initInputValues[getInputName(i)] = `${i}`;
      initInputValues[getInputName(i)] = '';
    }
    setInputValues(initInputValues);
  }, [codeLength]);

  /**
   * Sync authCode up stream on change
   */
  React.useEffect(() => {
    onCode(inputValues);
  }, [inputValues, onCode]);

  /**
   * getInputIndex()
   * Extracts the input index from it's name string as a number.
   */
  const getInputIndex = (inputName: string) => {
    return parseInt(inputName.replace(`${internalId}-digitInput`, ''), 10);
  };

  /**
   * getInputName()
   * Generates an input name string from a supplied index number.
   */
  const getInputName = (index: number) => `${internalId}-digitInput${index}`;

  /**
   * focusSelectInput()
   * Sets focus on a named input and selects its text content.
   */
  const focusSelectInput = (inputName: string) => {
    inputRefs.current[inputName].focus();
    window.requestAnimationFrame(() => {
      inputRefs.current[inputName].setSelectionRange(0, 1);
    });
  };

  /**
   * setSpreadValue()
   * Takes a string and spreads it across multiple digit inputs.
   */
  const setSpreadValue = (values: string, initialInputName: string) => {
    let finalInput = initialInputName;
    const initialIndex = getInputIndex(initialInputName);
    const newState = { ...inputValues };

    values.split('').forEach((v, i) => {
      if (initialIndex + i < Object.keys(inputValues).length) {
        finalInput = getInputName(initialIndex + i);
        newState[finalInput] = v;
      }
    });

    setInputValues(newState);
    focusSelectInput(finalInput);
  };

  /**
   * handleKeyDown
   * Hijack keyboard events to make sure this does what it's supposed to.
   */
  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const target = ev.target as HTMLInputElement;
    const targetIndex = getInputIndex(target.name);

    switch (ev.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        ev.preventDefault();
        if (targetIndex > 0) {
          focusSelectInput(getInputName(targetIndex - 1));
        } else {
          focusSelectInput(target.name);
        }
        break;

      case 'ArrowRight':
      case 'ArrowDown':
        ev.preventDefault();
        if (targetIndex < Object.keys(inputValues).length - 1) {
          focusSelectInput(getInputName(targetIndex + 1));
        } else {
          focusSelectInput(target.name);
        }
        break;

      case 'Backspace':
        ev.preventDefault();

        if (target.value) {
          const newState = { ...inputValues };
          newState[target.name] = '';
          setInputValues(newState);
          break;
        }

        if (target.value === '' && targetIndex > 0) {
          // only focus since select gets quirky on backspace
          const prev = getInputName(targetIndex - 1);
          inputRefs.current[prev].focus();
        }
        break;

      default:
        if (ev.key.length === 1 && !(ev.metaKey || ev.altKey || ev.ctrlKey)) {
          ev.preventDefault();
          if (/^[\d|a-zA-Z]$/.test(ev.key)) {
            const newState = { ...inputValues };
            newState[target.name] = ev.key;
            setInputValues(newState);

            if (targetIndex < Object.keys(inputRefs.current).length - 1) {
              focusSelectInput(getInputName(targetIndex + 1));
            } else {
              focusSelectInput(getInputName(targetIndex));
            }
          }
        }
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {Object.keys(inputValues).map((k, i) => (
        <OutlinedInput
          key={k}
          value={inputValues[k]}
          name={k}
          inputRef={(el) => {
            inputRefs.current[k] = el;
          }}
          inputProps={{
            inputMode: 'numeric',
            autoComplete: 'one-time-code',
            'aria-label': `one-time code digit ${i + 1}`
          }}
          classes={{ root: classes.digitRoot, input: classes.digitInput }}
          onClick={(ev) => {
            focusSelectInput((ev.target as HTMLInputElement).name);
          }}
          onKeyDown={handleKeyDown}
          onChange={(ev) => {
            setSpreadValue(ev.target.value, ev.target.name);
          }}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        margin: theme.spacing(2, 1)
      },
      digitRoot: {
        margin: theme.spacing(1),
        display: 'inline-block'
      },
      digitInput: {
        fontSize: '2em',
        width: '1.5ch',
        textAlign: 'center'
      }
    }),
  { name: 'Mui-AuthCodeInput' }
);

export default AuthCodeInput;
