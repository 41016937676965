import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import AuthCodeInput from '../../atoms/AuthCodeInput';

type Props = {
  onChallenge: (authCode: string) => void;
  onResend: () => void;
  error?: string;
  classes?: Record<string, string>;
};

const SceneChallenge: React.FC<Props> = ({
  onChallenge,
  onResend,
  error = '',
  classes = {}
}) => {
  const [authCode, setAuthCode] = React.useState<Record<string, string>>({});
  return (
    <div className={clsx(classes.root, classes.scene)}>
      <p className={classes.textDirective}>
        Please enter your one-time use code.
      </p>

      <AuthCodeInput onCode={(value) => setAuthCode(value)} />

      {error && <p className={classes.textError}>{error}</p>}

      <footer className={classes.footer}>
        <Button
          variant="text"
          disableTouchRipple
          className={classes.button}
          onClick={onResend}
        >
          Resend Code
        </Button>

        <Button
          variant="contained"
          color="primary"
          disableTouchRipple
          onClick={() => onChallenge(Object.values(authCode).join(''))}
          className={classes.button}
        >
          Continue
        </Button>
      </footer>
    </div>
  );
};

export default SceneChallenge;
