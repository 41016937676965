import React from 'react';

import SingleSelectBase from '../../atoms/SingleSelectBase';

import { ComponentWithVariants } from '../../../models/models';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';

interface SelectOption {
  uuid: string;
  display: string;
  secondaryText?: string;
}

export interface Props extends ComponentWithVariants {
  /**
   * Whether the user is required to pick an option.
   */
  isRequired?: boolean;
  /**
   * Whether the step should finish when user selects a response.
   */
  completeStep?: boolean;
  /**
   * A unique id for accessibility.
   */
  uuid: string;
  /**
   * A short title displayed above the field.
   */
  label: string;
  /**
   * Array of {uuid:display} pairs
   */
  options: SelectOption[];
  /**
   * Text displayed when no value is set.
   */
  placeholder?: string;
  /**
   * defaultValue on dropdown select.
   */
  defaultValue?: string;
  /**
   * Short string below the select, useful for errors.
   */
  helperText?: string;
  /**
   * Red-ify the field.
   */
  error?: boolean;
  /**
   * SVG element use as an icon when dropdown is closed
   */
  closedIcon?: string;
  /**
   * SVG element use as an icon when dropdown is opened
   */
  openedIcon?: string;
  /**
   * variant.
   */
  dropdownVariant?: 'filled' | 'standard' | 'outlined';
}

const DropdownSingleSelect: React.FC<Props> = ({
  uuid,
  label,
  options,
  placeholder = '',
  helperText = '',
  error = false,
  isRequired = true,
  closedIcon,
  openedIcon,
  completeStep = false,
  dropdownVariant = 'standard',
  defaultValue
}) => {
  const {
    registerResponseComponent,
    logResponse,
    finishStep
  } = useRadioDispatch();
  const radioState = useRadioState();

  const initValue =
    (radioState.apiData.responses?.[uuid] as string) || defaultValue || '';
  const [selectValue, setSelectValue] = React.useState(initValue);

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'select',
      componentId: uuid,
      value: initValue || null,
      isRequired
    });
  }, [registerResponseComponent, initValue, uuid, isRequired]);

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const key = ev.target.value as string;
    setSelectValue(key);

    logResponse({
      componentId: uuid,
      value: ev.target.value as string
    });
    if (completeStep) {
      finishStep();
    }
  };

  return (
    <SingleSelectBase
      isRequired={isRequired}
      uuid={uuid}
      label={label}
      options={options}
      selectValue={selectValue}
      handleChange={handleChange}
      placeholder={placeholder}
      helperText={helperText}
      error={error}
      closedIcon={closedIcon}
      openedIcon={openedIcon}
      dropdownVariant={dropdownVariant}
    />
  );
};

export default DropdownSingleSelect;
