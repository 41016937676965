import { darken } from '@material-ui/core/styles';

import * as palette from './colors';
import {
  typographyVariants,
  customTypographyVariants
  // fontStack
} from './typography';
import { shadows } from './shadows';

const variants = {
  'Mui-SaveAndExitLeftPanel': {
    root: {
      ...customTypographyVariants.labelSmall,
      borderColor: palette.white,
      color: palette.white,
      borderRadius: '8px',
      padding: '8px 12px'
    }
  },
  'Mui-Body-CongratsBackgroundImage': {
    root: {
      backgroundImage:
        'url("https://assets.harbormaster.dev/mr-reset/images/container_background_cover_375.jpg")',
      backgroundSize: '90%',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#0e1f4a',
      padding: '32px 24px 0 24px',
      '@media (min-width: 500px)': {
        padding: '32px 80px 0 80px',
        backgroundImage:
          'url("https://assets.harbormaster.dev/mr-reset/images/container_background_cover_1440.jpg")',
        backgroundSize: '189%'
      },
      '@media (min-width: 600px)': {
        padding: '32px 80px 0 80px',
        backgroundImage:
          'url("https://assets.harbormaster.dev/mr-reset/images/container_background_cover_1440.jpg")',
        backgroundSize: '192%'
      },
      '@media (min-width: 960px)': {
        backgroundSize: '120%'
      },
      '@media (min-width: 1024px)': {
        padding: '32px 120px 0 120px'
      },
      '@media (min-width: 1300px)': {
        backgroundSize: '90%'
      }
    }
  },
  'Mui-BodyContent-BottomText': {
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '500px',
      justifyContent: 'center',
      paddingBottom: '60px',
      '@media (max-width: 960px)': {
        justifyContent: 'flex-end'
      }
    }
  },
  'Mui-BodyContent-Success': {
    root: {
      width: '100%',
      backgroundColor: palette.mrNavy[50],
      padding: '24px',
      maxWidth: '560px'
    }
  },
  'Mui-BodyContent-Large': {
    root: {
      maxWidth: '684px',
      '@media (max-width: 600px)': {
        width: 'calc(100% + 48px)'
      }
    }
  },
  'Mui-ShowHideComponents-Centered-121206': {
    root: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '1060px'
    }
  },
  'Mui-ShowHideComponents-Centered-12120604': {
    root: {
      display: 'flex',
      justifyContent: 'center',
    }
  },
  'Mui-Section-PaddingLess': {
    root: {
      padding: '0 !important'
    }
  },
  'Mui-Subsection-Card': {
    root: {
      border: `2px solid ${palette.mrNavy[100]}`,
      borderRadius: '8px',
      padding: '32px',
      boxSizing: 'border-box',
      height: '100%',
      position: 'relative',
      '@media (max-width: 600px)': {
        padding: '18px',
      }
    }
  },
  'Mui-Headline-Option': {
    root: {
      ...typographyVariants.h1,
      paddingBottom: '4px !important',
      margin: '-8px 0 0',
      '@media (max-width: 600px)': {
        fontSize: 24
      }
    }
  },
  'Mui-Headline-Option-Program': {
    root: {
      ...typographyVariants.h1,
      paddingTop: '12px',
      paddingBottom: '16px',
      margin: '0',
      '@media (max-width: 600px)': {
        fontSize: 24
      }
    }
  },
  'Mui-Headline-PaddingLess': {
    root: {
      ...typographyVariants.h1,
      paddingBottom: '4px !important',
      margin: 0,
      '@media (max-width: 600px)': {
        fontSize: 24
      }
    }
  },
  'Mui-Headline-Secondary': {
    root: {
      ...customTypographyVariants.sh1,
      color: palette.mrNavy[700],
      margin: 0,
      paddingBottom: '16px',
      marginTop: '-16px',
      '@media (max-width: 600px)': {
        fontSize: 24
      }
    }
  },
  'Mui-Headline-Transition': {
    root: {
      color: palette.white,
      ...typographyVariants.h1,
      '@media (max-width: 600px)': {
        marginBottom: '32px',
        fontSize: 24
      }
    }
  },
  'Mui-Image-Option': {
    root: {
      margin: '0 0 20px',
      justifyContent: 'flex-start'
    }
  },
  'Mui-List-Option': {
    root: {
      color: palette.mrNavy[700],
      padding: '0',
      marginBottom: '96px'
    },
    headline: {
      ...customTypographyVariants.bodyLargeMedium
    },
    itemContainer: {
      padding: '8px 0',
      display: 'flex',
      alignItems: 'flex-start',
      '& svg': {
        flexShrink: 0,
        padding: '0 8px 0 0'
      }
    },
    item: {
      ...customTypographyVariants.bodyRegular,
      paddingTop: '4px'
    }
  },
  'Mui-FabBase-Option': {
    root: {
      ...customTypographyVariants.labelLarge,
      transition: 'transform .5s ease, backgroundColor .5s ease',
      marginBottom: '16px',
      width: 'calc(100% - 64px)',
      position: 'absolute',
      bottom: '28px',
      '@media (max-width: 600px)': {
        width: 'calc(100% - 36px)'
      }
    },
    fabExtended: {
      height: '56px',
      '&$ghost': {
        height: '40px'
      }
    },
    default: {
      backgroundColor: palette.actionBlue[100],
      border: '2px solid transparent',
      borderRadius: '8px',
      color: palette.actionBlue[700],
      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: palette.actionBlue[200]
        }
      }
    }
  },
  'Mui-FabBase-Transition': {
    default: {
      backgroundColor: palette.actionBlue[500],
      borderRadius: '28px',
      '&:hover': {
        backgroundColor: palette.actionBlue[700],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.actionBlue[500]
        }
      },
      '&:active': {
        backgroundColor: palette.actionBlue[700]
      }
    }
  },

  'Mui-TileButtonBase-FabNav': {
    root: {
      ...customTypographyVariants.labelLarge,
      transition: 'transform .5s ease, backgroundColor .5s ease',
      marginBottom: '16px',
      backgroundColor: palette.actionBlue[500],
      borderRadius: '28px',
      height: '56px',
      width: '100%',
      '&:hover': {
        backgroundColor: palette.actionBlue[700],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.actionBlue[500]
        }
      },
      '&:active': {
        backgroundColor: palette.actionBlue[700]
      }
    }
  },
  'Mui-CardList-MarginBottom': {
    grouped: {
      marginBottom: '112px'
    }
  }
};
const keyValuePairs = {
  'Mui-KeyValuePair': {
    key: {
      ...customTypographyVariants.sh2
    },
    value: {
      ...customTypographyVariants.sh1
    }
  },
  'Mui-KeyValuePair-Small': {
    root: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingBottom: '18px'
    },
    keyContainer: {
      width: '144px',
      flexShrink: 0
    },
    key: {
      ...customTypographyVariants.bodyLargeMedium
    },
    value: {
      ...customTypographyVariants.bodyLarge
    }
  },
  'Mui-KeyValuePair-Variant2': {
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    key: {
      ...customTypographyVariants.bodyLarge
    },
    value: {
      ...customTypographyVariants.sh1,
      color: palette.actionBlue[500]
    }
  },
  'Mui-KeyValuePair-Variant2-Green': {
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    key: {
      ...customTypographyVariants.bodyLarge
    },
    value: {
      ...customTypographyVariants.sh1,
      color: palette.mrGreen[500]
    }
  },
  'Mui-KeyValuePair-Variant3': {
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    key: {
      ...customTypographyVariants.bodyRegular
    },
    value: {
      ...customTypographyVariants.sh2
    }
  },
  'Mui-KeyValuePair-Variant3-BottomMargin': {
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '112px'
    },
    key: {
      ...customTypographyVariants.bodyRegular
    },
    value: {
      ...customTypographyVariants.sh2
    }
  }
};

const common = {
  'Mui-SaveAndExitButton': {
    root: {
      ...customTypographyVariants.labelSmall,
      // borderColor: palette.mrNavy[100],
      backgroundColor: palette.mrOrange[500],
      color: palette.white,
      borderRadius: '8px',
      padding: '8px 12px',
      '&:hover': {
        backgroundColor: `${darken(palette.mrOrange[500], 0.1)} !important`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.mrOrange[500]
        }
      },
      '&:active': {
        backgroundColor: `${darken(palette.mrOrange[500], 0.15)} !important`
      }
    }
  },
  'Mui-ChipLabel': {
    root: {
      ...customTypographyVariants.labelSmall,
      padding: '3px 4px',
      borderRadius: '4px !important',
      height: 'inherit',
      '&$error': {
        backgroundColor: palette.mrRed[100],
        color: palette.mrRed[500]
      },
      '&$info': {
        backgroundColor: palette.actionBlue[100],
        color: palette.actionBlue[500]
      },
      '&$warning': {
        backgroundColor: palette.mrYellow[100],
        color: palette.mrYellow[500]
      },
      '&$success': {
        backgroundColor: palette.mrGreen[100],
        color: palette.mrGreen[500]
      }
    },
    label: {
      padding: 0
    }
  }
};

const layout = {
  'Mui-Body': {
    root: {
      backgroundColor: palette.white,
      padding: '32px 24px 0 24px',
      '@media (min-width: 600px)': {
        padding: '64px 80px 0 80px'
      }
    }
  },

  'Mui-Footer1': {
    root: {
      backgroundColor: 'transparent',
      padding: '16px 16px 16px !important',
      // borderTop: `1px solid ${palette.mrNavy[100]}`,
      height: 'auto',
      '@media (min-width: 600px)': {
        padding: '16px 16px 48px !important'
      },
      '@media (min-width: 1024px)': {
        left: '354px',
        width: 'calc(100% - 354px)'
      }
    }
  },

  'Mui-Footer2': {
    root: {
      backgroundColor: 'transparent',
      padding: '16px 16px 16px !important',
      // borderTop: `1px solid ${palette.mrNavy[100]}`,
      height: 'auto',
      '@media (min-width: 600px)': {
        padding: '16px 16px 48px !important'
      }
    }
  },

  'Mui-Body-Carousel': {
    root: {
      // backgroundColor: palette.mrNavy[50]
    }
  },

  'Mui-TopNavigation': {
    root: {
      backgroundColor: palette.white
    }
  },

  // For Modal backdrop
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(11, 24, 55, 0.8)'
    }
  }
};

const body = {
  'Mui-BodyContent': {
    root: {
      maxWidth: '560px'
    }
  },
  'Mui-Section': {
    root: {
      '&:last-child': {
        '@media (max-width: 600px)': {
          paddingBottom: '128px'
        }
      }
    }
  }
};

const footerNavigation = {
  // 'Mui-SingleLongButtonNavigation': {
  //   buttonLabel: {
  //     ...typography.h6,
  //   },
  //   buttonDisabled: {
  //     backgroundColor: 'rgb(224, 224, 224) !important',
  //   },
  // },
  // 'Mui-BottomNavigation': {
  //   fab: {
  //     boxShadow: shadows[5],
  //     '&:active': {
  //       boxShadow: shadows[11],
  //     },
  //   },
  //   fabDisabled: {
  //     backgroundColor: 'rgb(224, 224, 224) !important',
  //   },
  // },

  // marginTop: '48px',
  // '@media (max-width: 600px)': {
  //   marginTop: '16px'
  // },

  'Mui-FooterContent': {
    root: {
      display: 'flex',
      flexDirection: 'column-reverse',
      maxWidth: '560px',
      paddingTop: '16px',
      height: 'auto',
      '& > *:not(:first-child)': {
        marginBottom: '8px'
      },
      '@media (min-width: 600px)': {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '48px',
        '& > *:not(:last-child)': {
          marginRight: '16px'
        },
        '& > *:not(:first-child)': {
          margin: 0
        }
      }
    }
  },
  'Mui-FabBase': {
    root: {
      ...customTypographyVariants.labelLarge,
      transition: 'transform .5s ease, backgroundColor .5s ease',
      marginBottom: '16px'
    },
    default: {
      backgroundColor: palette.actionBlue[500],
      borderRadius: '28px',
      '&:hover': {
        backgroundColor: palette.actionBlue[700],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.actionBlue[500]
        }
      },
      '&:active': {
        backgroundColor: palette.actionBlue[700]
      }
    },
    inverse: {
      border: `1px solid ${palette.actionBlue[500]}`,
      color: palette.actionBlue[500],
      borderRadius: '28px',
      '& path': {
        fill: palette.actionBlue[500]
      },
      '&:hover': {
        backgroundColor: palette.actionBlue[100],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.white
        }
      }
    },
    fabExtended: {
      height: '56px',
      '&$ghost': {
        height: '40px'
      }
    },
    fabDisabled: {
      backgroundColor: `${palette.actionBlue[50]} !important`,
      border: '1px solid transparent !important',
      color: `${palette.actionBlue[400]} !important`,
      transform: 'translateY(8px)',
      '$ghost&': {
        backgroundColor: 'transparent !important',
        color: `${palette.actionBlue[400]} !important`
      }
    },
    fabFocusVisible: {
      border: `3px solid ${palette.actionBlue[400]}`
    },
    ghost: {
      backgroundColor: 'transparent',
      color: palette.actionBlue[700],
      '& path': {
        fill: palette.actionBlue[500]
      },
      '&:hover': {
        backgroundColor: palette.actionBlue[50],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      },
      '&:active': {
        backgroundColor: palette.actionBlue[50]
      }
    }
  }
};

const topNavigation = {
  'Mui-LogoTopNav': {
    logo: {
      width: '95px'
    }
  }
};

const leftPanel = {
  'Mui-LeftPanel': {
    root: {
      backgroundColor: palette.actionBlue[50],
      width: '354px'
    }
  },
  'Mui-ExperienceProgressContainer': {
    root: {
      marginTop: '24px',
      marginLeft: '-16px',
      width: '354px',
      boxSizing: 'border-box'
    }
  },
  'Mui-ProgressStage': {
    root: {
      padding: '0 28px',
      alignItems: 'center',
      marginBottom: '16px',
      '& path': {
        fill: palette.actionBlue[700]
      }
    },
    stepActive: {
      backgroundColor: palette.actionBlue[700],
      '& path': {
        fill: palette.white
      }
    },
    stepCompleted: {
      '& path': {
        fill: 'none'
      }
    },
    label: {
      // ...typography.caption,
      color: palette.actionBlue[700],
      ...customTypographyVariants.bodyLarge,
      '$stepActive &': {
        color: palette.white
      },
      '$stepCompleted &': {
        color: palette.actionBlue[700]
      }
    }
  }
};

const prompt = {
  /**
   *
   * All of these are pulled from Figma but feel
   * small and "off", but it's a starting point. :)
   *
   */
  'Mui-Headline': {
    root: {
      ...typographyVariants.h1,
      color: palette.mrNavy[700],
      margin: 0,
      paddingBottom: '16px',
      '@media (max-width: 600px)': {
        fontSize: 24
      }
    }
  },

  'Mui-Description': {
    root: {
      ...customTypographyVariants.bodyLarge,
      color: palette.mrNavy[700],
      margin: 0,
      paddingBottom: '8px',
      '@media (max-width: 600px)': {
        fontSize: '14px'
      },
      '& strong': {
        ...customTypographyVariants.bodyLargeMedium
      }
    },
    link: {
      ...customTypographyVariants.bodyLargeMedium,
      color: palette.actionBlue[500],
      textDecoration: 'none',
      transition: 'color .250s',
      '@media (max-width: 600px)': {
        fontSize: '14px'
      },
      '@media (pointer: fine)': {
        '&:hover': {
          filter: 'brightness(92%)'
        }
      }
    },
    unstyledButton: {
      color: palette.actionBlue[500],
      ...customTypographyVariants.bodyLargeMedium
    }
  },

  'Mui-ModalLink': {
    root: {
      ...customTypographyVariants.textLink,
      color: palette.mrNavy[400],
      margin: 0,
      paddingBottom: '8px'
    },
    link: {
      ...customTypographyVariants.textLink,
      color: palette.actionBlue[500],
      textDecoration: 'none',
      transition: 'color .250s',
      '@media (pointer: fine)': {
        '&:hover': {
          filter: 'brightness(92%)'
        }
      }
    }
  }
};

const popOuts = {
  // 'Mui-SnackbarLongBase': {
  //   snackbar: {
  //     backgroundColor: palette.ycGreen[500],
  //   },
  //   body: {
  //     ...typography.body2,
  //   },
  // },
  'Mui-SimpleModal': {
    root: {
      padding: '24px'
    },
    header: {
      borderBottom: 'none',
      padding: 0
    },
    closeButton: {
      alignSelf: 'flex-end',
      // height: 24,
      // width: 24,
      '& svg': {
        fill: 'red'
      }
    },
    headerTitle: {
      color: palette.mrNavy[700],
      ...typographyVariants.h2,
      paddingBottom: '16px'
    },
    content: {
      color: palette.mrNavy[700],
      ...customTypographyVariants.bodyLarge,
      padding: '0 0 24px',
      textAlign: 'justify',
      display: 'flex',
      flexDirection: 'column',
      '& h1': {
        marginTop: 24,
        marginBottom: 4
      },
      '& p:first-child': {
        marginTop: 0
      },
      '& p:last-child': {
        marginBottom: 0
      },
      '& a': {
        textDecoration: 'none',
        fontWeight: 500,
        color: palette.actionBlue[500],
        ...customTypographyVariants.bodyLargeMedium
      }
    },
    closeButtonFooter: {
      backgroundColor: palette.actionBlue[100],
      border: '2px solid transparent',
      borderRadius: '8px',
      color: palette.actionBlue[700],
      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: palette.actionBlue[200]
        }
      }
    }
  }
};
  
const atoms = {
  'Mui-ResponseLink': {
    root: {
      ...customTypographyVariants.bodyLargeMedium
    },
    link: {
      color: palette.actionBlue[500]
    }
  },
  
  'Mui-AccordionAtom': {
    root: {
      borderBottom: `2px solid ${palette.mrNavy[100]}`,
      '&:first-child': {
        borderTop: `2px solid ${palette.mrNavy[100]}`
      },
      '&:before': {
        backgroundColor: 'transparent'
      }      
    },
    headline: {
      ...customTypographyVariants.bodyLargeMedium,
      padding: '12px 0',
      margin: 0
    },
    headlineContainer: {
      '& path': {
        fill: palette.mrNavy[700]
      },
      margin: 0,
      padding: 0
    },
    body: {
      ...customTypographyVariants.bodyLarge,
      padding: '0 0 24px',
      margin: 0
    },
    bodyContainer: {
      margin: 0,
      padding: 0
    }
  },
  'Mui-CardAtomWithImage': {
    root:{
      boxShadow: shadows[5],
      borderRadius: '8px',
      '&:not(:last-child)': {
        marginBottom: '12px'
      }
    },
    topValueContainer: {
      marginBottom: '4px'
    },
    topValue: {
      color: palette.actionBlue[700],
      ...customTypographyVariants.sh3,
    },
    bottomValue: {
      color: palette.mrGreen[500],
      ...customTypographyVariants.bodyRegular
    }
  },
  'Mui-CardAtom': {
    root: {},
    checkedCard: {
      // border: `2px solid ${palette.actionBlue[500]} !important`
      border: '0px'
    },
    topRow: {
      marginBottom: '-2px'
    },
    topLeftValue: {
      ...customTypographyVariants.bodyLargeMedium
    },
    topRightValue: {
      ...customTypographyVariants.bodyLargeMedium
    },
    bottomLeftValue: {
      ...customTypographyVariants.labelSmall,
      color: palette.mrNavy[500]
    },
    bottomRightValue: {
      ...customTypographyVariants.labelSmall,
      color: palette.mrNavy[500]
    },
    single: {
      // border: `2px solid ${palette.mrNavy[100]}`
      border: '0px',
      boxShadow: shadows[5],
    },
    stacked: {
      // border: `2px solid ${palette.mrNavy[100]}`,
      // boxShadow: `0 10px 0 -6px ${palette.mrNavy[50]}`,
      border: '0px',
      boxShadow: shadows[5],
    },
    grouped: {},
    cover: {
      border: '0px',
      borderBottom: `2px solid ${palette.mrNavy[100]}`
    }
  },
  'Mui-ExpandableCard': {
    expandedRootContainer: {
      boxShadow: shadows[5],
      borderRadius: '8px'
    },
    expandButton: {
      border: `2px solid ${palette.mrNavy[100]}`,
      '& svg': {
        fill: palette.mrNavy[500]
      }
    },
    expandedGroupContainer: {
      marginTop: '-18px'
    }
  },
  'Mui-CheckboxInput': {
    input: {
      '& path': {
        color: palette.mrNavy[100]
      }
    },
    colorPrimaryCheckbox: {
      '&$checkedCheckbox': {
        '& svg': {
          color: `${palette.actionBlue[500]}`
        },
        '& path': {
          color: `${palette.actionBlue[500]}`
        }
      }
    }
  },
  'Mui-RadioInput': {
    colorPrimaryRadio: {
      '&$checkedRadio': {
        color: palette.actionBlue[500]
      }
    }
  },
  'Mui-SingleSelectBase': {
    root: {
      '& .MuiInput-underline': {
        '&:hover:not(.Mui-disabled)::before': {
          borderBottom: `2px solid ${palette.actionBlue[700]}`
        },
        '&:before': {
          borderBottom: `1px solid ${palette.mrNavy[100]}`
        },
        '&:after': {
          borderBottom: `2px solid ${palette.actionBlue[700]}`
        }
      }
    },
    label: {
      color: palette.mrNavy[700]
    },
    placeholder: {
      color: `${palette.mrNavy[500]}`
    },
    input: {
      color: `${palette.actionBlue[700]} !important`
    },
    inputIcon: {
      fill: palette.mrNavy[700]
    },
    menu: {
      boxShadow: shadows[4],
      borderRadius: '8px',
      padding: '8px'
    },
    menuItem: {
      color: palette.actionBlue[700],
      borderRadius: '4px',
      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: `${palette.actionBlue[100]} !important`,
          color: palette.actionBlue[700]
        }
      },
      '&:focus': {
        border: `2px solid ${palette.actionBlue[400]} !important`,
        color: palette.actionBlue[700]
      }
    },
    menuItemSelected: {
      backgroundColor: `${palette.actionBlue[100]} !important`,
      color: palette.actionBlue[700]
    },
    itemPrimaryText: {
      ...customTypographyVariants.bodyLarge
    },
    itemSecondaryText: {
      ...customTypographyVariants.labelSmall,
      color: palette.mrNavy[500],
      paddingTop: '3px'
    }
  },
  'Mui-TypeInBase': {
    root: {
      '& .Mui-error': {
        color: palette.mrOrange[500],
        '&:after': {
          borderBottomColor: palette.mrOrange[500]
        }
      },
      '& .MuiSvgIcon-root': {
        '& path': {
          fill: palette.mrNavy[700]
        }
      }
    },
    label: {
      color: palette.mrNavy[700]
    },
    input: {
      color: `${palette.actionBlue[700]} !important`,
      caretColor: palette.actionBlue[500],
      '&:before': {
        borderBottom: `1px solid ${palette.mrNavy[100]}`
      },
      '&:after': {
        borderBottom: `2px solid ${palette.actionBlue[700]}`
      },
      '& ::placeholder': {
        color: palette.mrNavy[500],
        opacity: 1
      }
    },
    underline: {
      '&:hover:not(.Mui-disabled)::before': {
        borderBottom: `2px solid ${palette.mrNavy[700]}`
      }
    }
  },
  'Mui-TileButtonBase': {
    root: {
      backgroundColor: palette.actionBlue[100],
      border: '2px solid transparent',
      borderRadius: '8px',
      color: palette.actionBlue[700],
      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: palette.actionBlue[200]
        }
      }
    },
    selectedOption: {
      backgroundColor: palette.actionBlue[100],
      border: `2px solid ${palette.actionBlue[500]}`,
      '.multi-select &': {
        backgroundColor: palette.actionBlue[100],
        border: `2px solid ${palette.actionBlue[500]}`,
        boxShadow: `0 0 0 1px ${palette.actionBlue[500]}`
      }
    },
    leadingIcon: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      '& $buttonLabel': {
        width: 'auto',
        margin: '0'
      },
      '& svg, img': {
        paddingRight: '16px',
        width: '24px',
        height: 'auto',
        position: 'initial',
        margin: '0 !important'
      }
    }
  },
  'Mui-FilestackUpload': {
    root:{
      flexGrow: 1
    }
  }
};

const lists = {
  'Mui-List': {
    root: {
      color: palette.mrNavy[700],
      backgroundColor: palette.mrNavy[50],
      padding: '32px 24px'
    },
    itemContainer: {
      padding: '8px 0',
      display: 'flex',
      alignItems: 'flex-start',
      '& svg': {
        flexShrink: 0,
        padding: '0 8px 0 0'
      }
    },
    item: {
      ...customTypographyVariants.bodyRegular,
      paddingTop: '4px'
    }
  },
  'Mui-AccordionList': {
    root: {
      width: '100%',
      border: 0
    }
  },
  'Mui-CardList': {
    grouped: {
      // border: `2px solid ${palette.mrNavy[100]}`
      border: '0px',
      boxShadow: shadows[5]
    },
    expandedGroup: {
      // border: `2px solid ${palette.mrNavy[100]}`
      border: '0px'
    },
    groupedCardContainer: {
      borderBottom: `2px solid ${palette.mrNavy[100]}`

    }
  }
};

const visual = {
  'Mui-Image': {
    root: {
      margin: '0 0 40px'
    }
  },
  'Mui-VideoPlayer': {
    root: {
      margin: '0 0 40px',
      backgroundColor: '#F5F8FF'
    },
    playButton: {
      backgroundColor: palette.actionBlue[500]
    },
    duration: {
      ...customTypographyVariants.labelSmall,
      color: palette.mrNavy[500]
    }
  },
  'Mui-ParsedHTML': {
    root: {
      ...customTypographyVariants.labelSmall,
      color: palette.mrNavy[500],
      '& a': {
        color: palette.actionBlue[500],
        textDecoration: 'none',
        transition: 'color .250s',
        '@media (pointer: fine)': {
          '&:hover': {
            filter: 'brightness(92%)'
          }
        }
      }
    }
  }
};

const progress = {
  'Mui-Stepper': {
    root: {
      margin: '0 24px',
      padding: '32px 0'
    },
    line: {
      width: '3px',
      backgroundColor: palette.actionBlue[700]
    },
    dot: {
      border: `solid 2px ${palette.actionBlue[700]}`
    },
    completed: {
      backgroundColor: palette.actionBlue[700]
    }
  }
};

export const overrides = {
  ...variants,
  ...keyValuePairs,
  ...common,
  ...layout,
  ...body,
  ...topNavigation,
  ...footerNavigation,
  ...leftPanel,
  ...prompt,
  ...popOuts,
  ...atoms,
  ...lists,
  ...visual,
  ...progress
};
