import React from 'react';

export function useDebounce<Payload extends any[]>(
  func: (...payload: Payload) => void,
  delay: number = 350
) {
  const payloadRef = React.useRef<Payload>();
  const timeout = React.useRef<ReturnType<typeof setTimeout>>();

  function cleanup() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  // clear timeout if component gets unmounted
  React.useEffect(() => cleanup, []);

  return function debounce(...payload: Payload) {
    payloadRef.current = payload;

    // clear timer
    cleanup();

    // start timer again
    timeout.current = setTimeout(() => {
      if (payloadRef.current) {
        func(...payloadRef.current);
      }
    }, delay);
  };
}
