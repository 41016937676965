import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentWithChildren } from '../../models/models';

const Layout: React.FC<ComponentWithChildren> = ({ children }) => {
  const classes = useStyles();

  return (
    <div data-testid="Layout" className={classes.root}>
      {children}
    </div>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        position: 'relative',
        minHeight: '100vh',
        width: '100%',
        overflow: 'hidden',
        fontSize: '16px',
        display: 'grid',
        gridTemplateAreas: '"TopNav TopNav" "LeftPanel Body"',
        gridTemplateRows: 'fit-content(56px) 1fr',
        gridTemplateColumns: 'fit-content(260px) 1fr',
        height: '100%',
        '@media (max-width: 600px)': {
          fontSize: '12px'
        },
        '@media (max-width: 1024px)': {
          gridTemplateColumns: '0 1fr'
        }
      }
    }),
  { name: 'Mui-Layout' }
);

export default Layout;
