import React from 'react';

import SnackbarLongBase from './SnackbarLongBase';
import { useRadioDispatch } from '../Core/radioContext';
import { useNoteContext } from './noteContext';
import { useExperienceThemeContext } from '../../styles/experienceThemeContext';

const ApiErrorToast: React.FC = () => {
  const { finishStep } = useRadioDispatch();
  const { theme } = useExperienceThemeContext();
  const { state: noteState, dispatch: noteDispatch } = useNoteContext();
  const toast = {
    text: theme.strings.errorToast.default.message,
    actions: {
      text: theme.strings.errorToast.default.buttonLabel,
      cb: () => {
        finishStep();
        noteDispatch({ type: 'HIDE_API_ERROR_TOAST' });
      },
    },
  };

  if (noteState.apiErrorDeadlock) {
    toast.text =
    theme.strings.errorToast.deadLock.message;
    toast.actions = {
      text: theme.strings.errorToast.deadLock.buttonLabel,
      cb: () => {
        window.open(theme.strings.brandUrl);
      },
    };
  }

  return (
    <>
      {noteState.showApiErrorToast && (
        <SnackbarLongBase
          isOpen
          handleCloseNotification={() => {}}
          body={toast.text}
          buttonLabel={toast.actions.text}
          handleClick={toast.actions.cb}
        />
      )}
    </>
  );
};

export default ApiErrorToast;
