import React from 'react';
import parse from 'html-react-parser';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

interface ListItem {
  /**
   * body of item.
   */
  item: string;
  /**
   * source of optional image component of listItem.
   */
  imageSource?: string;
  /**
   * icon in html form as a string that will be parsed.
   */
  icon?: string;
}

export interface Props extends ComponentBase {
  /**
   * array of list items.
   */
  listItems: ListItem[];
  /**
   * headline of list.
   */
  headline?: string;
  /**
   * text alignment.
   */
  headlineAlign?: 'left' | 'center' | 'right';
}

const List: React.FC<Props> = ({
  listItems,
  headline,
  headlineAlign = 'left'
}) => {
  // @ts-ignore Mui bug with spreading theme variables.
  const classes = useStyles({ headlineAlign });
  return (
    <div className={classes.root}>
      {headline && <h2 className={classes.headline}>{headline}</h2>}
      <ul className={classes.listContainer}>
        {listItems.map((listItem, index) => {
          const key = `${listItem.item
            .substr(0, 5)
            .split(' ')
            .join('')}-${index}`;
          return (
            <li className={classes.itemContainer} key={key}>
              {listItem.imageSource && (
                <img
                  src={listItem.imageSource}
                  className={classes.image}
                  alt="icon to accompany item"
                />
              )}
              {!listItem.imageSource && listItem.icon && parse(listItem.icon)}
              <span className={classes.item}>{listItem.item}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      root: {
        width: '100%',
        padding: theme.spacing(2, 0),
        color: theme.palette.primary.main,
        boxSizing: 'border-box'
      },
      headline: {
        padding: theme.spacing(0, 0, 2, 0),
        ...theme.typography.h2,
        fontSize: '32px',
        margin: 0,
        textAlign: ({ headlineAlign }) => headlineAlign
      },
      listContainer: {
        padding: 0,
        margin: 0
      },
      itemContainer: {
        padding: theme.spacing(1, 0),
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          flexShrink: 0,
          padding: theme.spacing(0, 1, 0, 0)
        }
      },
      image: {
        padding: theme.spacing(0, 1, 0, 0)
      },
      item: {
        ...theme.typography.body2
      }
    }),

  { name: 'Mui-List' }
);

export default List;
