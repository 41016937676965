import { UserResponse } from '../../../components/Core/CoreTypes';

/**
 * translateForDmnSignals()
 * Translates radioState into the "signal" format compatible
 * with the DMN data structure.
 *
 * @param res  content from radioState.responses
 * @returns a DMN Signal compatible object
 */

export const dmnSignals = (res: UserResponse[]) => {
  return res.reduce((acc: Record<string, any>, obj: UserResponse) => {
    switch (obj.componentType) {
      case 'button':
        if (obj.value) {
          acc[obj.componentId] = true;
        }
        break;
      case 'select':
        // to allow false as a value
        if(obj.value !== null && typeof obj.value !== 'undefined') {
          if (Array.isArray(obj.value)) {
            acc[`${obj.value[0]}`] = true;
          } else {
            // TODO: Tech Debt YCF-826
            acc[`${obj.value}`] = true;
          }
        }
        break;
      case 'multiSelect':
        if (Array.isArray(obj.value)) {
          (obj.value as string[]).forEach((response) => {
            acc[`${response}`] = true;
          });
        }
        break;
      case 'filestackUploadList':
        if (Array.isArray(obj.value) && obj.value.length > 0) {
          acc[obj.componentId] = JSON.stringify(obj.value);
        }
        break;
      case 'selectCardList_TEMP':
        if (typeof obj.value === 'object' && obj.value !== null) {
          // Because TS is being a jerk.
          const responseValue = obj.value as any;

          // Loop through all keys in the CardList resp comp value,
          // only add the truthy ones to the signals.
          Object.keys(responseValue).forEach((key) => {
            if (responseValue[key].value === true) {
              acc[key] = true;
            }
          });
        }

        break;
      case 'selectDateTime':
        if (obj.value) {
          acc[obj.componentId] = obj.value;
        }
        break;
      case 'input':
        if (obj.value) {
          acc[obj.componentId] = obj.value;
        }
        break;
      default:
        throw Error('Unrecognized component type');
    }
    return acc;
  }, {});
};
