import * as React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentWithVariants, Variants } from '../../models/models';
import { assets } from '../../theme';


export interface Props extends ComponentWithVariants {
  /**
   * What happens when the button is clicked.
   */
  onClick: () => void;
  /**
   * Prop that allows to hide XOutButton.
   */
  hideXOutButton?: boolean;
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid?: string; 
}

const XOutButton: React.FC<Props> = ({
  onClick,
  hideXOutButton = false,
  variant = 'main' as Variants
}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!hideXOutButton && (
        <IconButton aria-label="Close" onClick={() => onClick()}>
          <img src={assets.topNavigation.xOutButton[variant]} alt="" className={classes.iconButton} />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        display: 'flex',
        height: '100%',
        alignItems: 'center'
      },
      iconButton: {
        width: '20px',
        cursor: 'pointer'
      }
    }),
  { name: 'Mui-XOutButton' }
);

export default XOutButton;
