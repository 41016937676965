export const ycGreen = {
  900: '#064135',
  500: '#095D4D',
  300: '#3A7D70',
  100: '#7FABA2'
};

export const ycGrey = {
  800: '#424242',
  500: '#A3A3A3',
  300: '#E0E0E0',
  50: '#F6F6F6',
};

export const ycOrange = {
  500: '#FF9966',
};

export const ycRed = {
  900: '#A53A3A',
  500: '#ED5454',
  300: '#F07676',
};

export const ycYellow = {
  500: '#F4C753',
  300: '#FFE8AA',
  250: '#FFF2D3', // YC Yellow 300 + 48% white
};

export const ycSand = {
  600: '#DDD6CA',
  300: '#FAF5ED',
  200: '#FDFCF9'
};

export const white = '#FFFFFF';
