import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * The color of the component.
   */
  color?: 'primary' | 'secondary';
  /**
   * If true, the shrink animation is disabled. This only works if variant is indeterminate.
   */
  disableShrink?: boolean;
  /**
   * The size of the circle. If using a number, the pixel unit is assumed. If using a string, you need to provide the CSS unit, e.g '3rem'
   */
  size?: string | number;
  /**
   * The thickness of the circle.
   */
  thickness?: number;
  /**
   * The variant to use. Use indeterminate when there is no progress value.
   */
  variant?: 'determinate' | 'indeterminate' | 'static';
}

const CircularProgressIndicator: React.FC<Props> = ({
  color,
  disableShrink,
  size,
  thickness,
  variant
}) => {
  const classes = useStyles();

  return (
    <CircularProgress
      color={color}
      disableShrink={disableShrink}
      size={size}
      thickness={thickness}
      variant={variant}
      className={classes.root}
    />
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        marginTop: '6rem'
      }
    }),
  { name: 'Mui-CircularProgress' }
);

export default CircularProgressIndicator;
