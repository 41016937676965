import { UserResponse } from '../../../components/Core/CoreTypes';

/**
 * translateForUiData()
 * Bundles radioState responses into a nice format to be regurgitated
 * to the UI if the user returns to this step so we can rebuild the state.
 *
 * @param res  content from radioState.responses
 * @returns a RadioSDK compatible object
 */

export const uiData = (res: UserResponse[]) => {
  const responses = res.reduce(
    (acc: Record<string, any>, obj: UserResponse) => {
      if (obj.value) {
        acc[obj.componentId] = obj.value;
      }
      return acc;
    },
    {}
  );

  // We use a key in the event the UI ever needs to stash more than responses.
  return { responses };
};
