import React from 'react';
import { ComponentBase } from '../../models/models';
import { useRadioDispatch } from '../Core/radioContext';

export interface Props extends ComponentBase {
  /** Unique ID used by the backend to catch user behavior. */
  uuid: string;
  /** How to translate this component value, defaults to 'button'. */
  componentType?: string;
  /** Response component value, defaults to true. */
  value?: string | number | boolean;
  /** Milliseconds used to delay complete call, defaults to 0. */
  delayMillisec?: number;
}

const AutocompleteStep: React.FC<Props> = ({ uuid, componentType = 'button', value = true, delayMillisec = 0 }) => {
  const { registerResponseComponent, finishStep } = useRadioDispatch();
  
  React.useEffect(() => {
    setTimeout(() => {
      registerResponseComponent({
        componentId: uuid,
        componentType,
        value
      });
      finishStep();
    }, delayMillisec);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return null;
};

export default AutocompleteStep;