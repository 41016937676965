import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { ComponentWithChildren } from '../../models/models';

/**
     * TODO: some logic when the greater RadioSDK context provider
     * is decided on... or maybe the individual response components
     * can hook directly in up stream?
     */
export interface ResponseGridProps extends ComponentWithChildren {
  /**
   * Allow multiple answers on children components.
   */
  isMultiSelect?: boolean
}

const ResponseGrid: React.FC<ResponseGridProps> = ({
  children
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      {children}
    </Grid>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {}
    }),
  { name: 'Mui-ResponseGrid' }
);
export default ResponseGrid;