import { makeVariant } from '../../../utils/createTypography';

export const fontStack = {
  circular: '"Circular STD", Helvetica, Arial, sans-serif',
  tiempos: '"Tiempos Headline", Times, serif',
};

export const typographyVariants = {
  h1: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  h2: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  h3: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  h4: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '130%'
  })
};

export const customTypographyVariants = {
  sh1: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '32px',
    fontWeight: 450,
    lineHeight: '130%'
  }),
  sh2: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '20px',
    fontWeight: 450,
    lineHeight: '130%'
  }),
  sh3: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '18px',
    fontWeight: 450,
    lineHeight: '130%'
  }),
  bodyLarge: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '1rem',
    fontWeight: 450,
    lineHeight: '130%'
  }),
  bodyLargeMedium: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  bodyRegular: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '.875rem',
    fontWeight: 450,
    lineHeight: '130%'
  }),
  bodyRegularMedium: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '.875rem',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  labelLarge: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '120%'
  }),
  labelRegular: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '.875rem',
    fontWeight: 450,
    lineHeight: '120%'
  }),
  labelSmall: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '.75rem',
    fontWeight: 500,
    lineHeight: '120%'
  }),
  labelSmallUppercase: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '.75rem',
    fontWeight: 500,
    lineHeight: '120%'
  }),
  labelTiny: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '.625rem',
    fontWeight: 700,
    lineHeight: '120%'
  }),
  textLink: makeVariant({
    fontFamily: fontStack.circular,
    fontSize: '.875rem',
    fontWeight: 500,
    lineHeight: '120%'
  })
};

export const typography = {
  fontFamily: fontStack.circular, // the default font
  ...typographyVariants
};
