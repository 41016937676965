import React from 'react';
import parse from 'html-react-parser';
import { format, formatISO, isValid } from 'date-fns';

import { ComponentWithVariants } from '../../../models/models';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';
import TypeInBase from '../../atoms/TypeInBase';
import { useDebounce } from '../../../helpers/useDebounce';


export interface Props extends ComponentWithVariants {
  /**
   * Whether the user is required to fill in the textfield.
   */
  isRequired?: boolean;
  /**
   * Label displayed on top of Textfield.
   */
  label: string;
  /**
   * Unique ID from Json.
   */
  uuid: string;
  /**
   * Placeholder on Textfield.
   */
  placeholder?: string;
  /**
   * defaultValue on Textfield.
   */
  defaultValue?: string;
  /**
   * SVG element use as an icon when input is valid
   */
  validIcon?: string;
  /**
   * SVG element use as an icon when input is not valid
   */
  errorIcon?: string;
}

const TypeInDate: React.FC<Props> = ({
  isRequired = true,
  uuid,
  placeholder = 'MM/DD/YYYY',
  label,
  validIcon,
  errorIcon,
  defaultValue
}) => {
  const { registerResponseComponent, logResponse } = useRadioDispatch();
  const radioState = useRadioState();

  const initValue = radioState.apiData.responses?.[uuid] || defaultValue || '';

  const [value, setValue] = React.useState<string>(
    initValue && isValid(new Date(initValue))
      ? format(new Date(initValue), 'MM/dd/yyyy')
      : ''
  );
  const [error, setError] = React.useState<boolean>(false);
  const [endAdornment, setEndAdornment] = React.useState<string>();
  const errorMessage = 'Invalid date.';

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'input',
      componentId: uuid,
      value: initValue || null,
      isRequired
    });
    registerResponseComponent({
      componentType: 'input',
      componentId: `${uuid}_ff`,
      value: initValue || null,
      isRequired
    });
  }, [registerResponseComponent, uuid, isRequired, initValue]);

  const maskInput = (val: string) => {
    let maskedVal = val.replace(/[^0-9]/g, '');
    maskedVal = maskedVal.substr(0, 8);

    // Mask with forward slash
    if (maskedVal.length >= 3) {
      maskedVal = `${maskedVal.slice(0, 2)}/${maskedVal.slice(2)}`;
    }

    if (maskedVal.length >= 6) {
      maskedVal = `${maskedVal.slice(0, 5)}/${maskedVal.slice(5)}`;
    }

    return maskedVal;
  };

  const validateDate = (date: string) => {
    const validity =
      isValid(new Date(date)) && format(new Date(date), 'MM/dd/yyyy') === date;
    return validity;
  };

  const debounce = useDebounce((id, input) => {
    logResponse({
      componentId: id,
      value: input ? formatISO(new Date(maskInput(input))) : null
    });
    logResponse({
      componentId: `${id}_ff`,
      value: input ? format(new Date(maskInput(input)), 'yyyy-MM-dd') : null
    });
  }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const eValue = e.target.value;
    setValue(maskInput(eValue));
    const input = validateDate(maskInput(eValue)) ? eValue : null;
    // debounce logResponse dispatch with default 350ms delay
    debounce(uuid, input);
  };

  const handleValidation = () => {
    const isDateValid = validateDate(value);
    setError(!isDateValid);
    if (validIcon && isDateValid) {
      setEndAdornment(validIcon);
    }
    if (errorIcon && !isDateValid) {
      setEndAdornment(errorIcon);
    }
  };

  return (
    <TypeInBase
      isRequired={isRequired}
      uuid={uuid}
      label={label}
      placeholder={placeholder}
      maxCharLength={320}
      value={value}
      handleChange={handleChange}
      handleOnBlur={handleValidation}
      errorMessage={error ? errorMessage : null}
      error={error}
      endAdornment={
        endAdornment ? (parse(endAdornment) as JSX.Element) : undefined
      }
    />
  );
};

export default TypeInDate;
