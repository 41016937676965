import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * key text.
   */
  keyLabel?: string;
  /**
   * value label text.
   */
  valueLabel?: string;
}

const KeyValuePair: React.FC<Props> = (props) => {
  const { keyLabel, valueLabel } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {keyLabel && (
        <div className={classes.keyContainer}>
          <span className={classes.key}>{keyLabel}</span>
        </div>
      )}
      {valueLabel && (
        <div className={classes.valueContainer}>
          <span className={classes.value}>{valueLabel}</span>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme>(
  // @ts-ignore Mui bug with spreading theme variables.
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        ...theme.typography.body1,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        color: theme.palette.primary.main
      },
      keyContainer: {
        paddingRight: '16px',
        paddingBottom: '4px'
      },
      key: {},
      valueContainer: {},
      value: {}
    }),
  { name: 'Mui-KeyValuePair' }
);

export default KeyValuePair;
