import { PickerFileMetadata } from 'filestack-js';
import { randomId } from '../../helpers/randomId';
import {
  State,
  UserResponse,
  ActionRoot,
  ActionApi,
  ActionResponse,
  ComplexValueType
} from './CoreTypes';

export const initState: State = {
  traceId: randomId(), // a session id from RadioSDK.
  profileUuid: '', // userId from the API side.
  domain: '', // used on complete api calls.
  app: '', // used on complete api calls.
  flowId: '', // current flow, from API.
  stepId: '', // current step, from API.
  stepUuid: '', // a stepUuid, from API.
  responses: [], // UI state, holding cell for input values.
  apiData: {}, // UI state, page seed data from API.
  nodeTree: {}, // UI state, page JSON to render.
  stepComplete: false, // UI flag, flip to fire /complete call.
  canComplete: false, // UI flag, allows user to flip stepComplete.
  isLoading: false, // UI flag, triggered between call/response.
  hasLoadingScreen: false, // UI flag, if true step is taking long and loading screen should be displayed.
  isLoadingScreenEnabled: true, // UI flag, if true loading screen should be skipped for one step
  apiTranslators: [] // array of translators that will be used as part of the complete step process
};

// used to verify if the cardList component has at least one truthy response
const hasAtLeastOneAnswer = (obj: Record<string, ComplexValueType>) => {
  return (
    Object.keys(obj).filter((itemKey) => !!obj[itemKey].value).length > 0
  );
};

// type predicate to identify ComplexValueType
const isComplexValueType = (value: Record<string, ComplexValueType> | string[] | PickerFileMetadata[]): value is Record<string, ComplexValueType> => {
  return (!Array.isArray(value));
};

const haveAllRequired = (res: UserResponse[]) => {
  return (
    res.filter((item) => {
      if(item.value && typeof item.value === 'object' && isComplexValueType(item.value)) {
        return item.isRequired === true && !hasAtLeastOneAnswer(item.value);
      }
      return item.isRequired === true && !item.value;
    }).length === 0
  );
};

const apiReducer = (state: State, action: ActionApi): State => {
  switch (action.type) {
    case 'BEGIN_STEP':
      return { ...state, ...action.payload };
    case 'FINISH_STEP':
      // Disregard additional calls after stepComplete
      if (state.stepComplete) return state;
      return { ...state, stepComplete: true };
    case 'NETWORK_ERROR_RESET':
      return { ...state, stepComplete: false };
    case 'LOADING_STEP':
      return { ...state, isLoading: true };
    case 'DISPLAY_LOADING_SCREEN':
      // only show loading screen if isLoading is true, otherwise if beginStep
      // is dispatched before timeout ends on the next step the loading screen will be
      // shown right away
      return { ...state, hasLoadingScreen: state.isLoading };
    case 'SKIP_NEXT_LOADING_SCREEN':
      // flag that skips the loading screen from showing for one step
      return { ...state, isLoadingScreenEnabled: false };
    case 'RESET_LOADING_SCREEN':
      // flag that resets the loading screen to show on the next step
      return { ...state, isLoadingScreenEnabled: true };
    case 'UPDATE_API_TRANSLATORS':
      // updates the api translators
      return { ...state, apiTranslators: action.payload.apiTranslators };
    default:
      throw new Error('Action type not recognized.');
  }
};

const responseReducer = (
  state: UserResponse[],
  action: ActionResponse
): UserResponse[] => {
  switch (action.type) {
    case 'REGISTER_RESPONSE_COMPONENT': {
      let isComponentNew = true;

      // This was added due to new behavior of switching required state after registration
      // on dropdownEnabledTypeIns. As far as I could tell this will not affect anything else
      // but it would be good to keep an eye for any unintended consequences
      const existingResponses = state.map((item) => {
        if (item.componentId === action.payload.componentId) {
          isComponentNew = false;
          return { ...item, ...action.payload }; // updates isRequired state
        }
        return item;
      });

      if (isComponentNew) {
        const newComponent = {
          componentType: '',
          value: null,
          isRequired: true,
          ...action.payload
        };
        existingResponses.push(newComponent);
      }
      
      return [...existingResponses ];
    }
    case 'LOG_RESPONSE': {
      const responses = state.map((item) => {
        if (item.componentId === action.payload.componentId) {
          return { ...item, value: action.payload.value };
        }
        return item;
      });
      return responses;
    }
    // Used for large unregistered buttons, and Forward button.
    case 'LOG_RESPONSE_AND_COMPLETE': {
      // Disregard additional calls after stepComplete
      const responses = [
        ...state,
        {
          componentType: 'button',
          componentId: action.payload.componentId,
          value: true,
          isRequired: true
        }
      ];

      return responses;
    }
    default:
      throw new Error('Action type not recognized.');
  }
};

export const radioReducer = (state: State, action: ActionRoot): State => {
  switch (action.type) {
    case 'BEGIN_STEP':
      return apiReducer(initState, action);

    case 'FINISH_STEP':
      return apiReducer(state, action);

    case 'REGISTER_RESPONSE_COMPONENT': {
      const responses = responseReducer(state.responses, action);
      const canComplete = haveAllRequired(responses);
      return { ...state, responses, canComplete };
    }

    case 'LOG_RESPONSE': {
      const responses = responseReducer(state.responses, action);
      const canComplete = haveAllRequired(responses);
      return { ...state, responses, canComplete };
    }

    // Used for large unregistered buttons, and Forward button.
    case 'LOG_RESPONSE_AND_COMPLETE': {
      // Disregard additional calls after stepComplete
      if (state.stepComplete) return state;

      const responses = responseReducer(state.responses, action);

      return { ...state, responses, canComplete: true, stepComplete: true };
    }

    case 'NETWORK_ERROR_RESET':
      return apiReducer(state, action);

    case 'LOADING_STEP':
      return apiReducer(state, action);

    case 'DISPLAY_LOADING_SCREEN':
      return apiReducer(state, action);

    case 'SKIP_NEXT_LOADING_SCREEN':
      return apiReducer(state, action);

    case 'RESET_LOADING_SCREEN':
      return apiReducer(state, action);

    case 'UPDATE_API_TRANSLATORS':
      return apiReducer(state, action);
    default:
      throw new Error('Action type not recognized.');
  }
};
