import { makeVariant } from '../../../utils/createTypography';

const fontStack = '"Graphik", helvetica, arial, sans-serif';

const variants = {
  h1: makeVariant({
    fontFamily: fontStack,
    fontWeight: 600,
    fontSize: '2.625rem', // 42px
    lineHeight: '1.35em'
  }),
  h3: makeVariant({
    fontFamily: fontStack,
    fontWeight: 400,
    fontSize: '2.25em', // 36px
    lineHeight: '1.35em'
  }),
  h6: makeVariant({
    fontFamily: fontStack,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.125em',
    textTransform: 'none'
  }),
  body1: makeVariant({
    fontFamily: fontStack,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.25rem'
  }),
  body2: makeVariant({
    fontFamily: fontStack,
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.125rem'
  }),
  button: makeVariant({
    fontFamily: fontStack,
    fontSize: '0.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    lineHeight: '1.125rem'
  }),
  caption: makeVariant({
    fontFamily: fontStack,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.375rem'
  })
};

export const customTypographyVariants = {
  h3Mobile: makeVariant({
    fontFamily: fontStack,
    fontSize: '1.375rem',
    fontWeight: 400,
    lineHeight: '1.35em'
  }),
  buttonLarge: makeVariant({
    fontFamily: fontStack,
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  sh1: makeVariant({
    fontFamily: fontStack,
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '130%'
  }),
};

export const typography = {
  fontFamily: fontStack,
  ...variants
};
