/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';


interface Source {
  srcSet: string,
  type: string,
  media?: string
}

export interface Props extends ComponentBase {
  /**
   * img sources and types array.
   */
  sources?: Source[];
  /**
   * alt tag for the image displayed.
   */
  altTag?: string;
  /**
   * img src property
   */
  src: string;
  /**
  * img srcSet property
  */
  srcSet?: string;
  /**
  * img height property
  */
  height?: string;
  /**
  * img width property
  */
  width?: string;
  /**
  * sets a maximum percentage of the container
  */
  maxWidth: string;
}

const Image: React.FC<Props> = ({
  sources,
  altTag = '',
  src,
  srcSet,
  height, 
  width,
  maxWidth
}) => {
  const classes = useStyles({ src, maxWidth });
  

  return (
    <picture
      className={classes.root}
    >
      {sources && sources.map((item) => {
        return  (
          <source
            srcSet={item.srcSet}
            type={`image/${item.type}`}
            key={item.srcSet}
            media={item.media}
          />
        );
      })}
      <img src={src} srcSet={srcSet} alt={altTag} width={width} height={height} className={classes.image} />
    </picture>
  );
};

const useStyles = makeStyles<any, Props>(
  () =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'center'
      },
      image: {
        height: 'auto',
        maxWidth:({ maxWidth }) => maxWidth
      }
    }),
  { name: 'Mui-Image' }
);

export default Image;