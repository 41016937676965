import React, { Suspense } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * JSON data or URL to JSON.
   */
  source: any;
  /**
   * Whether to loop animation.
   */
  loop: boolean;
  /**
   * Width size.
   */
  width?: string;
}

// Suppress Jest errors when RadioSDK is installed in other projects.
let LazyLottiePlayer: any;
if (process.env.NODE_ENV !== 'test') {
  LazyLottiePlayer = React.lazy(() => import('react-lottie-player'));
}

const LottiePlayer: React.FC<Props> = ({ source, loop, width = '100%' }) => {
  const classes = useStyles({ source, loop, width });
  const [animationJson, setAnimationJson] = React.useState<object | null>(null);

  React.useEffect(() => {
    /**
     * Process the 'source' prop.
     * If string, confirm it's a URL and fetch the JSON.
     * If it's Lottie JSON directly, use it!
     */

    if (typeof source === 'string') {
      // Fetch the JSON.
      fetch(source)
        .then((res) => res.json())
        .then((json) => {
          setAnimationJson(json);
        })
        /* eslint-disable-next-line no-console */
        .catch((err) => console.error(err));

      return;
    }

    if (typeof source === 'object') {
      setAnimationJson(source);
    }
  }, [source]);

  return (
    <div className={classes.root}>
      <Suspense fallback={<div>Loading…</div>}>
        {animationJson && (
          <LazyLottiePlayer loop={loop} play animationData={animationJson} />
        )}
      </Suspense>
    </div>
  );
};

const useStyles = makeStyles<Theme, Props>(
  () =>
    createStyles({
      root: {
        // '& #lottie': {
        width: ({ width }) => width
        // }
      }
    }),
  { name: 'Mui-LottiePlayer' }
);

export default LottiePlayer;
