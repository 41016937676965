/**
 * TODO: get actual Reset language for error toasts.
 */

export const strings = {
  brand: 'Mr. Reset',
  brandUrl: 'https://mrreset.com',
  errorToast: {
    default: {
      message:
        "Looks like we're having a little Internet turbulence, and that didn't go through.",
      buttonLabel: 'Try Again',
    },
    deadLock: {
      message:
        "Sorry, we're unable to process your request right now. Please try again later.",
      buttonLabel: 'MrReset.com',
    },
  },
};
