/**
 * getUrlParams()
 * Gets that window.location.search into a nice keyed object.
 */
export const getUrlParams = () => {
  const search = window.location.search
    .substring(1)
    .replace(/&/g, '","')
    .replace(/=/g, '":"');

  if (!search) return {};

  return JSON.parse(`{ "${search}" }`, function reviver(key, value) {
    return key === '' ? value : decodeURIComponent(value);
  });
};

/**
 * getUtmParams()
 * Gets those Urchin Tracking Module analytics search params
 * in a very Segment-friendly object.
 */
export const getUtmParams = () => {
  const urlParams = getUrlParams();

  return Object.keys(urlParams).reduce(
    (acc: Record<string, string>, key: string) => {
      if (key.substr(0, 4) === 'utm_') {
        const shortKey = key === 'utm_campaign' ? 'name' : key.substr(4);
        acc[shortKey] = urlParams[key];
      }
      return acc;
    },
    {}
  );
};
