export const drsBlue = {
  900: '#16163F',
  700: '#18447E',
  500: '#3B6296',
  300: '#4054B2', // info blue (Chip)
  100: '#6EC1E4',
  50: '#F5F8FF' // info blue bg (chip)
};

export const drsGray = {
  main: '#54595F',
  body: '#7A7A7A',
  light: '#EAEAEA'
};

export const drsGreen = {
  500: '#3dab3d',
  100: '#DEF5D2',
  50: '#F4FEEF'
};

export const drsRed = {
  500: '#FF3535',
  100: '#FFD7D7',
  50: '#FFF5F5'
};

export const drsYellow = {
  500: '#D3B574',
  100: '#FFF3B3',
  50: '#FFFAE0'
};

export const white = '#FFFFFF';
