import React from 'react';

import SingleSelectBase, { SelectOption } from '../../../atoms/SingleSelectBase';

import { DateTime } from './DateTimeSelect';
import { ComponentWithVariants } from '../../../../models/models';


export interface Props extends ComponentWithVariants {
  /**
     * A unique id for accessibility.
     */
  uuid: string,
  /**
     * A short title displayed above the field.
     */
  label: string,
  /**
   * Text displayed when no value is set.
   */
  placeholder?: string,
  /**
   * Short string below the select, useful for errors.
   */
  helperText?: string,
  /**
   * Red-ify the field.
   */
  error?: boolean,
  /**
   * dateTimeStore array.
   */
  dateTimeStore: DateTime[],
  /**
   * index selected date option.
   */
  selectedDate: number | string,
  /**
   * index selected time option.
   */
  selectedTime: number | string,
  /**
   * hook to select a Date.
   */
  setSelectedTime: React.Dispatch<React.SetStateAction<number | string>>
}
  
const TimeSelect: React.FC<Props> = (
  {
    uuid,
    label,
    placeholder = '',
    helperText = '',
    error = false,
    dateTimeStore,
    selectedDate,
    selectedTime,
    setSelectedTime, 
  }
) => {

  const [timeOptions, setTimeOptions] = React.useState<SelectOption[]>([]);

  React.useEffect(()=> {
    if (!!dateTimeStore.length && (!!selectedDate || selectedDate === 0)) {
      setTimeOptions(
        dateTimeStore[selectedDate as number].times.map((time, index) => {
          return {
            display: time.display,
            value: time.value,
            uuid: index
          };
        })
      );
    }
  }, [dateTimeStore, selectedDate]);
  
  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const optionIndex = ev.target.value as number;
    setSelectedTime(optionIndex);
  };
  
  return (
    <SingleSelectBase 
      disabled={!selectedDate && selectedDate !== 0}
      uuid={uuid}
      label={label}
      options={!!timeOptions && timeOptions.length ? timeOptions : null}
      selectValue={selectedTime}
      handleChange={handleChange}
      placeholder={placeholder}
      helperText={helperText}
      error={error}
    />
  );
};
  
export default TimeSelect;