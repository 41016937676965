import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';


export interface Props extends ComponentBase {
  /**
   * Defines the number of pages/sections. 
   */
  count: number,
  /**
   * Defines the current page/section. 
   */
  page: number
}

const DotPagination: React.FC<Props> = ({ count, page }) => {
  const classes = useStyles();
  const sizeClasses = [
    classes.large,
    classes.medium,
    classes.small,
    classes.xSmall
  ];

  // Helper functions and variables for Dot size rules
  const roundedUpHalf = Math.ceil(count/2);

  const getDotSizeIndex = (dotsAwayFromMiddle: number): number => {
    return dotsAwayFromMiddle <= 3 ? dotsAwayFromMiddle : 3;
  };

  const compareToHalf = (
    value: number,
    sign: 'greater' | 'less',
    strict: boolean
  ) => {
    if (strict && sign === 'greater') {
      return value >= roundedUpHalf;
    }
    if (strict && sign === 'less') {
      return value <= roundedUpHalf;
    }
    if (!strict && sign === 'greater') {
      return value > roundedUpHalf;
    }
    // !strict && sign === 'less'
    return value < roundedUpHalf;
  };

  const compareToMiddleOrToPage = (halfComparison: boolean): number => {
    return halfComparison ? page : roundedUpHalf;
  };

  const getDotsAwayFrom = (value: number, subtract: number) => {
    return value - subtract;
  };

  return (
    <div className={classes.root}>
      {Array.from(Array(count)).map((_item, index) => {
        const dotClassesArray = [classes.dot];
        const position = index + 1;
        let middleOrPage;
        let dotsAwayFrom;

        // The following if/else statements translate into these rules:
        // 1. When having less than 5 pages only use large dots
        // 2. Dots between the current page and the middle are always large
        // 3. Outer dots between the middle and the end or the current page and the end go as follow
        // from closest to the middle/current to outer: Large, Medium, Small, and XSmall repeated until the last one.

        if (position === page) dotClassesArray.push(classes.current);
        if (count < 5) {
          dotClassesArray.push(classes.large);
        } else if (position > page) {
          if (compareToHalf(position, 'less', true)) {
            dotClassesArray.push(classes.large);
          } else {
            middleOrPage = compareToMiddleOrToPage(compareToHalf(page, 'greater', false));
            dotsAwayFrom = getDotsAwayFrom(position, (middleOrPage + 1));

            dotClassesArray.push(
              sizeClasses[getDotSizeIndex(dotsAwayFrom)]
            );
          }
        } else if (compareToHalf(position, 'greater', true)) {
          dotClassesArray.push(classes.large);
        } else {
          middleOrPage = compareToMiddleOrToPage(compareToHalf(page, 'less', false));
          dotsAwayFrom = getDotsAwayFrom((middleOrPage - 1), position);
          dotClassesArray.push(
            sizeClasses[getDotSizeIndex(dotsAwayFrom)]
          );
        }

        return (
          <div
            className={dotClassesArray.join(' ')}
            key={position}
            aria-label={`Page ${position}${
              position === page ? ', Current Page' : ''
            }`}
            aria-current={position === page}
          />
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      alignItems: 'center'
    },
    dot:{
      backgroundColor: theme.palette.grey[400],
      borderRadius: '50%',
      margin: '0 3px',
    },
    xSmall:{
      width: '2px',
      height: '2px',
    },
    small:{
      width: '4px',
      height: '4px',
    },
    medium:{
      width: '6px',
      height: '6px',
    },
    large:{
      width: '8px',
      height: '8px',
    },
    current:{
      backgroundColor: theme.palette.primary.main,
      width: '8px',
      height: '8px',
    },
  }), { name: 'Mui-DotPagination' }
);

export default DotPagination;
