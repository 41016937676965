import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, fade } from '@material-ui/core/styles';
import { ComponentWithChildren } from '../../models/models';

export interface Props extends ComponentWithChildren {
  disableRail?: boolean;
}

const ExperienceProgressContainer: React.FC<Props>= (
  {
    disableRail = false,
    children
  }
) => {
  const classes = useStyles();
  const className = clsx(classes.root, {
    [classes.progressRail]: !disableRail
  });
  return <ul className={className}>{children}</ul>;
};

const useStyles = makeStyles((theme) => 
  createStyles({
    root: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      position: 'relative'
    },
    progressRail: {
      '&::before': {
        background: fade(theme.palette.primary.contrastText, .2), 
        borderRadius: '10px',
        content: '""',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '4px',
      }
    }
  }), { name: 'Mui-ExperienceProgressContainer' }
);

export default ExperienceProgressContainer;