import React from 'react';
import parse from 'html-react-parser';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { InputBaseComponentProps } from '@material-ui/core';
import { ComponentBase } from '../../../models/models';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';
import TypeInBase from '../../atoms/TypeInBase';
import { useDebounce } from '../../../helpers/useDebounce';

export interface Props extends ComponentBase {
  /**
   * Whether the user is required to fill in the textfield.
   */
  isRequired?: boolean;
  /**
   * Label displayed on top of Textfield.
   */
  label: string;
  /**
   * Unique ID from Json.
   */
  uuid: string;
  /**
   * SVG element use as an icon when input is valid
   */
  validIcon?: string;
  /**
   * SVG element use as an icon when input is not valid
   */
  errorIcon?: string;
  /**
   * Placeholder on Textfield.
   */
  placeholder?: string;
  /**
   * defaultValue on Textfield.
   */
  defaultValue?: string;
  /**
   * Options to override the mask's default props.
   */
  maskOptions?: MaskedOptionsProps;
}

interface MaskedOptionsProps {
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: string;
  requireDecimal?: boolean;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
  integerLimit?: number;
}

interface CurrencyMaskCustomProps {
  inputRef: (ref: HTMLElement | null) => void;
}

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false
};

function CurrencyMaskCustom(maskedOptions: MaskedOptionsProps) {
  return function MaskCustom(props: CurrencyMaskCustomProps) {
    const { inputRef, ...other } = props;

    const currencyMask = createNumberMask({
      ...defaultMaskOptions,
      ...maskedOptions
    });

    return (
      <MaskedInput
        {...other}
        ref={(ref: MaskedInput | null) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={currencyMask}
      />
    );
  };
}

// removes currency sign and commas so value is a valid number as a string 
const cleanUpValue = (value: string) => {
  return (value.replace(/[^0-9\.-]+/g, ''));
};

const TypeInCurrency: React.FC<Props> = ({
  isRequired = true,
  uuid,
  label,
  validIcon,
  errorIcon,
  placeholder,
  maskOptions = {},
  defaultValue
}) => {
  const { registerResponseComponent, logResponse } = useRadioDispatch();
  const radioState = useRadioState();

  const initValue = radioState.apiData.responses?.[uuid] || defaultValue || '';

  // value is a masked string ie: $100,000
  const [value, setValue] = React.useState<string>(initValue);
  const [error, setError] = React.useState<boolean>(false);
  const [endAdornment, setEndAdornment] = React.useState<string>();
  const errorMessage = 'Invalid response.';
  const CustomMask = React.useRef(CurrencyMaskCustom(maskOptions));

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'input',
      componentId: uuid,
      value: cleanUpValue(initValue) || null,
      isRequired
    });
  }, [registerResponseComponent, uuid, initValue, isRequired]);

  const isValid = (input: string) => {
    return isRequired ? input.trim().length !== 0 : true;
  };

  const debounce = useDebounce((id, input) =>
    logResponse({ componentId: id, value: cleanUpValue(input) })
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);

    const input = isValid(e.target.value) ? e.target.value : null;
    // debounce logResponse dispatch with default 350ms delay
    debounce(uuid, input);
  };

  const handleOnBlur = () => {
    const isInputValid = isValid(value);

    setError(!isInputValid);
    if (validIcon && isInputValid) {
      setEndAdornment(validIcon);
    }
    if (errorIcon && !isInputValid) {
      setEndAdornment(errorIcon);
    }
  };

  return (
    <TypeInBase
      isRequired={isRequired}
      uuid={uuid}
      label={label}
      inputMaskComponent={
        CustomMask.current as React.FunctionComponent<InputBaseComponentProps>
      }
      placeholder={placeholder}
      value={value}
      handleChange={handleChange}
      handleOnBlur={handleOnBlur}
      errorMessage={error ? errorMessage : null}
      error={error}
      endAdornment={
        endAdornment ? (parse(endAdornment) as JSX.Element) : undefined
      }
    />
  );
};

export default TypeInCurrency;
