
import * as React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentWithChildren } from '../../models/models';



const TopNavigationGrid: React.FC<ComponentWithChildren> = ({ children }) => {

  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      {children}
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%'
    }
  }), { name: 'Mui-TopNavigationGrid' }
);

export default TopNavigationGrid;
