import React from 'react';
import Fab from '@material-ui/core/Fab';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// Fallback icons:
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import parse from 'html-react-parser';
import { ComponentBase } from '../../models/models';
import { useRadioDispatch, useRadioState } from '../Core/radioContext';
import { useNavigationContext } from '../Core/navigationContext';

export interface Props extends ComponentBase {
  /**
   * False if the user clicked the back button to get to this step
   */
  isStepLatest?: boolean;
  /**
   * False if only one response interaction is needed to fire next step automatically
   */
  waitForClick?: boolean;
  /**
   * Show the back button?
   */
  hideBackButton?: boolean;
  /**
   * Disable the back button?
   */
  disableBackButton?: boolean;
  /**
   * Show the forward button?
   */
  hideForwardButton?: boolean;
  /**
   * Disable the forward button?
   */
  disableForwardButton?: boolean;
  /**
   * Icon used for the back FAB
   */
  backIcon?: string;
  /**
   * Icon used for the forward FAB
   */
  forwardIcon?: string;
  /**
   * Disable the ripple animation?
   */
  disableRipple?: boolean;
  /**
   * Forward button props, uuid for forward button.
   */
  forwardButton: {
    uuid: string;
  };
  /**
   * Backward button props, uuid for backward button.
   */
  backwardButton: {
    uuid: string;
  };
}

const BottomNavigation: React.FC<Props> = ({
  // isStepLatest,
  waitForClick,
  hideBackButton = false,
  disableBackButton = false,
  hideForwardButton = false,
  disableForwardButton = false,
  backwardButton,
  backIcon,
  forwardIcon,
  disableRipple = true
}) => {
  const radioState = useRadioState();
  const { finishStep } = useRadioDispatch();
  const { goPreviousStep } = useNavigationContext();
  const classes = useStyles();

  const hasDefaultValue =
    radioState.apiData.responses &&
    Object.keys(radioState.apiData.responses).length > 0;

  const showForwardButton =
    !hideForwardButton && (waitForClick || hasDefaultValue);

  const handleForwardClick = () => {
    finishStep();
  };

  const handleBackwardClick = () => {
    // logResponseAndComplete({
    //   componentId: backwardButton.uuid
    // });
    console.log(backwardButton.uuid); // not today, TS!
    goPreviousStep();
  };

  return (
    <nav className={classes.root} aria-label="Footer">
      {!hideBackButton && (
        <Fab
          classes={{
            root: classes.fab,
            disabled: classes.fabBackDisabled
          }}
          className={classes.fabBackward}
          disabled={disableBackButton}
          onClick={handleBackwardClick}
          aria-label="Back"
          disableRipple={disableRipple}
        >
          {backIcon ? (
            parse(backIcon)
          ) : (
            <ArrowBackIcon className={classes.backIcon} />
          )}
        </Fab>
      )}
      {showForwardButton && (
        <Fab
          classes={{
            root: classes.fab,
            disabled: classes.fabForwardDisabled
          }}
          className={classes.fabForward}
          disabled={disableForwardButton || !radioState.canComplete}
          onClick={handleForwardClick}
          aria-label="Forward"
          disableRipple={disableRipple}
        >
          {forwardIcon ? (
            parse(forwardIcon)
          ) : (
            <ArrowForwardIcon className={classes.forwardIcon} />
          )}
        </Fab>
      )}
    </nav>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%'
      },
      fab: {
        pointerEvents: 'all'
      },
      fabBackward: {},
      fabForward: {
        background: theme.palette.secondary.main,
        marginLeft: 'auto',
        '&:hover': {
          background: theme.palette.secondary.dark,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: theme.palette.secondary.main
          }
        }
      },
      fabBackDisabled: {},
      fabForwardDisabled: {},
      backIcon: {},
      forwardIcon: {}
    }),
  { name: 'Mui-BottomNavigation' }
);

export default BottomNavigation;
