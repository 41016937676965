import React from 'react';
import parse from 'html-react-parser';
import { ComponentWithVariants } from '../../../models/models';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';
import TypeInBase from '../../atoms/TypeInBase';
import { useDebounce } from '../../../helpers/useDebounce';


export interface Props extends ComponentWithVariants {
  /**
   * Whether the user is required to fill in the textfield.
   */
  isRequired?: boolean;
  /**
   * Label displayed on top of Textfield.
   */
  label?: string;
  /**
   * Unique ID from Json.
   */
  uuid: string;
  /**
   * Placeholder on Textfield.
   */
  placeholder?: string;
  /**
   * defaultValue on Textfield.
   */
  defaultValue?: string;
  /**
   * SVG element use as an icon when input is valid
   */
  validIcon?: string;
  /**
   * SVG element use as an icon when input is not valid
   */
  errorIcon?: string;
}

const TypeInEmail: React.FC<Props> = ({
  isRequired = true,
  uuid,
  placeholder,
  label = 'Email Address',
  validIcon,
  errorIcon,
  defaultValue
}) => {
  const { registerResponseComponent, logResponse } = useRadioDispatch();
  const radioState = useRadioState();

  const initValue = radioState.apiData.responses?.[uuid] || defaultValue || '';

  const [value, setValue] = React.useState<string>(initValue);
  const [error, setError] = React.useState<boolean>(false);
  const [endAdornment, setEndAdornment] = React.useState<string>();
  const errorMessage = 'Invalid email address.';

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'input',
      componentId: uuid,
      value: initValue || null,
      isRequired
    });
  }, [registerResponseComponent, uuid, initValue, isRequired]);

  const validateEmail = (input: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(input).toLowerCase());
  };

  const debounce = useDebounce((id, input) =>
    logResponse({ componentId: id, value: input })
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);

    const input = validateEmail(e.target.value) ? e.target.value : null;
    // debounce logResponse dispatch with default 350ms delay
    debounce(uuid, input);
  };

  const handleValidation = () => {
    const isValid = validateEmail(value);
    setError(!isValid);
    if (validIcon && isValid) {
      setEndAdornment(validIcon);
    }
    if (errorIcon && !isValid) {
      setEndAdornment(errorIcon);
    }
  };

  return (
    <TypeInBase
      isRequired={isRequired}
      uuid={uuid}
      label={label}
      placeholder={placeholder}
      maxCharLength={320}
      value={value}
      handleChange={handleChange}
      handleOnBlur={handleValidation}
      errorMessage={error ? errorMessage : null}
      error={error}
      endAdornment={
        endAdornment ? (parse(endAdornment) as JSX.Element) : undefined
      }
    />
  );
};

export default TypeInEmail;
