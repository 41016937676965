import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ComponentWithChildrenAndVariants } from '../../models/models';


const Footer1: React.FC<ComponentWithChildrenAndVariants> = (
  {children, 
    // variant='main'
  }) => {
  const classes = useStyles();
  return <footer className={classes.root}>{ children }</footer>;
};

const useStyles = makeStyles((theme) => 
  createStyles({
    root: {
      bottom: 0,
      boxSizing: 'border-box',
      display: 'flex',
      height: '80px',
      justifyContent: 'center',
      left: 0,
      padding: theme.spacing(1, 2, 2, 2), 
      pointerEvents: 'none',
      position: 'fixed',
      width: '100%',
      '@media (min-width: 512px)':{
        padding: theme.spacing(1, 10, 2, 10), 
      },
      '@media (min-width: 1024px)': {
        left: '260px',
        width: 'calc(100% - 260px)',
        padding: theme.spacing(1, 15, 2, 15), 
      }
    }
  }), { name: 'Mui-Footer1' }
);


export default Footer1;