import React from 'react';

type MakeVariantTypeArgs = {
  fontFamily: string;
  fontSize: React.CSSProperties['fontSize'];
  fontWeight: React.CSSProperties['fontWeight'];
  textTransform?: React.CSSProperties['textTransform'];
  lineHeight?: React.CSSProperties['lineHeight'];
  letterSpacing?: React.CSSProperties['letterSpacing'];
};

// To keep everything TypeScript compliant.
export const makeVariant = ({
  fontFamily,
  fontSize: size,
  fontWeight,
  textTransform = undefined,
  lineHeight = undefined,
  letterSpacing = undefined,
}: MakeVariantTypeArgs) => {
  return {
    fontFamily,
    fontSize: typeof size === 'number' ? `${size}px` : size,
    fontWeight,
    ...(textTransform ? { textTransform } : {}),
    ...(lineHeight ? { lineHeight } : {}),
    ...(letterSpacing ? { letterSpacing } : {}),
  };
};

// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
export const commonFontWeights = {
  thin: 100,
  hairline: 100,
  extraLight: 200,
  ultraLight: 200,
  light: 300,
  normal: 400,
  regular: 400,
  medium: 500,
  semiBold: 600,
  demiBold: 600,
  bold: 700,
  extraBold: 800,
  ultraBold: 800,
  black: 900,
  heavy: 900,
  extraBlack: 950,
  ultraBlack: 950,
};
