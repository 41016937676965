/**
 * The UI maintains a consistent data structure across all response
 * component types, these functions translate that structure to something
 * RadioAPI and other services need.
 */

import { dmnSignals } from './dmnSignals';
import { uiData } from './uiData';

// Trade Line Analyzer
import { 
  tradelineAnalyzerAccountList, tradelineAnalyzerAccountListPlus, 
  tradelineAnalyzerAccountListDisplayOnly, tradelineAnalyzerSingleAccount, 
  tradelineAnalyzerSingleAccountOneToMany, tradelineAnalyzerSingleAccountDocumentUpload 
} from './tda';

/**
 *
 * Create an object for export since TS wasn't happy with
 * a direct export + misc import.
 */
const apiTranslators: Record<string, any> = {
  dmnSignals,
  uiData,

  tradelineAnalyzerAccountList,
  tradelineAnalyzerAccountListPlus,
  tradelineAnalyzerAccountListDisplayOnly,
  tradelineAnalyzerSingleAccount,
  tradelineAnalyzerSingleAccountOneToMany,
  tradelineAnalyzerSingleAccountDocumentUpload
};

export default apiTranslators;
