import React from 'react';
import { Children, PropertiesBase, ComponentGeneric } from '../models/models';

interface ComponentMapInterface {
  [key: string]: React.FC<any>;
}

type RendererFunctions =
  | 'redirectTo'
  | 'addBeforeUnloadAlert'
  | 'removeBeforeUnloadAlert'; // Other functions can be added, ie: 'redirectTo' | 'otherFunction' | 'thirdFunction'

type FunctionMapInterface = Record<RendererFunctions, (props: any) => void>;

// Renderer Functions: (If we get to a point where we have more functions we can import them instead of having them in this file)

type RedirectTo = {
  url: string;
  delayMillisec?: number;
};

const redirectTo = (props: RedirectTo) => {
  if (props.delayMillisec) {
    setTimeout(() => {
      window.location.assign(props.url);
    }, props.delayMillisec);
  } else {
    window.location.assign(props.url);
  }
};

const onCloseTab = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
};

// Pop browser alert when user tries to navigate away.

let isBeforeUnloadAlertActive = false;

const addBeforeUnloadAlert = () => {
  if (isBeforeUnloadAlertActive === false) {
    window.addEventListener('beforeunload', onCloseTab);
    isBeforeUnloadAlertActive = true;
  }
};

const removeBeforeUnloadAlert = () => {
  if (isBeforeUnloadAlertActive === true) {
    window.removeEventListener('beforeunload', onCloseTab);
    isBeforeUnloadAlertActive = false;
  }
};

const FunctionMap: FunctionMapInterface = {
  redirectTo,
  addBeforeUnloadAlert,
  removeBeforeUnloadAlert
};

const MakeRenderer: Function = function makeRenderer(
  componentMap: ComponentMapInterface
) {
  return function renderer(jsonBlock: ComponentGeneric | Children) {
    const isEmpty = <T,>(value: T): boolean => {
      return Object.keys(value).length === 0;
    };

    // builder creates the DOM elements
    const builder = (
      component: React.FC,
      props: PropertiesBase,
      children: ComponentGeneric | Children = {}
    ): React.FunctionComponentElement<{}> => {
      return React.createElement(
        component,
        props,
        !isEmpty(children) && renderer(children)
      );
    };

    // transformer maps JSON to actual components and creates empty node if component not in library
    const transformer = (component: ComponentGeneric) => {
      const componentName = Object.keys(component)[0];

      // Type predicate for React.FC type
      const isComponentInLibrary = (
        mappedComponent: React.FC<any> | undefined
      ): mappedComponent is React.FC => {
        return typeof mappedComponent !== 'undefined';
      };

      const emptyNode: React.FC = () => (
        <div>{`The component ${componentName} has not been created yet.`}</div>
      );

      const mappedComponent: React.FC<any> | undefined =
        componentMap[componentName as keyof ComponentGeneric];

      if (!isComponentInLibrary(mappedComponent)) {
        return builder(emptyNode, { key: `EmptyNode-${Math.random()}` });
      }

      // Parses children and props arguments for different cases it can encounter
      const { properties = {}, ...rest } = component[
        componentName as keyof ComponentGeneric
      ] as ComponentGeneric;
      const children: Children | ComponentGeneric = rest?.children
        ? rest.children
        : rest;
      const props = { ...properties, key: `${componentName}-${Math.random()}` };
      return builder(mappedComponent, props, children);
    };

    const arrayIterator = (array: Children) => {
      return array.map((component) => {
        return transformer(component);
      });
    };

    const objectIterator = (object: ComponentGeneric) => {
      return Object.keys(object).map((key) => {
        // When encountering a Renderer Function
        if (key in FunctionMap) {
          
          return FunctionMap[key as keyof FunctionMapInterface](
            object[key as keyof FunctionMapInterface]
          );
        }
        const component = {
          [key as keyof ComponentGeneric]: object[key as keyof ComponentGeneric]
        };
        return transformer(component);
      });
    };

    // Type predicate for Children type
    const isArray = (block: ComponentGeneric | Children): block is Children => {
      return !!Array.isArray(block);
    };

    if (isArray(jsonBlock)) {
      return arrayIterator(jsonBlock);
    }
    return objectIterator(jsonBlock);
  };
};

export default MakeRenderer;
