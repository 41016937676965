import React from 'react';
import { format, formatISO } from 'date-fns';

import Grid from '@material-ui/core/Grid';

import GridItem06 from '../../../GridItems/GridItem06';
import DateSelect from './DateSelect';
import TimeSelect from './TimeSelect';

import { ComponentWithVariants } from '../../../../models/models';
import { useRadioDispatch } from '../../../Core/radioContext';

export interface Props extends ComponentWithVariants {
  /**
   * A unique id for accessibility.
   */
  uuid: string;
  /**
   * A short title displayed above the date field.
   */
  labelDate: string;
  /**
   * A short title displayed above the time field.
   */
  labelTime: string;
  /**
   * Text displayed when no value for date is set.
   */
  placeholderDate?: string;
  /**
   * Text displayed when no value for time is set.
   */
  placeholderTime?: string;
  /**
   * Short string below the select date, useful for errors.
   */
  helperTextDate?: string;
  /**
   * Short string below the select time, useful for errors.
   */
  helperTextTime?: string;
  /**
   * Red-ify the date field.
   */
  errorDate?: boolean;
  /**
   * Red-ify the time field.
   */
  errorTime?: boolean;
}

export type DateOptions = {
  display: string;
  value: Date;
};

export type DateTime = {
  date: DateOptions;
  times: DateOptions[];
};

const addMinutes = (date: Date, interval: number) => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + interval * 15);
  return newDate;
};

const populateTimeOptions = (dayOptionsArray: DateOptions[]) => {

  const timeArray: DateOptions[] = [];

  // Looping through today, tomorrow and next day
  dayOptionsArray.forEach((date) => {
    const datePlus15 = addMinutes(date.value, 1);
    let startingHour;
    let availableBlocks;

    // Mon-Fri
    if (date.value.getDay() >= 1 && date.value.getDay() < 6) {
      startingHour = '3';
      availableBlocks = 56;
      // Sat & Sun
    } else {
      startingHour = '5';
      availableBlocks = 32;
    }
    const startingDateTime = new Date(
      `${format(
        date.value as Date,
        'MM/dd/yyyy'
      )} ${startingHour}:00:00 PM UTC`
    );
    for (let i = 0; i <= availableBlocks; i += 1) {
      const newDateTime = addMinutes(startingDateTime, i);
      let futureTimeOption;
      let rightNowTimeOption;
      if(date.display === 'Today') {
        futureTimeOption = newDateTime > datePlus15; // To filter any date that is within the next 15mins of the today option
        rightNowTimeOption = date.value > startingDateTime && newDateTime > date.value; // you can only add right now if its between working hours
      } else {
        futureTimeOption = true;
      }

      if(!timeArray.some(time => time.display === 'Right now') && rightNowTimeOption){
        timeArray.push({
          display: 'Right now',
          value: date.value
        });
      }
      futureTimeOption &&
        timeArray.push({
          display: format(addMinutes(startingDateTime, i), 'hh:mm a'),
          value: addMinutes(startingDateTime, i),
        });
    }
  });
  return timeArray;
};

const populateDateTimeStore = (
  dayOptions: DateOptions[],
  timeOptions: DateOptions[]
) => {
  const storeArray: DateTime[] = [];
  let todayFiltered: DateOptions[] = [];
  let tomorrowFiltered: DateOptions[] = [];
  let theNextDayFiltered: DateOptions[] = [];
  dayOptions.forEach((day) => {
    if (day.display === 'Today') {
      todayFiltered = timeOptions.filter(
        (time) =>
          time.value.toString().includes(format(day.value, 'E MMM dd y')) ===
          true
      );
      // push today only if there is time availability
      todayFiltered.length > 0 &&
        storeArray.push({ date: day, times: todayFiltered });
    } else if (day.display === 'Tomorrow') {
      tomorrowFiltered = timeOptions.filter(
        (time) =>
          time.value.toString().includes(format(day.value, 'E MMM dd y')) ===
          true
      );
      storeArray.push({ date: day, times: tomorrowFiltered });
    } else {
      theNextDayFiltered = timeOptions.filter(
        (time) =>
          time.value.toString().includes(format(day.value, 'E MMM dd y')) ===
          true
      );
      storeArray.push({ date: day, times: theNextDayFiltered });
    }
  });
  return storeArray;
};

const DateTimeSelect: React.FC<Props> = ({
  uuid,
  labelDate,
  labelTime,
  placeholderDate = '',
  placeholderTime = '',
  helperTextDate = '',
  helperTextTime = '',
  errorDate = false,
  errorTime = false,
}) => {
  const [currentDateTime, setCurrentDateTime] = React.useState<Date>(
    new Date()
  );
  const [selectedDate, setSelectedDate] = React.useState<number | string>(''); // index value
  const [selectedTime, setSelectedTime] = React.useState<number | string>(''); // index value
  const [dateTimeSignal, setDateTimeSignal] = React.useState<string>('');
  const [timeOptions, setTimeOptions] = React.useState<DateOptions[]>([]);
  const [dayOptions, setDayOptions] = React.useState<DateOptions[]>([]);
  const [dateTimeStore, setDateTimeStore] = React.useState<DateTime[]>([]);

  const { registerResponseComponent, logResponse } = useRadioDispatch();

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'selectDateTime',
      componentId: uuid,
    });
  }, [registerResponseComponent, uuid]);

  React.useEffect(() => {
    if (
      typeof selectedTime === 'number' &&
      typeof selectedDate === 'number' &&
      dateTimeStore.length
    ) {
      setDateTimeSignal(
        dateTimeStore[selectedDate as number].times[
          selectedTime as number
        ].value.toString()
      );
    }
  }, [selectedTime, selectedDate, dateTimeStore]);

  React.useEffect(() => {
    if (dateTimeSignal) {
      logResponse({
        componentId: uuid,
        value: formatISO(new Date(dateTimeSignal)), // ISO Date sample: 2020-12-10T07:15:00-08:00
      });
    }
  }, [logResponse, uuid, dateTimeSignal]);

  // Update currentDateTime variable every 15 mins, so user cant choose a past date after window inactivity
  React.useEffect(() => {
    const updateCurrent = () => {
      setCurrentDateTime(new Date());
    };
    const updateInterval = setInterval(updateCurrent, 300000);
    return () => clearInterval(updateInterval);
  }, []);

  // effect that populates dayOptions
  React.useEffect(() => {
    const addDays = (days: number) => {
      const newDate = new Date(currentDateTime);
      newDate.setDate(newDate.getDate() + days);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    };
    setDayOptions([
      { display: 'Today', value: currentDateTime },
      { display: 'Tomorrow', value: addDays(1) },
      { display: format(addDays(2), 'EEEE'), value: addDays(2) },
    ]);
  }, [currentDateTime]);

  // effect that populates timeOptions
  React.useEffect(() => {
    if (dayOptions.length) {
      const timeOptionsArray: DateOptions[] = populateTimeOptions(dayOptions);
      setTimeOptions(timeOptionsArray);
    }
  }, [dayOptions]);

  // effect that populates DateTimeStore
  React.useEffect(() => {
    if (timeOptions.length && dayOptions.length) {
      const dateTimeArray: DateTime[] = populateDateTimeStore(
        dayOptions,
        timeOptions
      );
      setDateTimeStore(dateTimeArray);
    }
  }, [timeOptions, dayOptions]);

  return (
    <Grid container spacing={2}>
      <GridItem06>
        <DateSelect
          label={labelDate}
          placeholder={placeholderDate}
          helperText={helperTextDate}
          error={errorDate}
          dateTimeStore={dateTimeStore}
          uuid={`${uuid}-date`}
          setSelectedDate={setSelectedDate}
          setSelectedTime={setSelectedTime}
          selectedDate={selectedDate}
        />
      </GridItem06>
      <GridItem06>
        <TimeSelect
          label={labelTime}
          placeholder={placeholderTime}
          helperText={helperTextTime}
          error={errorTime}
          uuid={`${uuid}-time`}
          dateTimeStore={dateTimeStore}
          selectedDate={selectedDate}
          setSelectedTime={setSelectedTime}
          selectedTime={selectedTime}
        />
      </GridItem06>
    </Grid>
  );
};

export default DateTimeSelect;
