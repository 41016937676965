import { makeVariant } from '../../../utils/createTypography';

export const fontStack = {
  montserrat: '"Montserrat", sans-serif;',
  openSans: '"Open Sans", Helvetica, Arial, sans-serif'
};

export const typographyVariants = {
  h1: makeVariant({
    fontFamily: fontStack.montserrat,
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '130%'
  }),
  h2: makeVariant({
    fontFamily: fontStack.montserrat,
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '130%'
  }),
  h3: makeVariant({
    fontFamily: fontStack.montserrat,
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '130%'
  }),
  h4: makeVariant({
    fontFamily: fontStack.montserrat,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '130%'
  })
};

// Currently we dont have all weights but eventually we will
export const customTypographyVariants = {
  sh1: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '130%'
  }),
  sh2: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '130%'
  }),
  bodyLarge: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '130%'
  }),
  bodyLargeMedium: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '130%'
  }),
  bodyRegular: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: '130%'
  }),
  bodyRegularMedium: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '.875rem',
    fontWeight: 600,
    lineHeight: '130%'
  }),
  labelLarge: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '120%'
  }),
  labelRegular: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '.875rem',
    fontWeight: 600,
    lineHeight: '120%'
  }),
  labelSmall: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '.75rem',
    fontWeight: 600,
    lineHeight: '120%'
  }),
  labelSmallUppercase: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '.75rem',
    fontWeight: 600,
    lineHeight: '120%'
  }),
  labelTiny: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '.625rem',
    fontWeight: 700,
    lineHeight: '120%'
  }),
  textLink: makeVariant({
    fontFamily: fontStack.openSans,
    fontSize: '.875rem',
    fontWeight: 600,
    lineHeight: '120%'
  })
};

export const typography = {
  fontFamily: fontStack.openSans, // the default font
  ...typographyVariants
};
