import radioClient from '@tugboatholdings/hm-radio-client-v2';
import Cookies from 'universal-cookie';
import { randomId } from '../../helpers/randomId';
import { UserResponse } from '../../components/Core/CoreTypes';
import { getUtmParams } from '../../helpers/params';

import apiTranslators from './translators';
import { Translator } from '../../components/RenderFunctions/AddApiTranslators';

interface InitializeData {
  traceId: string;
}

interface StartRequestData extends InitializeData {
  requestData: {
    profileUuid?: string;
    data: any;
  };
}

interface StartData {
  startRequestData: StartRequestData;
  domain: string;
  app: string;
}

interface CompleteData extends InitializeData {
  profileUuid: string;
  flowId: string;
  stepId: string;
  stepUuid: string;
  responses: UserResponse[];
  useApiTranslators?: Translator[];
  canComplete?: boolean;
}

interface ContinueData extends InitializeData {
  requestData: {
    profileUuid: string;
    experienceProcessId: string;
    stepUuid: string;
  };
}

const apiUrl = process.env.REACT_APP_RADIO_API_URL;
const apiKey = process.env.REACT_APP_RADIO_API_KEY;

const requestBodyTemplate = () => {
  const cookies = new Cookies();
  const googleClientId = cookies.get('_ga');
  const facebookPixelId = cookies.get('_fbp');
  const ajsAnonymousId = cookies.get('ajs_anonymous_id');
  const userAgent = navigator?.userAgent;
  const locale = navigator?.language;

  return {
    requestId: 'string',
    traceId: 'string',
    requestData: {},
    context: {
      ...(googleClientId ? { googleClientId } : {}),
      ...(facebookPixelId ? { facebookPixelId } : {}),
      ...(ajsAnonymousId ? { ajsAnonymousId } : {}),
      ...(userAgent ? { userAgent } : {}),
      locationHref: window.location.href,

      // Xerox of the Segment.io analytics.context object
      // for easy copy/paste on the API-side.
      analytics: {
        campaign: getUtmParams(),
        page: {
          path: window.location.pathname,
          referrer: document.referrer,
          search: window.location.search,
          title: document.title,
          url: window.location.href
        },
        ...(locale ? { locale } : {}),
        ...(userAgent ? { userAgent } : {})
      }
    }
  };
};

export const initializeRadio = async (data: InitializeData) => {
  const reqBody = {
    ...requestBodyTemplate(),
    ...data,
    requestId: randomId(),
    requestData: { host: window.location.hostname }
  };

  const radioApi = new radioClient.Api({ apiKey }, apiUrl, fetch);
  const init = await radioApi.initialize(reqBody);

  return init;
};

export const startRadio = async (data: StartData) => {
  const { startRequestData, domain, app } = data;

  const requestBody = {
    ...requestBodyTemplate(),
    ...startRequestData,
    requestId: randomId()
  };

  const radioApi = new radioClient.Api({ apiKey }, apiUrl, fetch);
  const start = await radioApi.start(requestBody, domain, '2.0.0', app);

  return start;
};

export const continueRadio = async (
  domain: string,
  app: string,
  data: ContinueData
) => {
  const requestBody = {
    ...requestBodyTemplate(),
    requestId: randomId(),
    ...data
  };

  const radioApi = new radioClient.Api({ apiKey }, apiUrl, fetch);
  const continueApp = await radioApi.continueApp(
    requestBody,
    domain,
    '2.0.0',
    app
  );

  return continueApp;
};

export const completeRadio = async (
  domain: string,
  app: string,
  data: CompleteData
) => {
  const useApiTranslators = data.useApiTranslators || [];

  // we only support the first… for now.
  const hasValidTranslation: boolean =
    useApiTranslators.length > 0 &&
    Object.keys(apiTranslators).includes(useApiTranslators[0].translator);
  const serviceData = hasValidTranslation
    ? { serviceData: apiTranslators[useApiTranslators[0].translator](data.responses, useApiTranslators[0].args, data.canComplete) }
    : {};

  const requestBody = {
    ...requestBodyTemplate(),
    requestId: randomId(),
    traceId: data.traceId,

    requestData: {
      profileUuid: data.profileUuid,
      flowId: data.flowId,
      stepId: data.stepId,
      stepUuid: data.stepUuid,

      // response translation
      data: {
        payload: apiTranslators.dmnSignals(data.responses),
        uiData: apiTranslators.uiData(data.responses),
        // serviceData: apiTranslators... something something...
        ...serviceData
      }
    }
  };

  const radioApi = new radioClient.Api({ apiKey }, apiUrl, fetch);
  const complete = await radioApi.complete(requestBody, domain, '2.0.0', app);

  return complete;
};
