export enum TwilioEndpoints {
  verify = 'Verifications',
  check = 'VerificationCheck'
}

const TWILIO_API_URL = process.env.REACT_APP_TWILIO_API_URL;
const TWILIO_ACCOUNT_SID = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
const TWILIO_AUTH_TOKEN = process.env.REACT_APP_TWILIO_AUTH_TOKEN;
const TWILIO_VERIFY_SERVICE_ID = process.env.REACT_APP_TWILIO_VERIFY_SERVICE_ID;

export const twilioFetch = async (
  endpoint: TwilioEndpoints,
  data: Record<string, string>
) => {
  const reqHeaders = new Headers();
  const formData = new FormData();

  // set headers
  reqHeaders.append(
    'Authorization',
    /* eslint-disable-next-line prefer-template */
    `Basic ${btoa(TWILIO_ACCOUNT_SID + ':' + TWILIO_AUTH_TOKEN)}`
  );

  // set formData
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });

  const res = await fetch(
    `${TWILIO_API_URL}${TWILIO_VERIFY_SERVICE_ID}/${endpoint}`,
    {
      method: 'POST',
      headers: reqHeaders,
      body: formData,
      redirect: 'follow'
    }
  );

  if (res.status < 200 || res.status > 300) {
    const json = await res.json();
    throw new Error(`${json.status}: ${json.message}`);
  } else {
    const json = await res.json();
    return json;
  }
};
