import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';
import ChipLabel, { Props as ChipProps } from '../common/ChipLabel';
import CheckboxInput from './CheckboxInput';

export interface Props extends ComponentBase {
  /**
   * If true component is checked. Only applicable if isMultiSelect is true
   */
  checked?: boolean;
  /**
   * If true component has a checkbox
   */
  isSelectable?: boolean;
  /**
   * If true component is clickable
   */
  isClickable?: boolean;
  /**
   * variant changes the way the card is displayed
   */
  variant?: 'single' | 'grouped' | 'stacked' | 'cover' | 'expandedGroup';
  /**
   * Function triggered when card is clicked
   */
  handleClick?: () => void;
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid: string;
  /**
   * value located in the top-left section of the card.
   */
  topLeftValue?: string;
  /**
   * value located in the top-right section of the card.
   */
  topRightValue?: string;
  /**
   * value located in the bottom-left section of the card.
   */
  bottomLeftValue?: string;
  /**
   * value located in the bottom-right section of the card.
   */
  bottomRightValue?: string;
  /**
   * properties if chip should be included
   */
  chipProps?: ChipProps;
  /**
   * location of the chip if included
   */
  chipLocation?: 'top' | 'bottom';
  /**
   * tabIndex for accessibility
   */
  tabIndex?: number;
}

const CardAtom: React.FC<Props> = ({
  checked,
  isSelectable,
  isClickable = true,
  variant = 'single',
  handleClick,
  uuid,
  topLeftValue,
  topRightValue,
  bottomLeftValue,
  bottomRightValue,
  chipProps,
  chipLocation,
  tabIndex = 0
}) => {
  const classes = useStyles();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' && handleClick) {
      handleClick();
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.checkedCard]:
          checked && (variant === 'single' || variant === 'stacked'),
        [classes.single]: variant === 'single',
        [classes.grouped]: variant === 'grouped',
        [classes.stacked]: variant === 'stacked',
        [classes.cover]: variant === 'cover',
        [classes.expandedGroup]: variant === 'expandedGroup',
        [classes.clickable]: isClickable && isSelectable
      })}
      role="button"
      tabIndex={tabIndex}
      onKeyPress={isClickable ? handleKeyPress : undefined}
      onClick={isClickable ? handleClick : undefined}
    >
      <div className={classes.content}>
        {chipLocation === 'top' && chipProps && (
          <div className={classes.topChipRow}>
            <ChipLabel {...chipProps} />
          </div>
        )}
        <div className={classes.topRow}>
          <div className={classes.topLeft}>
            <span className={classes.topLeftValue}>{topLeftValue}</span>
          </div>
          <div className={classes.topRight}>
            <span className={classes.topRightValue}>{topRightValue}</span>
          </div>
        </div>
        <div className={classes.bottomRow}>
          {bottomLeftValue && (
            <div className={classes.bottomLeft}>
              <span className={classes.bottomLeftValue}>{bottomLeftValue}</span>
            </div>
          )}
          <div className={classes.bottomRight}>
            {bottomRightValue && (
              <span className={classes.bottomRightValue}>
                {bottomRightValue}
              </span>
            )}
            {chipLocation === 'bottom' && chipProps && (
              <ChipLabel {...chipProps} />
            )}
          </div>
        </div>
      </div>
      {isSelectable && isClickable && (
        <div className={classes.checkboxContainer}>
          <CheckboxInput
            checked={checked}
            value={uuid}
            name={uuid}
            ariaLabel={uuid}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme>(
  // @ts-ignore Mui bug with spreading theme variables.
  (theme) =>
    createStyles({
      root: {
        width: '100%',
        // border: `1px solid ${theme.palette.grey[600]}`,
        boxSizing: 'border-box',
        ...theme.typography.body1,
        color: theme.palette.primary.main,
        display: 'flex',
        '&:focus-visible': {
          outline: 'none' // dev-defined a11y color
        },
        '&:-moz-focusring': {
          outline: 'none' // dev-defined a11y color
        }
      },
      clickable: {
        cursor: 'pointer'
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 0',
        flex: 1
      },
      checkboxContainer: {
        padding: '4px',
        flex: 0
      },
      topChipRow: {
        marginBottom: '4px'
      },
      topRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      topRight: {},
      topRightValue: {},
      topLeft: {},
      topLeftValue: {},
      bottomRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      bottomRight: {},
      bottomRightValue: {},
      bottomLeft: {},
      bottomLeftValue: {},
      single: {
        border: `1px solid ${theme.palette.grey[600]}`,
        padding: '0 16px',
        borderRadius: '8px'
      },
      grouped: {
        border: '0px transparent'
      },
      stacked: {
        border: `1px solid ${theme.palette.grey[600]}`,
        padding: '0 16px',
        borderRadius: '8px',
        boxShadow: `0 8px 0 -6px ${theme.palette.grey[300]}`
      },
      cover: {
        borderRadius: '8px 8px 0 0',
        border: `1px solid ${theme.palette.grey[600]}`,
        padding: '0 16px'
      },
      expandedGroup: {},
      checkedCard: {
        border: `2px solid ${theme.palette.primary.main}`
      }
    }),
  { name: 'Mui-CardAtom' }
);

export default CardAtom;
