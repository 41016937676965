export * from './components/ComponentLibrary';
export {default as RadioSDK} from './components/Core/RadioSDK';


interface RadioSDK {
  version: string;
  bundled: string;
}

declare global {
  interface Window {
    RadioSDK: RadioSDK;
  }
}

// So we can tell which version is running in SPA.
if (typeof window !== 'undefined') {
  window.RadioSDK = window.RadioSDK || {};
  window.RadioSDK.version = '{{RADIOSDK_VERSION}}';
  window.RadioSDK.bundled = '{{ROLLUP_BUILD_DATETIME}}';
}