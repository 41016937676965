import { makeStyles, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { ComponentWithChildren } from '../../models/models';
import FabBase from '../atoms/FabBase';
import { useDebounce } from '../../helpers/useDebounce';



export interface Props extends ComponentWithChildren {
  /**
   * Label for button that will show and hide long text.
   */
  showHideTextLabel: {
    show: string;
    hide: string;
  };
  /**
   * if true all components will be shown on Mobile 
   */
  showAllOnMobile?: boolean
  /**
   * Number of components shown before show more button is displayed
   */
  maxShownBeforeHidden: number
  /**
   * Variant used on button that shows/hides the components
   */
  buttonVariant?: 'ghost' | 'default' | 'inverse'
  /**
   * boolean flag to show an extended button
   */
  buttonExtended?: boolean
  /**
   * boolean flag to show a full width button
   */
  buttonFullWidth?: boolean
}

const ShowHideComponents: React.FC<Props> = (props) => {
  const {
    showHideTextLabel,
    showAllOnMobile = false,
    maxShownBeforeHidden,
    buttonVariant = 'ghost',
    buttonExtended = true,
    buttonFullWidth = true,
    children
  } = props;

  const classes = useStyles();

  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [isWindowMobile, setIsWindowMobile] = React.useState<boolean>();
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  const handleClick = () => {
    setShowAll(!showAll);
  };

  const updateWidthDebounced = useDebounce(() => 
    setWindowWidth(window.innerWidth)
  );
    
  // Tracking the width of the browser only necessary if !!showAllOnMobile
  React.useEffect(() => {
    
    if(showAllOnMobile) {
      if (windowWidth < 600) {
        setIsWindowMobile(true);
      } else {
        setIsWindowMobile(false);
      }
      window.addEventListener('resize', updateWidthDebounced);
    }
    return () => showAllOnMobile ? window.removeEventListener('resize', updateWidthDebounced) : undefined;
  }, [windowWidth, showAllOnMobile, updateWidthDebounced]);

  // Only triggered if !!showAllOnMobile
  React.useEffect(() => {
    if(isWindowMobile){
      setShowAll(true);
    } else {
      setShowAll(false);
    }
  }, [isWindowMobile]);

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        {children && React.Children.map(children, (child, index) => {
          if(showAll) {
            return child;
          } if (index < maxShownBeforeHidden) {
            return child;
          }
          return null;
        })}
      </Grid>
      {(children && (React.Children.toArray(children).length > maxShownBeforeHidden)) && (!showAllOnMobile || !isWindowMobile) && (
        <>
          <div className={classes.buttonContainer}>
            <FabBase 
              extended={buttonExtended}
              fullWidth={buttonFullWidth}
              label={showAll ? showHideTextLabel.hide : showHideTextLabel.show}
              variant={buttonVariant}
              handleClick={handleClick}
              type={showAll ? showHideTextLabel.hide : showHideTextLabel.show}
            />
          </div>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root:{
        paddingBottom: '16px'
      },
      buttonContainer: {
        
      }
    }),
  { name: 'Mui-ShowHideComponents' }
);

export default ShowHideComponents;
