import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * If true component is checked
   */
  checked?: boolean;
  /**
   * Function triggered when button is changed
   */
  handleChange?: () => void;
  /**
   * value to test against to identify active radio input.
   */
  selectedValue?: string;
  /**
   * value of rendered checkbox input.
   */
  value?: string;
  /**
   * Name attribute of the input element.
   */
  name?: string;
  /**
   * label for accessibility.
   */
  ariaLabel?: string;
  /**
   * If true ripple will be disabled
   */
  disableRipple?: boolean;
  /**
   * If true ratio will be disabled
   */
  disabled?: boolean;
  /**
   * color used when checked.
   */
  color?: 'primary' | 'secondary';
}

const CheckboxInput: React.FC<Props> = ({
  checked,
  handleChange,
  selectedValue,
  value,
  name,
  ariaLabel,
  disableRipple,
  disabled,
  color = 'primary'
}) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (typeof checked === 'boolean') {
      setIsChecked(checked);
    } else {
      setIsChecked(selectedValue === value);
    }
  }, [checked, selectedValue, value]);

  return (
    <Checkbox
      disableRipple={disableRipple}
      disabled={disabled}
      color={color}
      checked={isChecked}
      onChange={handleChange}
      value={value}
      name={name}
      inputProps={{ 'aria-label': ariaLabel }}
      classes={{
        root: classes.input,
        checked: classes.checkedCheckbox,
        disabled: classes.disabledCheckbox,
        colorPrimary: classes.colorPrimaryCheckbox,
        colorSecondary: classes.colorSecondaryCheckbox
      }}
    />
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      input: {},
      checkedCheckbox: {},
      disabledCheckbox: {},
      colorPrimaryCheckbox: {},
      colorSecondaryCheckbox: {}
    }),
  { name: 'Mui-CheckboxInput' }
);

export default CheckboxInput;
