import React from 'react';
import { ComponentBase } from '../../../models/models';
import GridItem12 from '../../GridItems/GridItem12';
import Headline from '../../Prompt/Headline';
import DropdownSingleSelect, { Props as DropdownProps } from '../Selects/DropdownSingleSelect';
import TypeInShort, { Props as TypeInProps } from '../TypeInsSimple/TypeInShort';
import { useRadioState } from '../../Core/radioContext';

export interface Props extends ComponentBase {
  /**
   * The component that will be triggering the enabled/disabled behavior.
   */
  dropdownProps: DropdownProps;
  /**
   * The components that will be enabled/disabled by the controller component
   */
  typeInPropsArray: TypeInProps[];
  /**
   * A map of the controller uuids to the desired behavior
   */
  behaviorMap: Record<string, 'enabled' | 'disabled'>;
  /**
   * The headline text that will go between controller and receiver components
   */
  headline?: string;
  /**
   * The properties that will be passed to the back button.
   */
  receiverDefaultState?: 'enabled' | 'disabled';
}


const DropdownEnabledTypeIns: React.FC<Props> = ({
  dropdownProps,
  typeInPropsArray,
  behaviorMap,
  headline,
  receiverDefaultState = 'disabled'
}) => {

  const radioState = useRadioState();
  const [isDisabled, setIsDisabled] = React.useState<boolean>(receiverDefaultState === 'disabled');


  React.useEffect(() => {
    radioState.responses.forEach((component) => {
      if(component.componentType === 'select') { 
        if(behaviorMap[component.value as string] === 'enabled') {
          setIsDisabled(false);
        } else if (behaviorMap[component.value as string] === 'disabled') {
          setIsDisabled(true);
        }
      }
    });
  }, [radioState.responses, behaviorMap]);

  return (
    <>
      <GridItem12>
        <DropdownSingleSelect
          {...dropdownProps}
        />
      </GridItem12>
      {
        headline && (
          <>
            <GridItem12>{}</GridItem12>
            <GridItem12>
              <Headline headline={headline} />
            </GridItem12>
          </>
        )
      }
      {
        typeInPropsArray.map((componentProps) => {
          return (
            <GridItem12 key={componentProps.uuid}>
              <TypeInShort
                {...componentProps}
                disabled={isDisabled}
                isRequired={!isDisabled}
              />
            </GridItem12>
          );
        })
      }
    </>
  );
};

export default DropdownEnabledTypeIns;
