import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * The color of the component
   */
  color?: 'primary' | 'secondary';
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value?: number;
  /**
   * The value for the buffer variant.
   * Value between 0 and 100.
   */
  valueBuffer?: number;
  /**
   * The variant to use.
   * Use indeterminate or query when there is no progress value.
   */
  variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  /**
   * Whether to display % label or not
   */
  hasLabel?: boolean;
  /**
   * MinWidth of label container
   */
  labelMinWidth?: number;
}

const ProgressBar: React.FC<Props> = ({
  color = 'primary',
  value,
  valueBuffer,
  variant = 'determinate',
  hasLabel = true,
  labelMinWidth = 35
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" className={classes.container}>
        <Box width="100%" mr={1} className={classes.barContainer}>
          <LinearProgress
            variant={variant}
            valueBuffer={valueBuffer}
            value={value}
            color={color}
            className={classes.progressBar}
          />
        </Box>
        {hasLabel && value && (
          <Box minWidth={labelMinWidth} className={classes.labelContainer}>
            <span className={classes.label}>{`${Math.round(value)}%`}</span>
          </Box>
        )}
      </Box>
    </div>
  );
};

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: {},
      container: {},
      barContainer: {},
      progressBar: {
        height: '12px'
      },
      label: {
        color: theme.palette.grey[600],
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.caption.fontWeight,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: theme.typography.caption.letterSpacing
      },
      labelContainer: {}
    }),
  { name: 'Mui-ProgressBar' }
);

export default ProgressBar;
