import React from 'react';
import { useRadioDispatch } from '../Core/radioContext';

const DisableNextLoadingScreen: React.FC = () => {
  const { skipNextLoadingScreen } = useRadioDispatch();

  React.useEffect(() => {
    skipNextLoadingScreen();
  }, []);
  
  return null;
};

export default DisableNextLoadingScreen;