export const mrNavy = {
  700: '#0B1837',
  600: '#354466',
  500: '#687AA3',
  400: '#A2B0CE',
  100: '#CFD2DB',
  50: '#F5F6F8'
};

export const actionBlue = {
  800: '#0A1C52',
  700: '#1F3E99',
  500: '#3368FF',
  400: '#80A1FF',
  200: '#CCD9FF',
  100: '#E5ECFF',
  50: '#F5F8FF'
};

export const mrGreen = {
  500: '#439B18',
  100: '#DEF5D2',
  50: '#F4FEEF'
};

export const mrRed = {
  500: '#FF3535',
  100: '#FFD7D7',
  50: '#FFF5F5'
};

export const mrYellow = {
  500: '#FF9900',
  100: '#FFF3B2',
  50: '#FFFAE0'
};

export const mrOrange = {
  500: '#FF7443'
};

export const white = '#FFFFFF';
