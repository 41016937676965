import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ComponentWithChildren } from '../../models/models';


const FooterContent: React.FC<ComponentWithChildren> = ({children}) => {
  const classes = useStyles();
  return <nav className={classes.root}>{children}</nav>;
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    maxWidth: '820px'

  }
}, { name: 'Mui-FooterContent' });

export default FooterContent;