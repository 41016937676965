import React from 'react';
import MaskedInput from 'react-text-mask';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { InputBaseComponentProps } from '@material-ui/core';

import TypeInBase from '../../atoms/TypeInBase';
import TwoFactorAuthModal, {
  VerifyChannels
} from '../../Modals/TwoFactorAuthModal';
import { ComponentWithVariants } from '../../../models/models';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';

export interface Props extends ComponentWithVariants {
  /**
   * Whether the user is required to fill in the textfield.
   */
  isRequired?: boolean;
  /**
   * Label displayed on top of Textfield.
   */
  label?: string;
  /**
   * Unique ID from Json.
   */
  uuid: string;
}

interface PhoneNumberMaskCustomProps {
  inputRef: (ref: HTMLElement | null) => void;
}

function PhoneNumberMaskCustom(props: PhoneNumberMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: MaskedInput | null) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

const TypeInPhone: React.FC<Props> = ({
  isRequired = true,
  uuid,
  label = 'Phone Number'
  // variant = 'main'
}) => {
  const { registerResponseComponent, logResponse } = useRadioDispatch();
  const radioState = useRadioState();

  const initValue = radioState.apiData.responses?.[uuid] || '';

  const [value, setValue] = React.useState<string>(initValue);
  const [verifyModalOpen, setVerifyModalOpen] = React.useState<boolean>(false);
  const [verified, setVerified] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);
  const errorMessage = 'Invalid United States phone number.';

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'input',
      componentId: uuid,
      value: initValue || null,
      isRequired
    });
  }, [registerResponseComponent, uuid, initValue, isRequired]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const isValid = (phoneNumber: string) => {
    const re = /^\(\d{3}\) \d{3}-\d{4}$/;
    return re.test(String(phoneNumber));
  };

  const handleOnBlur = () => {
    setError(!isValid(value));

    if (value !== verified) {
      setVerified('');

      logResponse({
        componentId: uuid,
        value: null
      });
    }
  };

  const handleOnVerify = () => {
    setVerified(value);
    logResponse({
      componentId: uuid,
      value
    });
  };

  const verifyButton = () => (
    <InputAdornment position="end">
      <Button
        variant={verified ? 'text' : 'contained'}
        color="primary"
        size="small"
        disabled={!isValid(value) || !!verified}
        endIcon={verified ? <VerifiedUserIcon /> : ''}
        onClick={() => setVerifyModalOpen(true)}
      >
        {verified ? 'Verified' : 'Verify'}
      </Button>
    </InputAdornment>
  );

  return (
    <div>
      <TypeInBase
        isRequired={isRequired}
        uuid={uuid}
        label={label}
        inputMaskComponent={
          PhoneNumberMaskCustom as React.FunctionComponent<InputBaseComponentProps>
        }
        endAdornment={verifyButton()}
        placeholder="(123) 456-7890"
        value={value}
        handleChange={handleChange}
        handleOnBlur={handleOnBlur}
        errorMessage={error ? errorMessage : null}
        error={error}
      />
      {verifyModalOpen && (
        <TwoFactorAuthModal
          to={value}
          availableChannels={[VerifyChannels.sms, VerifyChannels.call]}
          onClose={() => setVerifyModalOpen(false)}
          onVerify={handleOnVerify}
        />
      )}
    </div>
  );
};

export default TypeInPhone;
