import { fade, darken } from '@material-ui/core/styles';

import * as palette from './colors';
import { typography, customTypographyVariants } from './typography';
import { shadows } from './shadows';

const variants = {
  'Mui-Body-Transition': {
    root: {
      background: palette.ycSand[300]
    }
  },

  'Mui-BodyContent-Large': {
    root: {
      height: 'auto',
      maxWidth: '800px',
      '@media (max-width: 600px)': {
        width: 'calc(100% + 48px)'
      }
    }
  },

  'Mui-SaveAndExitTopNav': {
    root: {
      borderColor: palette.ycGreen[900],
      color: palette.ycGreen[900]
    }
  },
  'Mui-Headline-XXL': {
    root: {
      ...typography.h1,
      color: palette.ycGreen[900],
      margin: '0 0 28px'
    }
  },
  'Mui-TileButtonBase-SpeakNowOrLater': {
    root: {
      background: palette.ycRed[500],
      color: palette.white,
      border: '2px solid transparent',
      transition: 'background .35s ease, border-color .5s ease',
      boxShadow: shadows[4],
      '@media (pointer: fine)': {
        '&:hover': {
          background: fade(palette.ycRed[500], 0.9),
          boxShadow: shadows[11]
        }
      },
      '&:active': {
        background: `${darken(palette.ycRed[500], 0.15)} !important`
      }
    },
    buttonLabel: {
      ...customTypographyVariants.buttonLarge
    },
    selectedOption: {
      background: palette.ycRed[500],
      borderColor: `${darken(palette.ycRed[500], 0.15)} !important`
    }
  },

  'Mui-TileButtonBase-Welcome': {
    root: {
      flexDirection: 'row-reverse',
      background: palette.white,
      borderRadius: '8px',
      color: palette.ycGreen[900],
      border: `1px solid ${palette.ycGreen[900]}`,
      transitionDelay: '.3s',
      transition: 'background .6s ease, border-color .5s ease',
      boxShadow: shadows[4],
      '@media (pointer: fine)': {
        '&:hover': {
          boxShadow: shadows[11]
        }
      },
      '&:active': {
        background: `${palette.ycSand[300]} !important`
      }
    },
    buttonLabel: {
      fontWeight: '400'
    },
    leftAligned: {
      ...customTypographyVariants.buttonLarge,
      alignItems: 'center',
      padding: '8px 12px',
      '& img': {
        paddingLeft: '16px'
      }
    },
    selectedOption: {
      background: `${palette.ycSand[300]} !important`,
      '.multi-select &': {
        background: palette.ycGreen[500], // ycGreen900 + 80% white overlay
        color: palette.white,
        border: '2px solid transparent'
      }
    }
  },
  'Mui-LottiePlayer-SpeakNowOrLater': {
    root: {
      margin: '-5rem auto',
      '@media (max-width: 600px)': {
        margin: '-2rem auto'
      }
    }
  },
  'Mui-TypeInBase-NoMaxWidth': {
    root: {
      maxWidth: 'none'
    }
  },
  'Mui-SingleSelectBase-StateStep': {
    label: {
      color: palette.ycGreen[900]
    },
    input: {
      ...customTypographyVariants.sh1,
      color: palette.ycGreen[900],
      background: palette.white,
      boxShadow: shadows[4]
    },
    inputIcon: {
      fill: palette.ycGreen[500],
      paddingRight: '8px'
    },
    menu: {
      boxShadow: shadows[5]
    },
    menuItem: {
      color: palette.ycGreen[900],
      '@media (pointer: fine)': {
        '&:hover': {
          background: `${palette.ycSand[300]} !important`,
          color: palette.ycGreen[900]
        }
      },
      '&:focus': {
        background: `${palette.ycGreen[500]} !important`,
        color: palette.white
      }
    },
    menuItemSelected: {
      background: `${palette.ycGreen[500]} !important`,
      color: palette.white
    }
  },

  'Mui-FabBase-InverseHideDesktop': {
    inverse: {
      backgroundColor: palette.white,
      border: `2px solid ${palette.ycRed[500]}`,
      color: palette.ycRed[500],
      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: palette.white,

          '& path': {
            fill: palette.ycRed[500]
          }
        }
      },
      '&:active': {
        backgroundColor: palette.white,
        '& path': {
          fill: `${darken(palette.ycRed[500], 0.15)} !important`
        }
      },
      '& path': {
        fill: palette.ycRed[500]
      },
      '@media (min-width: 964px)': {
        visibility: 'hidden'
      }
    }
  }
};

const layout = {
  'Mui-Body': {
    root: {
      background: palette.white
    }
  },

  'Mui-TopNavigation': {
    root: {
      background: palette.white
    }
  },

  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(6, 63, 54, 0.9)'
      // backgroundColor: 'rgba(0, 0, 0, .75)',
    }
  }
};

const footerNavigation = {
  'Mui-SingleLongButtonNavigation': {
    buttonLabel: {
      ...customTypographyVariants.buttonLarge,
      textTransform: 'none'
    },
    buttonDisabled: {
      background: 'rgb(224, 224, 224) !important'
    }
  },

  'Mui-BottomNavigation': {
    fab: {
      boxShadow: shadows[5],
      '@media (pointer: fine)': {
        '&:hover': {
          boxShadow: shadows[11]
        }
      },
      '&:active': {
        boxShadow: shadows[5]
      }
    },
    fabForwardDisabled: {
      background: `${fade(palette.ycRed[500], 0.48)} !important`,
      // background: `${fade(palette.ycGreen[500], 0.48)} !important`,

      boxShadow: `${shadows[5]} !important`
    },
    fabBackward: {
      backgroundColor: palette.white,
      border: `2px solid ${palette.ycRed[500]}`,
      // background: palette.ycRed[500],

      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: palette.white,
          // background: `${darken(palette.ycRed[500], 0.15)} !important`,

          '& path': {
            // fill: palette.ycGreen[900]
            // fill: palette.white
            fill: palette.ycRed[500]
          }
        }
      },
      '&:active': {
        backgroundColor: palette.white,
        // background: `${darken(palette.ycRed[500], 0.15)} !important`,
        '& path': {
          // fill: palette.ycGreen[900]
          // fill: palette.white
          fill: `${darken(palette.ycRed[500], 0.15)} !important`
        }
      },
      '& path': {
        // fill: '#A3A3A3'
        // fill: palette.white
        fill: palette.ycRed[500]
      }
    },
    fabForward: {
      '@media (pointer: fine)': {
        '&:hover': {
          background: `${darken(palette.ycRed[500], 0.15)} !important`
          // background: `${darken(palette.ycGreen[500], 0.15)} !important`
        }
      },
      '&:active': {
        background: `${darken(palette.ycRed[500], 0.15)} !important`
        // background: `${darken(palette.ycGreen[500], 0.15)} !important`
      },
      '& path': {
        fill: palette.white
      }
    }
  },
  'Mui-FooterContent': {
    root: {
      display: 'flex',
      flexDirection: 'column-reverse',
      maxWidth: '684px',
      '& > *:not(:first-child)': {
        marginBottom: '8px'
      },
      '@media (min-width: 600px)': {
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:not(:last-child)': {
          marginRight: '16px'
        },
        '& > *:not(:first-child)': {
          margin: 0
        }
      }
    }
  },
  'Mui-FabBase': {
    root: {
      ...customTypographyVariants.buttonLarge,
      textTransform: 'none',
      maxWidth: '450px'
    },
    default: {
      background: palette.ycRed[500],
      color: palette.white,
      '&:hover': {
        '@media (hover: none)': {
          background: palette.ycRed[500]
        }
      },
      '@media (pointer: fine)': {
        '&:hover': {
          background: `${darken(palette.ycRed[500], 0.15)} !important`
        }
      },
      '&:active': {
        background: `${darken(palette.ycRed[500], 0.15)} !important`
        // background: `${darken(palette.ycGreen[500], 0.15)} !important`
      },
      '& path': {
        fill: palette.white
      }
    },
    inverse: {
      backgroundColor: palette.white,
      border: `2px solid ${palette.ycRed[500]}`,
      color: palette.ycRed[500],
      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: palette.white,
          // background: `${darken(palette.ycRed[500], 0.15)} !important`,

          '& path': {
            // fill: palette.ycGreen[900]
            // fill: palette.white
            fill: palette.ycRed[500]
          }
        }
      },
      '&:active': {
        backgroundColor: palette.white,
        // background: `${darken(palette.ycRed[500], 0.15)} !important`,
        '& path': {
          // fill: palette.ycGreen[900]
          // fill: palette.white
          fill: `${darken(palette.ycRed[500], 0.15)} !important`
        }
      },
      '& path': {
        // fill: '#A3A3A3'
        // fill: palette.white
        fill: palette.ycRed[500]
      }
    },
    fabExtended: {
      height: '56px',
      borderRadius: '8px',
      '&$ghost': {
        height: '40px'
      }
    },
    fabDisabled: {
      backgroundColor: `${palette.ycSand[300]} !important`,
      border: '1px solid transparent !important',
      color: `${palette.ycGrey[500]} !important`,
      '$ghost&': {
        color: `${palette.ycGrey[500]} !important`
      }
    },
    fabFocusVisible: {
      border: `3px solid ${palette.ycRed[300]}`
    },
    ghost: {
      backgroundColor: 'transparent',
      color: palette.ycGreen[900],

      '&:hover': {
        background: palette.ycGreen[100],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      },
      '&:active': {
        background: palette.ycGreen[300]
      }
    }
  }
};

const leftPanel = {
  'Mui-ExperienceProgressContainer': {
    progressRail: {
      '&::before': {
        background: fade(palette.ycGreen[300], 0.48)
      }
    }
  },

  'Mui-ProgressStage': {
    label: {
      ...typography.body1,
      lineHeight: '1.375rem',
      color: fade(palette.ycGreen[300], 0.48)
    }
  }
};

const prompt = {
  'Mui-Headline': {
    root: {
      ...typography.h3,
      color: palette.ycGreen[900],
      margin: '0 0 8px',

      '@media (max-width: 768px)': {
        ...customTypographyVariants.h3Mobile
      }
    }
  },

  'Mui-Description': {
    root: {
      margin: 0,
      paddingBottom: '8px'
    },
    link: {
      ...typography.body2,
      color: palette.ycOrange[500],
      textDecoration: 'none',
      transition: 'color .250s',
      '@media (pointer: fine)': {
        '&:hover': {
          filter: 'brightness(92%)'
        }
      }
    }
  },

  'Mui-ModalLink': {
    root: {
      ...typography.body2,
      color: palette.ycGreen[900],
      margin: 0,
      paddingBottom: '8px'
    },
    link: {
      ...typography.body2,
      color: palette.ycOrange[500],
      textDecoration: 'none',
      transition: 'color .250s',
      '@media (pointer: fine)': {
        '&:hover': {
          filter: 'brightness(92%)'
        }
      }
    }
  }
};

const visuals = {
  'Mui-ParsedHTML': {
    root: {
      display: 'flex',
      justifyContent: 'center',
      padding: '.5rem 0 1rem'
    }
  },

  // VideoPlayer for MrReset Video Demo
  'Mui-VideoPlayer': {
    root: {
      position: 'relative',
      left: '-28px',
      top: '-32px',
      width: 'calc(100% + 48px)'
    },
    playButton: {
      backgroundColor: palette.ycOrange[500]
    }
  },

  'Mui-LottiePlayer': {
    root: {
      margin: '0 auto'
    }
  }
};

const progress = {
  'Mui-ProgressBar': {
    root: {
      margin: '-24px 0 32px'
    },
    label: {
      color: palette.ycOrange[500]
    }
  }
};

const popOuts = {
  'Mui-SnackbarLongBase': {
    snackbar: {
      background: palette.ycGreen[900]
    },
    body: {
      ...typography.body2
    }
  },

  'Mui-AlertMessage': {
    body: {
      ...typography.body2
    },
    fillError: {
      background: palette.ycRed[500]
    }
  },

  'Mui-SimpleModal': {
    closeButton: {
      alignSelf: 'flex-end',
      height: 24,
      padding: 0,
      width: 24
    },
    header: {
      alignItems: 'flex-start',
      borderBottom: 'none',
      flexDirection: 'column',
      padding: '24px 24px 8px 24px'
    },
    headerTitle: {
      color: palette.ycGreen[900],
      ...customTypographyVariants.h3Mobile,
      fontWeight: 600,
    },
    content: {
      color: palette.ycGreen[500],
      padding: '16px 24px 24px 24px',
      textAlign: 'justify',
      '& p:first-child': {
        marginTop: 0
      },
      '& p:last-child': {
        marginBottom: 0
      },
      '& a': {
        textDecoration: 'none',
        fontWeight: 500
      }
    }
  }
};

const list = {
  'Mui-List': {
    root: {
      width: 'auto'
    }
  }
};

const responseAtoms = {
  'Mui-ResponseLink': {
    root: {
      color: palette.ycGreen[500],
      margin: 0,
      paddingBottom: '8px'
    },
    link: {
      ...typography.body2,
      color: palette.ycOrange[500],
      textDecoration: 'none',
      transition: 'color .250s',
      '@media (pointer: fine)': {
        '&:hover': {
          filter: 'brightness(92%)'
        }
      }
    }
  },
  'Mui-SingleSelectBase': {
    label: {
      color: palette.ycGreen[900]
    },
    input: {
      color: palette.ycGreen[500]
    },
    inputIcon: {
      fill: palette.ycGreen[500]
    },
    menu: {
      boxShadow: shadows[5]
    },
    menuItem: {
      color: palette.ycGreen[900],
      '@media (pointer: fine)': {
        '&:hover': {
          background: `${palette.ycSand[300]} !important`,
          color: palette.ycGreen[900]
        }
      },
      '&:focus': {
        background: `${palette.ycGreen[500]} !important`,
        color: palette.white
      }
    },
    menuItemSelected: {
      background: `${palette.ycGreen[500]} !important`,
      color: palette.white
    }
  },

  'Mui-TypeInBase': {
    root: {
      maxWidth: '450px'
    },
    label: {
      color: palette.ycGreen[900]
    },
    input: {
      color: palette.ycGreen[500],
      caretColor: palette.ycOrange[500],
      '&:before': {
        borderBottom: `1px solid ${palette.ycGrey[500]}`
      },
      '&:after': {
        borderBottom: `2px solid ${palette.ycGreen[500]}`
      }
    }
  },
  'Mui-ButtonGrid': {
    root: {
      maxWidth: '450px',
      width: '100%',
      margin: '0 auto'
    }
  },
  'Mui-TileButtonBase': {
    root: {
      background: palette.white,
      borderRadius: '8px',
      color: palette.ycGreen[900],
      border: `1px solid ${palette.ycGreen[900]}`,
      transitionDelay: '.30s',
      transition: 'background .6s ease, border-color .5s ease, color .5s ease',
      boxShadow: shadows[4],
      '@media (pointer: fine)': {
        '&:hover': {
          boxShadow: shadows[11]
        }
      },
      '&:active': {
        background: `${palette.ycSand[300]} !important`
      }
    },
    buttonLabel: {
      ...customTypographyVariants.sh1
    },
    buttonDescription: {
      fontWeight: '300',
      '& ul': {
        margin: 0,
        paddingLeft: 16,
        '& li': {
          paddingBottom: '8px'
        }
      }
    },
    selectedOption: {
      background: `${palette.ycSand[300]} !important`,
      '.multi-select &': {
        background: `${palette.ycGreen[500]} !important`, // ycGreen900 + 80% white overlay
        color: palette.white,
        border: '1px solid transparent'
      }
    },
    square: {
      fontSize: '1.5rem !important',
      '@media (max-width: 600px)': {
        fontSize: '1rem !important'
      }
    }
  }
};

export const overrides = {
  ...list,
  ...visuals,
  ...progress,
  ...variants,
  ...layout,
  ...footerNavigation,
  ...leftPanel,
  ...prompt,
  ...popOuts,
  ...responseAtoms
};
