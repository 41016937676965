import React from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export type TileButtonProps = {
  uuid: string;
  onClick: () => void;
  label: string;
  description?: string;
  icon?: string;
  // if sending a url is preferred
  iconUrl?: string;
  variant?: 'standard' | 'leftAligned' | 'square';
  isSelected?: boolean;
  disabled?: boolean;
  iconPosition?: 'default' | 'leading' | 'trailing';
};

const TileButtonBase: React.FC<TileButtonProps> = (
  {
    uuid,
    onClick,
    label,
    description = '',
    icon = '',
    variant = 'standard',
    isSelected = false,
    disabled = false,
    iconPosition = 'default',
    iconUrl
  }
) => {
  const classes = useStyles();
  return (
    <button
      type="button"
      className={clsx(classes.root, {
        [classes.standard]: variant === 'standard',
        [classes.leftAligned]: variant === 'leftAligned',
        [classes.square]: variant === 'square',
        [classes.selectedOption]: isSelected,
        [classes.disabled]: disabled,
        [classes.leadingIcon]: (icon || iconUrl) && iconPosition === 'leading',
        [classes.trailingIcon]:
          (icon || iconUrl) && iconPosition === 'trailing',
        [classes.defaultIcon]: (icon || iconUrl) && iconPosition === 'default'
      })}
      onClick={onClick}
      disabled={disabled}
      data-testid={`TileButtonBase-${uuid}`}
    >
      {(icon && parse(icon)) ||
        (iconUrl && (
          <img
            src={iconUrl}
            alt=""
            data-testid="button-icon"
            className={classes.imgUrlIcon}
          />
        ))}
      <span className={classes.buttonLabel}>{decodeURIComponent(label)}</span>
      {description && (
        <span className={classes.buttonDescription}>{parse(description)}</span>
      )}
    </button>
  );
};

const useStyles = makeStyles((theme) => 
  createStyles({
    root: {
      alignItems: 'center',
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      margin: 0, // Remove the margin in Safari
      outline: 0,
      padding: '1em', // Remove the padding in Firefox
      position: 'relative',
      textDecoration: 'none',
      userSelect: 'none',
      verticalAlign: 'middle',
      MozAppearance: 'none', // Reset
      WebkitTapHighlightColor: 'transparent',
      WebkitAppearance: 'none', // Reset
      '&::-moz-focus-inner': {
        borderStyle: 'none', // Remove Firefox dotted outline.
      },

      // defaults
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      border: 'none',
      borderRadius: '8px',
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      transition: '.1s background',

      '&:focus-visible': {
        outline: '3px solid #005FCC' // dev-defined a11y color
      },
      '&:-moz-focusring': {
        outline: '3px solid #005FCC' // dev-defined a11y color
      }
    },
    disabled: {
      pointerEvents: 'none',
      cursor: 'default'
    },
    // "selected" is a reserved word on components prefixed with "mui"
    selectedOption: {
      background: theme.palette.primary.light
    },
    buttonLabel: {
      fontWeight: 500,
      width: '100%'
    },
    buttonDescription: {
      marginTop: '1em',
      width: '100%'
    },
    standard: {},
    square: {},
    leftAligned: {
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    leadingIcon: {
      position: 'relative',
      '& $buttonLabel': {
        margin: '0 36px',
        width: 'auto'
      },
      '& svg, img': {
        width: '24px',
        height: 'auto',
        position: 'absolute',
        left: '16px',
        top: '50%',
        marginTop: '-12px'
      }      
    },
    trailingIcon: {
      position: 'relative',
      '& $buttonLabel': {
        margin: '0 36px',
        width: 'auto'
      },
      '& svg, img': {
        width: '24px',
        height: 'auto',
        position: 'absolute',
        right: '16px',
        top: '50%',
        marginTop: '-12px'
      }   
    },
    defaultIcon: {

    },
    imgUrlIcon: {

    }
  }), { name: 'Mui-TileButtonBase' }
);


export default TileButtonBase;