import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * Body text.
   */
  body: string;
  /**
   * SVG element use as an icon
   */
  icon?: string;
  /**
   * variant type
   */
  variant?: 'standard' | 'outlined' | 'filled';
  /**
   * Alert type.
   */
  severity: 'error' | 'warning' | 'info' | 'success';
  /**
   * Notification open state.
   */
  isOpen?: boolean;
  /**
   * Allow the alert to be closed or not.
   */
  isClosable?: boolean;
}

const AlertMessage: React.FC<Props> = (props) => {
  const { body, isOpen = true, severity, icon, variant = 'filled', isClosable } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(isOpen);

  return (
    <Collapse in={open}>
      <Alert 
        classes={{
          root: classes.root,
          standardSuccess: classes.standSuccess,
          standardError: classes.standError,
          standardInfo: classes.standInfo,
          standardWarning: classes.standWarning,
          outlinedSuccess: classes.outSuccess,
          outlinedError: classes.outError,
          outlinedInfo: classes.outInfo,
          outlinedWarning: classes.outWarning,
          filledSuccess: classes.fillSuccess,
          filledError: classes.fillError,
          filledInfo: classes.fillInfo,
          filledWarning: classes.fillWarning,
          message: classes.body
        }}
        variant={variant}
        severity={severity}
        icon={icon ? parse(icon) : undefined}
        action={
          isClosable && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
      >
        {body}
      </Alert>
    </Collapse>
  );
};

const useStyles = makeStyles({
  root: {},
  body:{},
  standSuccess:{},
  standError: {},
  standInfo: {},
  standWarning: {},
  outSuccess: {},
  outError: {},
  outInfo: {},
  outWarning: {},
  fillSuccess: {},
  fillError: {},
  fillInfo: {},
  fillWarning: {}
},
{ name: 'Mui-AlertMessage' }
);

export default AlertMessage;
