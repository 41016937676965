import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ComponentWithChildren } from '../../models/models';


const PromptContainer: React.FC<ComponentWithChildren> = ({children}) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingBottom:'24px'
  }
}, { name: 'Mui-PromptContainer' });

export default PromptContainer;