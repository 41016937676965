export const keleGreen = {
  700: '#006642',
  600: '#008255',
  500: '#20CE9C',
  50: '#F4FEEF'
};

export const keleGray = {
  main: '#25313E',
  body: '#616367',
  light: '#F6F5F5'
};

export const success = {
  500: '#439B18',
  100: '#DEF5D2',
  50: '#F4FEEF'
};

export const keleRed = {
  500: '#FF3535',
  100: '#FFD7D7',
  50: '#FFF5F5'
};

export const keleYellow = {
  500: '#FF9900',
  100: '#FFF3B3',
  50: '#FFFAE0'
};

export const keleBlue = {
  500: '#3368FF',
  50: '#F5F8FF'
};

export const white = '#FFFFFF';
