import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import TileBase from '../../atoms/TileButtonBase';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';
import { ComponentBase } from '../../../models/models';

type ButtonGridOptionsType = {
  uuid: string;
  label: string;
  description?: string;
  icon?: string;
  iconUrl?: string;
};

type GridColumnsType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type GridGapsType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface ButtonGridProps extends ComponentBase {
  /** A unique id for accessibility. */
  uuid: string;
  /** Type of TileButton to render. */
  tileVariant?: 'standard' | 'leftAligned' | 'square';
  /** Position of Icon to render. */
  iconPosition?: 'default' | 'leading' | 'trailing';
  /** Allow multiple responses. */
  isMultiSelect?: boolean;
  /** Number of columns for all screen widths, lowest priority. */
  xsCol?: GridColumnsType;
  /** Number of columns, applied at the `sm` breakpoint. */
  smCol?: GridColumnsType;
  /** Number of columns, applied at the `md` breakpoint. */
  mdCol?: GridColumnsType;
  /** Number of columns, applied at the `lg` breakpoint. */
  lgCol?: GridColumnsType;
  /** Number of columns, applied at the `xl` breakpoint. */
  xlCol?: GridColumnsType;
  /** Gap between columns and rows for all screen widths, lowest priority. */
  xsGap?: GridGapsType;
  /** Gap between columns and rows, applied at the `sm` breakpoint. */
  smGap?: GridGapsType;
  /** Gap between columns and rows, applied at the `md` breakpoint. */
  mdGap?: GridGapsType;
  /** Gap between columns and rows, applied at the `lg` breakpoint. */
  lgGap?: GridGapsType;
  /** Gap between columns and rows, applied at the `xl` breakpoint. */
  xlGap?: GridGapsType;
  /** Array of buttons, each with a string for uuid, label, description (optional), icon (optional) */
  options: ButtonGridOptionsType[];
}

const GRID_COLUMNS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const GRID_GAPS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

// Build out useStyles breakpoint classes
const generateBreakpoint = (bpKey: string, theme: Theme) => {
  const bpStyles: Record<string, Record<any, string>> = {};

  GRID_COLUMNS.forEach((cols) => {
    const key = `grid-col-${bpKey}-${cols}`;
    bpStyles[key] = {
      gridTemplateColumns: `repeat(${cols}, 1fr)`,
    };
  });

  GRID_GAPS.forEach((gap) => {
    const key = `grid-gap-${bpKey}-${gap}`;
    bpStyles[key] = {
      gridGap: `${theme.spacing(gap)}px`,
      gap: `${theme.spacing(gap)}px`,
    };
  });

  return bpStyles;
};

const ButtonGrid: React.FC<ButtonGridProps> = ({
  uuid,
  tileVariant = 'standard',
  iconPosition = 'default',
  isMultiSelect = false,
  xsCol = false,
  smCol = false,
  mdCol = false,
  lgCol = false,
  xlCol = false,
  xsGap = false,
  smGap = false,
  mdGap = false,
  lgGap = false,
  xlGap = false,
  options
}) => {
  const {
    registerResponseComponent,
    logResponse,
    finishStep
  } = useRadioDispatch();
  const radioState = useRadioState();
  const classes: any = useStyles();

  const initValue = React.useMemo(() => {
    return (radioState.apiData.responses?.[uuid] as string[]) || [];
  }, [radioState.apiData.responses, uuid]);

  const initValueSet = new Set(initValue);
  // hook is a Set type in order to make it easy & fast to add/remove selected strings and have no duplicates
  const [selectValue, setSelectValue] = React.useState<Set<string>>(
    initValueSet
  );

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: isMultiSelect ? 'multiSelect' : 'select', // ButtonGrid works just like select
      componentId: uuid,
      value: initValue.length > 0 ? initValue : null
    });
  }, [registerResponseComponent, uuid, initValue, isMultiSelect]);

  const handleClick = (optUuid: string) => {
    if (selectValue.has(optUuid) && isMultiSelect) {
      setSelectValue((prevSet) => {
        const newSet = new Set(prevSet);
        newSet.delete(optUuid);
        const newArray = Array.from(newSet);
        logResponse({
          componentId: uuid,
          value: newArray.length > 0 ? newArray : null
        });
        return newSet;
      });
    } else if (isMultiSelect) {
      setSelectValue((prevSet) => {
        const newSet = new Set(prevSet);
        newSet.add(optUuid);
        const newArray = Array.from(newSet);
        logResponse({
          componentId: uuid,
          value: newArray.length > 0 ? newArray : null
        });
        return newSet;
      });
    } else {
      // if single select only allow one response:
      setSelectValue(new Set([optUuid]));
      logResponse({
        componentId: uuid,
        value: [optUuid]
      });
      finishStep();
    }
  };

  const className = clsx(classes.root, {
    'multi-select': isMultiSelect,
    [classes.squareTiles]: tileVariant === 'square',
    [classes[`grid-col-xs-${String(xsCol)}`]]: xsCol !== false,
    [classes[`grid-col-sm-${String(smCol)}`]]: smCol !== false,
    [classes[`grid-col-md-${String(mdCol)}`]]: mdCol !== false,
    [classes[`grid-col-lg-${String(lgCol)}`]]: lgCol !== false,
    [classes[`grid-col-xl-${String(xlCol)}`]]: xlCol !== false,
    [classes[`grid-gap-xs-${String(xsGap)}`]]: xsGap !== false,
    [classes[`grid-gap-sm-${String(smGap)}`]]: smGap !== false,
    [classes[`grid-gap-md-${String(mdGap)}`]]: mdGap !== false,
    [classes[`grid-gap-lg-${String(lgGap)}`]]: lgGap !== false,
    [classes[`grid-gap-xl-${String(xlGap)}`]]: xlGap !== false
  });

  return (
    <div className={className}>
      {options.map((item) => (
        <TileBase
          uuid={item.uuid}
          label={item.label}
          description={item.description}
          icon={item.icon}
          iconUrl={item.iconUrl}
          iconPosition={iconPosition}
          key={item.uuid}
          variant={tileVariant}
          isSelected={selectValue.has(item.uuid)}
          onClick={() => handleClick(item.uuid)}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'grid',
        gridGap: `${theme.spacing(2)}px`,
        gap: `${theme.spacing(2)}px`,
        gridAutoRows: '1fr',
        gridTemplateColumns: '1fr',
      },
      squareTiles: {
        gridAutoRows: '1fr',
        '&::before': {
          content: '""',
          gridColumn: '1 / 1',
          gridRow: '1 / 1',
          paddingBottom: '100%',
          width: '0',
        },
        '& > *:first-child': {
          gridColumn: '1 / 1',
          gridRow: '1 / 1',
        },
      },
      ...theme.breakpoints.keys.reduce((acc, bpKey) => {
        if (bpKey === 'xs') {
          return { ...acc, ...generateBreakpoint(bpKey, theme) };
        }
        const mediaQuery = theme.breakpoints.up(bpKey);
        return { ...acc, [mediaQuery]: generateBreakpoint(bpKey, theme) };
      }, {}),
    }),
  { name: 'Mui-ButtonGrid' }
);

export default ButtonGrid;
