import * as React from 'react';
import parse from 'html-react-parser';
import { darken, makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * label used inside the chip.
   */
  label: string;
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid?: string; 
  /**
   * source for an avatar image.
   */
  avatarSource?: string;
  /**
   * avatar as letter.
   */
  avatarLetter?: string;
  /**
   * Function triggered when button is clicked.
   */
  handleClick?: (uuid?: string) => void;
  /**
   * Function triggered when delete button is clicked.
   */
  handleDelete?: () => void;
  /**
   * background color for chip.
   */
  color?: 'default' | 'primary' | 'secondary';
  /**
   * background color for chip.
   */
  size?: 'medium' | 'small';
  /**
   * background color for chip.
   */
  variant?: 'default' | 'outlined';
  /**
   * Chip type.
   */
  severity?: 'error' | 'warning' | 'info' | 'success';
  /**
   * icon that will be parsed to be converted to an html element.
   */
  icon?: string;
  /**
   * delete icon that will be parsed to be converted to an html element.
   */
  deleteIcon?: string;
  /**
   * whether the chip should be in a disabled state.
   */
  disabled?: boolean;
}

const ChipLabel: React.FC<Props> = ({
  label,
  uuid,
  severity,
  avatarSource,
  avatarLetter,
  handleClick,
  handleDelete,
  color = 'default',
  size = 'medium',
  variant = 'default',
  icon,
  deleteIcon,
  disabled
}) => {
  const classes = useStyles();

  const AvatarIcon = () => {
    return(
      avatarSource ?
        (
          <Avatar className={classes.avatar} alt="Avatar icon" src={avatarSource} />
        ) : (
          <Avatar className={
            clsx(
              classes.avatar,
              {
                [classes.avatarColorPrimary]: color === 'primary',
                [classes.avatarColorSecondary]: color === 'secondary'
              }
            )
          }
          >
            {avatarLetter}
          </Avatar>
        )
    );
  };
  return (
    <Chip
      label={label}
      avatar={avatarSource || avatarLetter ? <AvatarIcon /> : undefined}
      onClick={handleClick && (() => handleClick(uuid))}
      onDelete={handleDelete}
      color={color}
      size={size}
      variant={variant}
      icon={icon ? (parse(icon) as JSX.Element) : undefined}
      deleteIcon={
        deleteIcon && handleDelete
          ? (parse(deleteIcon) as JSX.Element)
          : undefined
      }
      disabled={disabled}
      className={clsx(classes.root, {
        [classes.success]: severity === 'success',
        [classes.error]: severity === 'error',
        [classes.warning]: severity === 'warning',
        [classes.info]: severity === 'info',
        [classes.warning]: severity === 'warning'
      })}
      classes={{
        root: classes.root,
        sizeSmall: classes.sizeSmall,
        colorPrimary: classes.colorPrimary,
        colorSecondary: classes.colorSecondary,
        disabled: classes.disabled,
        clickable: classes.clickable,
        deletable: classes.deletable,
        outlined: classes.outlined,
        outlinedPrimary: classes.outlinedPrimary,
        outlinedSecondary: classes.outlinedSecondary,
        avatar: classes.avatar,
        icon: classes.icon,
        iconSmall: classes.iconSmall,
        label: classes.label,
        labelSmall: classes.labelSmall,
        deleteIcon: classes.deleteIcon,
        deleteIconSmall: classes.deleteIconSmall
      }}
    />
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      sizeSmall: {},
      colorPrimary: {},
      colorSecondary: {},
      disabled: {},
      clickable: {},
      deletable: {},
      outlined:{},
      outlinedPrimary:{},
      outlinedSecondary:{},
      avatar:{
        height: '24px',
        width: '24px',
        marginLeft: '.25rem',
        fontSize: '.75rem'
      },
      avatarColorPrimary:{
        background: darken(theme.palette.primary.main, 0.15)
      },
      avatarColorSecondary:{
        background: darken(theme.palette.secondary.main, 0.15)
      },
      icon:{
        paddingLeft: '.25rem'
      },
      iconSmall:{},
      label:{},
      labelSmall:{},
      deleteIcon:{},
      deleteIconSmall:{},
      success:{},
      error:{},
      warning:{},
      info:{}
    }),
  { name: 'Mui-ChipLabel' }
);

export default ChipLabel;
