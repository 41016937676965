import * as React from 'react';
import parse from 'html-react-parser';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * HTML string that will be parsed and rendered.
   */
  content: string;
}

const ParsedHTML: React.FC<Props> = ({ content }) => {
  const classes = useStyles();

  return <div className={classes.root}>{parse(content)}</div>;
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        width: '100%'
      }
    }),
  { name: 'Mui-ParsedHTML' }
);

export default ParsedHTML;
