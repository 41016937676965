/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { ComponentBase } from '../../models/models';
import { useDebounce } from '../../helpers/useDebounce';

interface Source {
  source: string,
  type: string
}

export interface Props extends ComponentBase {
  /**
   * Whether to autoplay video.
   */
  autoplay?: boolean;
  /**
   * Whether to show native controls.
   */
  controls?: boolean;
  /**
   * video url and types array.
   */
  sources: Source[];
  /**
   * image poster url.
   */
  poster?: string;
  /**
   * Whether to loop animation.
   */
  loop?: boolean;
  /**
   * Whether to mute the video.
   */
  muted?: boolean;
  /**
   * Width size.
   */
  width?: string;
  /**
   * play button icon.
   */
  playButtonIcon?: string;
}

const defaultPlayButtonIcon = `<svg data-testid="default-play-icon" width="100%" height="100%" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0V14L11 7L0 0Z" fill="white"/>
</svg>`;

const VideoPlayer: React.FC<Props> = ({
  autoplay = false,
  controls = false,
  sources,
  poster,
  muted = false,
  loop,
  width = '100%',
  playButtonIcon
}) => {
  const classes = useStyles({ sources, loop, width });
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );
  const [showCustomControls, setShowCustomControls] = React.useState<boolean>(true);
  const [wholeScreenButton, setWholeScreenButton] = React.useState<boolean>(true);
  const [videoLength, setVideoLength] = React.useState<string>();
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const updateWidthDebounced = useDebounce(() => 
    setWindowWidth(window.innerWidth)
  );

  React.useEffect(() => {
    if (windowWidth < 600) {
      setWholeScreenButton(false);
    } else {
      setWholeScreenButton(true);
    }
    window.addEventListener('resize', updateWidthDebounced);
    return () => window.removeEventListener('resize', updateWidthDebounced);
  }, [windowWidth, updateWidthDebounced]);

  React.useEffect(() => {
    const handleCloseFullScreen = () => {
      const documentExtended = document as Document & {
        webkitFullscreenElement: Element | null;
        mozFullScreenElement: Element | null;
        msFullscreenElement: Element | null;
      };
      if (
        !documentExtended.fullscreenElement &&
        !documentExtended.webkitFullscreenElement &&
        !documentExtended.mozFullScreenElement &&
        !documentExtended.msFullscreenElement &&
        videoRef.current
      ) {
        videoRef.current.controls = false;
        videoRef.current.pause();
        videoRef.current.load();
        setShowCustomControls(true);
      }
    };

    document.addEventListener('fullscreenchange', handleCloseFullScreen, false);
    document.addEventListener(
      'webkitfullscreenchange',
      handleCloseFullScreen,
      false
    );
    document.addEventListener(
      'mozfullscreenchange',
      handleCloseFullScreen,
      false
    );
    document.addEventListener(
      'msfullscreenchange',
      handleCloseFullScreen,
      false
    );
    
    // for mobile iOS
    videoRef.current?.addEventListener('webkitendfullscreen', handleCloseFullScreen, false);

    return () => {
      window.removeEventListener(
        'fullscreenchange',
        handleCloseFullScreen,
        false
      );
      window.removeEventListener(
        'webkitfullscreenchange',
        handleCloseFullScreen,
        false
      );
      window.removeEventListener(
        'mozfullscreenchange',
        handleCloseFullScreen,
        false
      );
      window.removeEventListener(
        'msfullscreenchange',
        handleCloseFullScreen,
        false
      );
      window.removeEventListener('webkitendfullscreen', handleCloseFullScreen, false);
    };
  }, []);

  const handlePlay = () => {
    if (videoRef.current) {
      const video = videoRef.current as HTMLVideoElement & {
        mozRequestFullScreen(): Promise<void>;
        webkitRequestFullscreen(): Promise<void>;
        msRequestFullscreen(): Promise<void>;
        webkitEnterFullScreen(): Promise<void>;
      };

      video.play();
      video.controls = true;
      setShowCustomControls(false);

      if (windowWidth < 600) {
        
        if (video.requestFullscreen) {
          video.requestFullscreen();
        }

        /* Firefox */
        if (video.mozRequestFullScreen) {
          video.mozRequestFullScreen();
        }

        /* Chrome, Safari & Opera */
        if (video.webkitRequestFullscreen) {
          video.webkitRequestFullscreen();
        }

        /* IE/Edge */
        if (video.msRequestFullscreen) {
          video.msRequestFullscreen();
        }

        /* Safari mobile (iphone) */
        if (video.webkitEnterFullScreen) {
          video.webkitEnterFullScreen();
        }
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      handlePlay();
    }
  };

  // helper function
  const padWithZeros = (num: number, size: number) => {
    let numString = num.toString();
    while (numString.length < size) numString = `0${numString}`;
    return numString;
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const getVideoLength = () => {
        const durationInSeconds = videoRef.current && videoRef.current.duration;
        const minutes = Math.floor((durationInSeconds as number) / 60);
        const seconds = Math.ceil(durationInSeconds as number) - minutes * 60;
        const displayedTime = `${minutes}:${padWithZeros(seconds, 2)}`;
        return displayedTime;
      };
      setVideoLength(getVideoLength());
    }
  };

  return (
    <div className={classes.root}>
      <video
        autoPlay={autoplay}
        controls={controls}
        poster={poster}
        muted={muted}
        width={width}
        ref={videoRef}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={() => document.exitFullscreen()}
      >
        {sources.map((item) => {
          return (
            <source
              src={item.source}
              type={`video/${item.type}`}
              key={item.source}
            />
          );
        })}
        Your browser does not support the video tag.
      </video>
      {!autoplay && !controls && showCustomControls && (
        <>
          <button
            type="button"
            onClick={handlePlay}
            className={classes.playButton}
            aria-label="Play"
          >
            {parse(playButtonIcon || defaultPlayButtonIcon)}
          </button>
          <span className={classes.duration}>{videoLength}</span>
          {wholeScreenButton && 
          <div className={classes.playArea} tabIndex={0} onClick={handlePlay} role='button' onKeyPress={handleKeyPress} aria-label="Play button area" />}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main // as fallback to ios missing first frame
      },
      playButton: {
        // remove default styles
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
        // actual styles
        height: '56px',
        width: '56px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-28px 0 0 -28px',
        zIndex: 1,
        '& svg': {
          paddingLeft: '4px',
          height: 'auto',
          width: '14px'
        }
      },
      playArea: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        cursor: 'pointer'
      },
      duration: {
        color: theme.palette.grey[600],
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.caption.fontWeight,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: theme.typography.caption.letterSpacing,
        position: 'absolute',
        bottom: '1rem',
        left: '16px',
        width: '100%'
      }
    }),
  { name: 'Mui-VideoPlayer' }
);

export default VideoPlayer;
