import React from 'react';

import SingleSelectBase, { SelectOption } from '../../../atoms/SingleSelectBase';

import { DateTime } from './DateTimeSelect';
import { ComponentWithVariants } from '../../../../models/models';


export interface Props extends ComponentWithVariants {
  /**
     * A unique id for accessibility.
     */
  uuid: string,
  /**
     * A short title displayed above the field.
     */
  label: string,
  /**
   * Text displayed when no value is set.
   */
  placeholder?: string,
  /**
   * Short string below the select, useful for errors.
   */
  helperText?: string,
  /**
   * Red-ify the field.
   */
  error?: boolean,
  /**
   * dateTimeStore array.
   */
  dateTimeStore: DateTime[],
  /**
   * index of selected Date option.
   */
  selectedDate: number | string,
  /**
   * hook to select a Date.
   */
  setSelectedDate: React.Dispatch<React.SetStateAction<number | string>>,
  /**
   * hook to reset the time.
   */
  setSelectedTime: React.Dispatch<React.SetStateAction<number | string>>
}
  
const DateSelect: React.FC<Props> = (
  {
    uuid,
    label,
    placeholder = '',
    helperText = '',
    error = false,
    dateTimeStore, 
    selectedDate,
    setSelectedDate,
    setSelectedTime
  }
) => {

  const [dateOptions, setDateOptions] = React.useState<SelectOption[]>([]);

  React.useEffect(()=> {
    if(!!dateTimeStore && dateTimeStore.length) {
      setDateOptions(
        dateTimeStore.map((date:DateTime, index) => {
          return({
            display: date.date.display,
            value: date.date.value,
            uuid: index
          });
        })
      );
    }
  }, [dateTimeStore]);

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const optionIndex = ev.target.value as number;
    setSelectedTime('');
    setSelectedDate(optionIndex);
  };
  
  return (
    <SingleSelectBase 
      uuid={uuid}
      label={label}
      options={(!!dateOptions && dateOptions.length) ? dateOptions : null}
      selectValue={selectedDate}
      handleChange={handleChange}
      placeholder={placeholder}
      helperText={helperText}
      error={error}
    />
  );
};
  

export default DateSelect;