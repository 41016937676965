import { Grid } from '@material-ui/core';
import * as React from 'react';
import { ComponentWithChildren } from '../../models/models';

export interface Props extends ComponentWithChildren {
  /**
   * Defines the align-content style property.
   */
  alignContent?:
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around';
  /**
   * Defines the align-items style property.
   */
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  /**
   * Defines the flex-direction style property.
   */
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  /**
   * Defines the align-items style property.
   */
  justify?:
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
}

const GridItem12120604: React.FC<Props> = ({
  children,
  alignContent,
  alignItems,
  direction,
  justify
}) => {
  return (
    <Grid
      container={!!alignContent || !!alignItems || !!justify || !!direction}
      item
      alignContent={alignContent}
      alignItems={alignItems}
      justify={justify}
      direction={direction}
      xs={12}
      sm={12}
      md={6}
      lg={4}
    >
      {children}
    </Grid>
  );
};

export default GridItem12120604;
