// Manually import and set Shadow type because TypeScript...
import { Shadows } from '@material-ui/core/styles/shadows';

export const shadows: Shadows = [
  'none',
  'none',
  'none',
  'none',
  '0px 2px 8px -1px rgba(0, 0, 0, 0.05), 0px 4px 10px rgba(0, 0, 0, 0.035), 0px 1px 20px rgba(0, 0, 0, 0.03)',
  'none', // elevation 5
  'none',
  'none',
  'none',
  'none',
  'none', // elevation 10
  'none',
  'none',
  'none',
  'none',
  'none', // elevation 15
  'none',
  'none',
  'none',
  'none',
  'none', // elevation 20
  'none',
  'none',
  'none',
  'none'
];
