import React from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import { ComponentWithVariants,
  //  Variants 
} from '../../models/models';

export interface Props extends ComponentWithVariants {
  /**
   * A very short name for the step.
   */
  label: string;
  /**
   * An svg icon that replaces the progress rail.
   */
  progressIcon?: string;
  /**
   * An svg icon that replaces the progress icon when stage completed.
   */
  completedIcon?: string;
  /**
   * Is this step currently active?
   */
  isActive?: boolean;
  /**
   * Is this step completed?
   */
  isCompleted?: boolean;
  /**
   * Remove the progress sidebar, to be replaced by image icon
   */
  disableRail?: boolean;
}

const ProgressStage: React.FC<Props> = ({
  label,
  isActive = false,
  isCompleted = false,
  disableRail = false,
  progressIcon,
  completedIcon
  // variant = 'main' as Variants
}) => {
  const classes = useStyles();
  const listClassName = clsx(classes.root, {
    [classes.progressRail]: !disableRail && !progressIcon,
    [classes.stepActive]: isActive,
    [classes.stepCompleted]: isCompleted
  });

  return (
    <li className={listClassName} data-testid="progress-stage">
      {!isCompleted && progressIcon && parse(progressIcon)}
      {isCompleted && completedIcon && parse(completedIcon)}
      <span className={classes.label}>{label}</span>
    </li>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        '& svg': {
          marginRight: theme.spacing(1)
        },
      },
      progressRail: {
        paddingLeft: '20px',
        '&:first-child::before': {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        },
        '&::before': {
          background: 'transparent',
          content: '""',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '4px',

          '$stepActive&': {
            background: theme.palette.primary.contrastText,
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px'
          },
          '$stepCompleted&': {
            background: theme.palette.primary.contrastText
          }
        }
      },
      stepCompleted: {},
      stepActive: {},
      label: {
        ...theme.typography.body2,
        color: fade(theme.palette.primary.contrastText, 0.2),
        padding: `${theme.spacing(1)}px 0`,
        '$stepActive &': {
          color: theme.palette.primary.contrastText
        },
        '$stepCompleted &': {
          color: theme.palette.primary.contrastText
        }
      }
    }),
  { name: 'Mui-ProgressStage' }
);

export default ProgressStage;