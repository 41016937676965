import React from 'react';
import ButtonGrid from '../ButtonGrid/ButtonGrid';
import { ComponentWithVariants } from '../../../models/models';

type ButtonGridOptionsType = {
  uuid: string;
  label: string;
  description?: string;
  icon?: string;
};

export interface YCSpeakNowOrLaterButtonProps extends ComponentWithVariants {
  /**
   * A unique id for the Speak Now button.
   */
  uuid: string;
  /**
   * options that are fed to the ButtonGrid component and displayed.
   */
  options: ButtonGridOptionsType[];
}

export const canSpeakNow = (date: Date) => {
  // Mon-Fri
  if (date.getUTCDay() >= 1 && date.getUTCDay() < 6) {
    if (date.getUTCHours() > 3 && date.getUTCHours() < 15) {
      return false;
    }
    return true;
    // Sat & Sun
  }
  if (date.getUTCHours() > 3 && date.getUTCHours() < 17) {
    return false;
  }
  return true;
};

const YCSpeakNowOrLaterButton: React.FC<YCSpeakNowOrLaterButtonProps> = ({
  uuid,
  options,
}) => {
  const [currentDateTime, setCurrentDateTime] = React.useState<Date>(
    new Date()
  );
  const [buttonGridOptions, setButtonGridOptions] = React.useState<
    ButtonGridOptionsType[]
  >();

  // Update currentDateTime variable every 15 mins, so user cant click speak now after window inactivity and time is now longer withing available hours
  React.useEffect(() => {
    const updateCurrent = () => {
      setCurrentDateTime(new Date());
    };
    const updateInterval = setInterval(updateCurrent, 900000);
    return () => clearInterval(updateInterval);
  }, []);

  React.useEffect(() => {
    if (!canSpeakNow(currentDateTime)) {
      setButtonGridOptions(
        options.filter((option) => option.uuid !== 'speakNow')
      );
    } else {
      setButtonGridOptions(options);
    }
  }, [currentDateTime, options]);
  return (
    <>
      {buttonGridOptions && (
        <ButtonGrid
          uuid={uuid}
          options={buttonGridOptions}
          xsCol={1}
          smCol={buttonGridOptions.length as 1 | 2}
        />
      )}
    </>
  );
};

export default YCSpeakNowOrLaterButton;
