import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../../models/models';
import ExpandableCard, {
  Props as ExpandableCardProps
} from '../../atoms/ExpandableCard';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';

export interface Props extends ComponentBase {
  /**
   * multi or single select
   */
  selectType?: 'single' | 'multiple' | 'none';
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid: string;
  /**
   * array of expandable cards, each with a uuid, values, chip props, etc.
   */
  expandableCardOptions: ExpandableCardProps[];
  /**
   * If false (default) & single select, finishStep will be triggered onClick
   */
  confirmationRequired?: boolean;
}

const ExpandedCardList: React.FC<Props> = ({
  selectType = 'none',
  uuid,
  expandableCardOptions,
  confirmationRequired = false
}) => {
  const {
    registerResponseComponent,
    logResponse,
    finishStep
  } = useRadioDispatch();
  const radioState = useRadioState();
  const initValue = React.useMemo(() => {
    return (radioState.apiData.responses?.[uuid] as string[]) || [];
  }, [radioState.apiData.responses, uuid]);

  const initValueSet = new Set(initValue);
  // hook is a Set type in order to make it easy & fast to add/remove selected strings and have no duplicates
  const [selectValue, setSelectValue] = React.useState<Set<string>>(
    initValueSet
  );

  const classes = useStyles();

  React.useEffect(() => {
    if (selectType !== 'none' && uuid) {
      registerResponseComponent({
        componentType: selectType === 'multiple' ? 'multiSelect' : 'select', // CardList works just like select
        componentId: uuid,
        value: initValue.length > 0 ? initValue : null
      });
    }
  }, [registerResponseComponent, uuid, selectType, initValue]);

  const handleClick = (cardUuid: string) => {
    if (selectValue.has(cardUuid) && selectType === 'multiple') {
      setSelectValue((prevSet) => {
        const newSet = new Set(prevSet);
        newSet.delete(cardUuid);
        const newArray = Array.from(newSet);
        logResponse({
          componentId: uuid,
          value: newArray.length > 0 ? newArray : null
        });
        return newSet;
      });
    } else if (selectType === 'multiple') {
      setSelectValue((prevSet) => {
        const newSet = new Set(prevSet);
        newSet.add(cardUuid);
        const newArray = Array.from(newSet);
        logResponse({
          componentId: uuid,
          value: newArray.length > 0 ? newArray : null
        });
        return newSet;
      });
    } else {
      // if single select only allow one response:
      setSelectValue(new Set([cardUuid]));
      logResponse({
        componentId: uuid,
        value: [cardUuid]
      });

      // Trigger complete call if confirmationRequired === false
      !confirmationRequired && finishStep();
    }
  };

  return (
    <div className={classes.root}>
      {expandableCardOptions.map((card, index) => {
        return (
          <ExpandableCard
            {...card}
            selectType={selectType}
            tabIndex={index}
            key={card.uuid}
            handleSelectClick={
              selectType !== 'none' ? () => handleClick(card.uuid) : () => {}
            }
            checked={selectValue.has(card.uuid)}
          />
        );
      })}
    </div>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {}
    }),
  { name: 'Mui-ExpandedCardList' }
);

export default ExpandedCardList;
