// Manually import and set Shadow type because TypeScript...
import { Shadows } from '@material-ui/core/styles/shadows';

export const shadows: Shadows = [
  'none',
  'none',
  'none',
  'none',
  '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)', // elevation 5
  '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
  'none',
  'none',
  'none',
  'none', // elevation 10
  '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
  'none',
  'none',
  'none',
  'none', // elevation 15
  'none',
  'none',
  'none',
  'none',
  'none', // elevation 20
  'none',
  'none',
  'none',
  '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
];

// For reference with QA, to be deleted.
export const shadowsLegacy: Shadows = [
  'none',
  'none',
  'none',
  'none',
  '0px 2px 4px -1px rgba(224, 224, 224, 0.2),0px 4px 5px rgba(224, 224, 224, 0.14),0px 1px 10px rgba(224, 224, 224, 0.12)',
  '0px 3px 5px -1px rgba(224,224,224,0.2),0px 5px 8px 0px rgba(224,224,224,0.14),0px 1px 14px 0px rgba(224,224,224,0.12)',
  '0px 3px 5px -1px rgba(224,224,224,0.2),0px 6px 10px 0px rgba(224,224,224,0.14),0px 1px 18px 0px rgba(224,224,224,0.12)',
  'none',
  'none',
  'none',
  'none',
  '0px 6px 7px -4px rgba(224,224,224,0.2),0px 11px 15px 1px rgba(224,224,224,0.14),0px 4px 20px 3px rgba(224,224,224,0.12)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  '0px 11px 15px -7px rgba(224,224,224,0.2),0px 24px 38px 3px rgba(224,224,224,0.14),0px 9px 46px 8px rgba(224,224,224,0.12)'
];
