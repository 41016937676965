import { PickerFileMetadata } from 'filestack-js';
import { ComplexValueType, UserResponse } from '../../../components/Core/CoreTypes';

// {
//   "traceId": "HIss6a9f8oPamzsIxBTq7",
//   "requestId": "TEt78JOL23dF8K3hndlCG",
//
//   "responseData": {},
//
//   "serviceData": {
//     "processName": "tda",
//     "processPayload": {
//       "category": "creditUnion",
//       "accounts": [{
//           "accountId": "1",
//           "employedByCreditUnion": true
//         },
//         {
//           "accountId": "2",
//           "employedByCreditUnion": false
//         }
//       ]
//     }
//   }
// }

enum TranslatorTypes {
  AccountList = 'AccountList',
  AccountListDisplayOnly = 'AccountListDisplayOnly',
  SingleAccount = 'SingleAccount',
  SingleAccountOneToMany = 'SingleAccountOneToMany',
  SingleAccountDocumentUpload = 'SingleAccountDocumentUpload'
}

type DocumentUploadTDASchema = {
  documentUploadFileName: string,
  documentUploadFileUrl: string,
  documentUploadFileHandle: string
};

type PossibleTDAValues = string | number | boolean | DocumentUploadTDASchema | Record<string, ComplexValueType>;

type TDASignals = Record<string, PossibleTDAValues>;

interface AccountsType extends TDASignals {
  accountId: string
}

const hasValidTDAResponse = ( componentId: string, value: UserResponse['value'] | DocumentUploadTDASchema, translatorKeysOrController: string[] | string, componentType: string, canComplete: boolean ): boolean => {
  let firstArgument: boolean;
  if( typeof translatorKeysOrController === 'string' ) {
    firstArgument = componentId === translatorKeysOrController;
  } else {
    firstArgument = translatorKeysOrController.indexOf(componentId) > -1;
  }

  // For button types (FabNav) falsy values are allowed 
  if(componentType === 'button') {
    return firstArgument;
  }
  return (firstArgument && !!value && !!canComplete);
};

const addTDASignalToAccount = (account: AccountsType, TDASignal: string, value: PossibleTDAValues ) => {
  return (
    {
      ...account,
      [TDASignal]: value
    }
  );
};
 
const transformFilestackValue = (filestackMetaData: PickerFileMetadata[]): DocumentUploadTDASchema => {
  return(
    {
      documentUploadFileName: filestackMetaData[0].filename,
      documentUploadFileUrl: filestackMetaData[0].url,
      documentUploadFileHandle: filestackMetaData[0].handle
    }
  );
};

const accountListArrayBuilder = (responses: UserResponse[], translatorKeys: string[], canComplete: boolean ) => {
  let accounts: AccountsType[] = [];
  let cardListTranslatorKey = '';

  // Process CardList multi-select first 
  // responsesToProcess becomes an array without the selectCardList_TEMP component
  const responsesToProcess = responses.filter((response) => {
    if (response.componentType === 'selectCardList_TEMP') {

      if(translatorKeys.indexOf(response.componentId) === -1) {
        console.log('WARNING: CardList uuid is not inside translatorKeys');
      }
      
      cardListTranslatorKey = response.componentId;

      accounts = Object.keys(response.value as Record<string, ComplexValueType>).map((accountId: string) => {
        return {
          accountId,
          [cardListTranslatorKey]: (response.value as Record<string, ComplexValueType>)[accountId].value
        };
      });

      return false;
    } 
    return true;
    
  });

  // For AccountListPlus
  if(translatorKeys.length > 1) {

    responsesToProcess.forEach((response: UserResponse) => {

      const value = (Array.isArray(response.value) && typeof response.value[0] === 'string') ? response.value[0] : response.value; 

      if (hasValidTDAResponse(response.componentId, value, translatorKeys, response.componentType || 'button', canComplete) && value !== undefined && value !== null) { 
        accounts.forEach((account, index) => {
          if(account[cardListTranslatorKey] === true) {
            accounts[index] = addTDASignalToAccount(account, response.componentId, value as PossibleTDAValues);
          }
        });          
      }

      // TODO: multiSelect might require a different type of translation
      // implementation details depend on whether we want to send falsy values as well
    });

  }

  return accounts;
};

const accountListDisplayOnlyArrayBuilder = (
  responses: UserResponse[], 
  translatorKeys: string[], 
  canComplete: boolean, 
  accountIdArray: string[],
  uploadCompletedSignal?: string,
  documentType?: string
) => {
  const accounts: AccountsType[] = accountIdArray.map((id) => ({ accountId: id, ...( uploadCompletedSignal ? { [uploadCompletedSignal]: false } : {}) }));

  responses.forEach((response: UserResponse) => {

    let value: UserResponse['value'] | DocumentUploadTDASchema;

    if(response.componentType === 'filestackUploadList' && response.value) {
      value = transformFilestackValue(response.value as PickerFileMetadata[]);
    } else {
      value = (Array.isArray(response.value) && typeof response.value[0] === 'string') ? response.value[0] : response.value; 
    }
 

    if (hasValidTDAResponse(response.componentId, value, translatorKeys, response.componentType || 'button', canComplete) && value !== undefined && value !== null) { 
      let index = 0;
      accounts.forEach(() => {
        accounts[index] = addTDASignalToAccount(accounts[index], response.componentId, value as PossibleTDAValues);
        if(response.componentType === 'filestackUploadList' && uploadCompletedSignal) {
          accounts[index] = addTDASignalToAccount(accounts[index], uploadCompletedSignal, true);
        }
        if(response.componentType === 'filestackUploadList' && documentType) {
          accounts[index] = addTDASignalToAccount(accounts[index], 'documentType', documentType);
        }
        index += 1;
      });
    }

    // TODO: multiSelect might require a different type of translation
    // implementation details depend on whether we want to send falsy values as well
  });

  return accounts;
};

const singleAccountOneToManyArrayBuilder = (responses: UserResponse[], translatorKeys: string[], canComplete: boolean, accountIdArray: string[], controller: string ) => {
  const accounts: AccountsType[] = accountIdArray.map((id) => ({ accountId: id }));


  responses.forEach((response: UserResponse) => {
    
    const value = (Array.isArray(response.value) && typeof response.value[0] === 'string') ? response.value[0] : response.value; 

    if (hasValidTDAResponse(response.componentId, value, controller, response.componentType || 'button', canComplete) && value !== undefined && value !== null) { 
      let index = 0;
      accounts.forEach(() => {
        translatorKeys.forEach((key) => {
          accounts[index] = addTDASignalToAccount(accounts[index], key, value as PossibleTDAValues);
        });
        index += 1;
      });
    }

    // TODO: multiSelect might require a different type of translation
    // implementation details depend on whether we want to send falsy values as well
  });

  return accounts;
};

/**
 * 9:31
 * `category` is the type of account -
 * `creditUnion`, `medical`, `shortTermFixedDebt` etc.
 * ☝️ long term we likely want args, but we'll roll static for nwo
 */
const translateForTradelineAnalyzer = (
  translatorType: TranslatorTypes,
  category: string,
  translatorKeys: string[],
  responses: UserResponse[],
  canComplete: boolean = true,
  accountId: string | string[] = [],
  controller?: string,
  uploadCompletedSignal?: string,
  documentType?: string
) => {
  let accounts: AccountsType[] = [];
  const accountIdArray = (typeof accountId === 'string') ? ([accountId]) : (accountId);

  switch(translatorType) {
    case TranslatorTypes.AccountList: {
      accounts = accountListArrayBuilder(responses, translatorKeys, canComplete);
      break;
    }
    case TranslatorTypes.AccountListDisplayOnly: {
      accounts = accountListDisplayOnlyArrayBuilder(responses, translatorKeys, canComplete, accountIdArray);
      break;
    } 
    case TranslatorTypes.SingleAccount: {
      accounts = accountListDisplayOnlyArrayBuilder(responses, translatorKeys, canComplete, accountIdArray);
      break;
    }
    case TranslatorTypes.SingleAccountOneToMany: {
      if(controller) {
        accounts = singleAccountOneToManyArrayBuilder(responses, translatorKeys, canComplete, accountIdArray, controller);
      }
      break;
    }
    case TranslatorTypes.SingleAccountDocumentUpload: {
      if(uploadCompletedSignal && documentType) {
        accounts = accountListDisplayOnlyArrayBuilder(responses, translatorKeys, canComplete, accountIdArray, uploadCompletedSignal, documentType);
      }
      break;
    }
    default:
      throw Error('Unrecognized translator type');
  }
  

  return {
    serviceName: 'tradelineAnalyzer',
    servicePayload: {
      category,
      accounts
    }
  };
};

 type AccountListTranslatorData = {
   category: string,
   translatorKeys: string[],
 };

interface AccountListDisplayOnly extends AccountListTranslatorData {
  accountId: string[];
}

interface SingleAccountTranslatorData extends AccountListTranslatorData {
  accountId: string;
}

interface SingleAccountOneToManyTranslatorData extends SingleAccountTranslatorData {
  controller: string;
}

interface SingleAccountDocumentUploadTranslatorData extends SingleAccountTranslatorData {
  documentType: string;
  uploadCompletedSignal: string;
}

export const tradelineAnalyzerAccountList = (res: UserResponse[], translatorData: AccountListTranslatorData) =>
  translateForTradelineAnalyzer(TranslatorTypes.AccountList, translatorData.category, translatorData.translatorKeys, res);

export const tradelineAnalyzerAccountListPlus = (res: UserResponse[], translatorData: AccountListTranslatorData, canComplete: boolean) =>
  translateForTradelineAnalyzer(TranslatorTypes.AccountList, translatorData.category, translatorData.translatorKeys, res, canComplete);

export const tradelineAnalyzerAccountListDisplayOnly = (res: UserResponse[], translatorData: AccountListDisplayOnly, canComplete: boolean) =>
  translateForTradelineAnalyzer(TranslatorTypes.AccountListDisplayOnly, translatorData.category, translatorData.translatorKeys, res, canComplete, translatorData.accountId);

export const tradelineAnalyzerSingleAccount = (res: UserResponse[], translatorData: SingleAccountTranslatorData, canComplete: boolean) =>
  translateForTradelineAnalyzer(TranslatorTypes.SingleAccount, translatorData.category, translatorData.translatorKeys, res, canComplete, translatorData.accountId);

export const tradelineAnalyzerSingleAccountOneToMany = (res: UserResponse[], translatorData: SingleAccountOneToManyTranslatorData, canComplete: boolean) =>
  translateForTradelineAnalyzer(TranslatorTypes.SingleAccountOneToMany, translatorData.category, translatorData.translatorKeys, res, canComplete, translatorData.accountId, translatorData.controller);

export const tradelineAnalyzerSingleAccountDocumentUpload = (res: UserResponse[], translatorData: SingleAccountDocumentUploadTranslatorData, canComplete: boolean) =>
  translateForTradelineAnalyzer(TranslatorTypes.SingleAccountDocumentUpload, translatorData.category, translatorData.translatorKeys, res, canComplete, translatorData.accountId, undefined, translatorData.uploadCompletedSignal, translatorData.documentType);



