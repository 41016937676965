import React from 'react';
import { ComponentBase } from '../../models/models';
import { useRadioDispatch } from '../Core/radioContext';

export interface Props extends ComponentBase {
  /** Unique ID from JSON. */
  uuid: string;
  /** How to translate this component value, defaults to 'input'. */
  componentType?: string;
  /** Response component value, defaults to true. */
  value?: string | number | boolean;
}

type TypeSubstitutions = {
  [index: string]: string | number | boolean;
};

const SUBSTITUTES: TypeSubstitutions = {
  __user_timezone__: Intl.DateTimeFormat().resolvedOptions().timeZone
};

const AutoLogResponse: React.FC<Props> = ({
  uuid,
  componentType = 'input',
  value = true
}) => {
  const { registerResponseComponent } = useRadioDispatch();

  React.useEffect(() => {
    registerResponseComponent({
      componentId: uuid,
      componentType,
      value: SUBSTITUTES[value as string] ? SUBSTITUTES[value as string] : value
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default AutoLogResponse;
