/**
 * This is the legacy theme file
 * We're retaining this for now at the conclusion of YCF-169
 * for both reference, and because a handful of components
 * are wired into the assets export. The assets will be patched
 * in another Jira task.
 */

import { createMuiTheme } from '@material-ui/core';
// YC-Visual Assets
import MainLogo from './assets/images/yc_logo.svg';
import XIconMain from './assets/images/close.svg';
import ArrowForwardIconMain from './assets/images/arrow_forward.svg';
import ArrowBackIconMain from './assets/images/arrow_back.svg';
import XIconModal from './assets/images/modal_close.svg';

interface Assets {
  topNavigation: {
    logo: {
      main: string,
      light?: string,
      dark?: string
    },
    xOutButton: {
      main: string,
      light?: string,
      dark?: string
    }
  },  
  footer: {
    forwardArrow: {
      main?: string,
      light?: string,
      dark?: string
    },
    backArrow: {
      main?: string,
      light?: string,
      dark?: string
    }
  },
  leftPanel: {
    activeIcon?: string,
    completedIcon?: string,
    defaultIcon?: string
  },
  body: {
    dropdownIcon?: string,
  },
  modal: {
    closeIcon: {
      main: string, 
      light?: string,
      dark?: string
    }
  }
}

export const assets:Assets = {
  topNavigation: {
    logo: {
      main: MainLogo
    },
    xOutButton: {
      main: XIconMain
    }
  },  
  footer: {
    forwardArrow: {
      main: ArrowForwardIconMain
    },
    backArrow: {
      main: ArrowBackIconMain
    }
  },
  leftPanel: {
    
  },
  body: {
  },
  modal: {
    closeIcon: {
      main: XIconModal
    }
  }
};


declare module '@material-ui/core/styles/createPalette' {
  interface TextColor extends PaletteColor {
    secondary: string;
    disabled: string;
    hint: string;
    highlight: string;
    light400: string; // TODO: find a better place for this eventually
  }
  
  interface BackgroundColor extends PaletteColor {
    neutral: string;
    fivePercentDarker: string;
  }
  interface DividerColor extends PaletteColor {
    highlight: string;
  }

  interface PaletteOptions {    
    Text?: Partial<TextColor>;
    Divider?: Partial<DividerColor>;
    Background?: Partial<BackgroundColor>;
  }

  interface Palette {
    Text: TextColor;
    Divider: DividerColor;
    Background: BackgroundColor;
  }
}

export default createMuiTheme({
  palette: {
    primary: {
      main: '#095D4D',
      dark: '#064135',
      light: '#3a7d70'
    },
    secondary: {
      main: '#ED5454',
      dark: '#A53A3A',
      light: '#F07676'
    },
    grey: {
      '50': '#FAFAFA',
      '300': '#e0e0e0',
      '500': '#9e9e9e',
      '800': '#424242',
    },
    Text: {
      secondary: '#ffffff',
      dark: '#064135',
      main: '#095D4D',
      light: '#3a7d70',
      disabled: 'rgba(58, 125, 112, 0.48)',
      hint: '#9e9e9e',
      highlight: '#FF9966',
      light400: '#1D6A5B'
    },
    Divider: {
      main: '#9e9e9e',
      dark: '#424242',
      light: '#e0e0e0',
      highlight: '#095D4D'
    },
    Background:{
      dark: '#064135',
      main: '#FFFFFF',
      light: '#FFE8AA',
      neutral: '#F6EEE1',
      fivePercentDarker: 'rgba(0, 0, 0, 0.05)'
    },
  },
  typography: { 
    fontFamily: ['Graphik'].join(','),
    h3: {
      fontFamily: ['Graphik'].join(','),
      fontWeight: 600,
      fontSize: '1.75em',
      lineHeight: '1.35em',
    },
    h6: {
      fontFamily: ['Graphik'].join(','),
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.125em',
    },
    body1: {
      fontFamily: ['Graphik'].join(','),
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.25rem',
    },
    body2: {
      fontFamily: ['Graphik'].join(','),
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
    button: {
      fontFamily: ['Graphik'].join(','),
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
    },
    caption: {
      fontFamily: ['Graphik'].join(','),
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1.375rem',
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(6, 63, 54,0.8)'
      }
    }
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    '0px 2px 4px -1px rgba(224, 224, 224, 0.2),0px 4px 5px rgba(224, 224, 224, 0.14),0px 1px 10px rgba(224, 224, 224, 0.12)',
    '0px 3px 5px -1px rgba(224,224,224,0.2),0px 5px 8px 0px rgba(224,224,224,0.14),0px 1px 14px 0px rgba(224,224,224,0.12)',
    '0px 3px 5px -1px rgba(224,224,224,0.2),0px 6px 10px 0px rgba(224,224,224,0.14),0px 1px 18px 0px rgba(224,224,224,0.12)',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(224,224,224,0.2),0px 11px 15px 1px rgba(224,224,224,0.14),0px 4px 20px 3px rgba(224,224,224,0.12)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 11px 15px -7px rgba(224,224,224,0.2),0px 24px 38px 3px rgba(224,224,224,0.14),0px 9px 46px 8px rgba(224,224,224,0.12)'
  ]
});
