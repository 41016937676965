import React from 'react';
import Fab from '@material-ui/core/Fab';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * If true increases the width of the Fab in order to fit its content
   */
  extended?: boolean;
  /**
   * SVG element use as an icon
   */
  icon?: string;
  /**
   * Text inside Fab
   */
  label?: string;
  /**
   * Function triggered when button is clicked
   */
  handleClick: () => void;
  /**
   * Display variants.
   */
  variant?: 'default' | 'ghost' | 'inverse';
  /**
   * Function type, ie: forward, back, other.
   */
  type: 'forward' | 'back' | string;
  /**
   * If true button will be disabled.
   */
  disabled?: boolean;
  /**
   * If true button will be span the available space.
   */
  fullWidth?: boolean;
  /**
   * If true button will be hidden but occupying space.
   */
  hidden?: boolean;
  /**
   * If true ripple will be disabled
   */
  disableRipple?: boolean;
}

const FabBase: React.FC<Props> = ({
  extended = false,
  icon,
  label,
  handleClick,
  variant = 'default',
  type,
  disabled = false,
  fullWidth,
  hidden,
  disableRipple = true
}) => {
  const classes = useStyles();

  return (
    <Fab
      variant={extended ? 'extended' : 'round'}
      classes={{
        root: classes.root,
        disabled: classes.fabDisabled,
        label: classes.fabLabel,
        extended: classes.fabExtended,
        focusVisible: classes.fabFocusVisible
      }}
      className={clsx(classes.root, {
        [classes.fabDisabled]: disabled,
        [classes.fabFullWidth]: fullWidth,
        [classes.default]: variant === 'default',
        [classes.ghost]: variant === 'ghost',
        [classes.inverse]: variant === 'inverse',
        [classes.fabHidden]: hidden
      })}
      disabled={disabled}
      onClick={handleClick}
      aria-label={label || type}
      disableRipple={disableRipple}
    >
      {icon && parse(icon)}
      {label && label}
    </Fab>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        pointerEvents: 'all',
        // YCF-788 Color transition hack to resolve Safari button label color,
        // appended to typical MUI transition since it's non-standard.
        // This may be patched on iOS 14.7+ https://bit.ly/3sMT2Bw
        transition: `${theme.transitions.create(
          ['background-color', 'box-shadow', 'border'],
          {
            duration: theme.transitions.duration.short
          }
        )}, color .01s`
      },
      fabLabel: {
        fontFamily: theme.typography.fontFamily,
        transition: '.1s background',
        textTransform: 'none'
      },
      fabDisabled: {},
      fabExtended: {},
      fabFullWidth: {
        flexGrow: 1
      },
      fabHidden: {
        visibility: 'hidden'
      },
      default: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          background: theme.palette.primary.dark,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            background: theme.palette.primary.main
          }
        }
      },
      inverse: {
        background: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        '& path': {
          fill: theme.palette.primary.main
        }
      },
      ghost: {
        background: 'transparent',
        color: theme.palette.grey[500],
        boxShadow: theme.shadows[0],
        '& path': {
          fill: theme.palette.primary.main
        }
      },
      fabFocusVisible: {
        border: `2px solid ${theme.palette.primary.light}`
      }
    }),
  { name: 'Mui-FabBase' }
);

export default FabBase;
