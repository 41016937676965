import React from 'react';
import { ComponentBase } from '../../models/models';
import DotPagination from '../common/DotPagination';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import FooterContent from '../Footer/FooterContent';
import FabNavigation from '../Footer/FabNavigation';
import GridItem12 from '../GridItems/GridItem12';
import MakeRenderer from '../../renderer/RenderNodeTree';
import * as ComponentMapWithoutCarousel from '../ComponentLibrary';
import { useRadioDispatch, useRadioState } from '../Core/radioContext';

// Pagination components map
const PaginationMap: Record<string, React.FC<any>> = {
  DotPagination
};

interface FabProps {
  extended?: boolean;
  icon?: string;
  label?: string;
  variant?: 'default' | 'ghost' | 'inverse';
  fullWidth?: boolean;
  uuid: string;
}

export interface Props extends ComponentBase {
  /**
   * Unique Id sent upon completion.
   */
  uuid: string;
  /**
   * The components that will be rendered on each screen.
   */
  screens: Record<string, any>[];
  /**
   * The pagination component that will be rendered in the footer.
   */
  paginationComponent?: string;
  /**
   * The variant that will replace the default overrides.
   */
  hasLeftPanel?: boolean;
  /**
   * The components that will be rendered along the two fabs and optional pagination.
   */
  extraFooterContent?: Record<string, any>;
  /**
   * The properties that will be passed to the back button.
   */
  backFabProps: FabProps;
  /**
   * The properties that will be passed to the forward button.
   */
  forwardFabProps: FabProps;
}

const Renderer = MakeRenderer(ComponentMapWithoutCarousel);

const Carousel: React.FC<Props> = ({
  uuid,
  screens,
  paginationComponent,
  hasLeftPanel = true,
  extraFooterContent,
  backFabProps,
  forwardFabProps
}) => {
  const { registerResponseComponent } = useRadioDispatch();
  const radioState = useRadioState();
  const initValue = (radioState.apiData.responses?.[uuid] as boolean) || false;
  const [currentPage, setCurrentPage] = React.useState<number>(
    initValue ? screens.length - 1 : 0
  );

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'button', // The carousel works like a navigation button setting value=== true when carousel is finished
      componentId: uuid,
      value: true,
      isRequired: true
    });
  }, [registerResponseComponent, uuid]);

  const nextSection = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousSection = () => {
    setCurrentPage(currentPage - 1);
  };

  const FooterWrapper: React.FC = ({ children }) =>
    hasLeftPanel ? (
      <Footer1>{children}</Footer1>
    ) : (
      <Footer2>{children}</Footer2>
    );

  return (
    <>
      {Renderer(screens[currentPage])}
      <FooterWrapper>
        <FooterContent>
          {extraFooterContent && Renderer(extraFooterContent)}
          <GridItem12
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <FabNavigation
              {...backFabProps}
              type={currentPage === 0 ? 'back' : 'carousel-back'}
              onClick={previousSection}
            />
            {paginationComponent &&
              React.createElement(PaginationMap[paginationComponent], {
                count: screens.length,
                page: currentPage + 1 // currentPage starts at 0
              })}
            <FabNavigation
              {...forwardFabProps}
              type={
                currentPage === screens.length - 1
                  ? 'forward'
                  : 'carousel-forward'
              }
              onClick={nextSection}
            />
          </GridItem12>
        </FooterContent>
      </FooterWrapper>
    </>
  );
};

export default Carousel;
