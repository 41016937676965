import React, { Reducer } from 'react';

export type Action =
  | { type: 'SHOW_API_ERROR_TOAST'; payload?: Payload }
  | { type: 'HIDE_API_ERROR_TOAST' }
  | { type: 'HIDE_API_ERROR_TOAST_AND_RESET' }; 

 
export type State = {
  apiErrorCount: number;
  apiErrorDeadlock: boolean;
  showApiErrorToast: boolean;
};

type Payload = {
  deadlock: boolean
};

export const initState = {
  apiErrorCount: 0,
  apiErrorDeadlock: false,
  showApiErrorToast: false
};


export const NoteContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initState,
  dispatch: () => null
});

const noteReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SHOW_API_ERROR_TOAST': {
      const apiErrorDeadlock =
        action.payload && action.payload.deadlock
          ? true
          : state.apiErrorCount > 0;
      return {
        ...state,
        apiErrorCount: state.apiErrorCount + 1,
        showApiErrorToast: true,
        apiErrorDeadlock
      };
    }

    case 'HIDE_API_ERROR_TOAST_AND_RESET': {
      return {
        ...state,
        apiErrorCount: 0,
        showApiErrorToast: false,
        apiErrorDeadlock: false
      };
    }

    case 'HIDE_API_ERROR_TOAST':
      return { ...state, showApiErrorToast: false };

    default:
      throw new Error('Action type not recognized.');
  }
};

export const NoteProvider: React.FC = ({children}) => {
  const [state, dispatch] = React.useReducer<Reducer<State, Action>>(noteReducer, initState);
  return (
    <NoteContext.Provider value={{state, dispatch}}>
      {children}
    </NoteContext.Provider>
  );
};

export const useNoteContext = () => {
  const context = React.useContext(NoteContext);
  if (context === undefined) {
    throw new Error('useNoteContext must be used within NoteProvider.');
  }
  return context;
};