import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { ComponentWithChildren } from '../../models/models';


const BodyContent: React.FC<ComponentWithChildren> = ({children}) => {
  const classes = useStyles();
  const [slideIntoView, setslideIntoView] = React.useState<boolean>(false);

  React.useEffect(() => {
    const enterDelay = () => {
      setslideIntoView((prevState) => !prevState);
    };
    enterDelay();
  }, []);

  return (
    <Slide direction="left" in={slideIntoView} mountOnEnter unmountOnExit>
      <div className={classes.root}>{children}</div>
    </Slide>
  );
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    maxWidth: '800px',
    width: '100%'
  }
}, { name: 'Mui-BodyContent' });

export default BodyContent;