import React from 'react';
import {
  StylesProvider,
  createGenerateClassName
} from '@material-ui/core/styles';
import { HelmetProvider } from 'react-helmet-async';

import { ExperienceThemeProvider } from '../../styles/experienceThemeContext';
import { NavigationProvider } from './navigationContext';
import { RadioProvider } from './radioContext';
import { NoteProvider } from '../Notifications/noteContext';
import RadioTuner from './RadioTuner';

const generateClassName = createGenerateClassName({
  productionPrefix: 'radioSdk-jss',
  disableGlobal: true
});

const RadioSDK: React.FC = () => {
  // React.useEffect(() => {
  //   const onCloseTab = (e:BeforeUnloadEvent) => {
  //     e.preventDefault();
  //     e.returnValue = '';
  //   };
  //   window.addEventListener('beforeunload', onCloseTab);
  //   return () => {
  //     window.removeEventListener('beforeunload', onCloseTab);
  //   };
  // }, []);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ExperienceThemeProvider>
        <HelmetProvider>
          <NoteProvider>
            <RadioProvider>
              <NavigationProvider>
                <RadioTuner />
              </NavigationProvider>
            </RadioProvider>
          </NoteProvider>
        </HelmetProvider>
      </ExperienceThemeProvider>
    </StylesProvider>
  );
};

export default RadioSDK;
