import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import SimpleModal from '../SimpleModal';
import SceneIntro from './SceneIntro';
import SceneChallenge from './SceneChallenge';
import SceneSuccess from './SceneSuccess';
import AlertMessage from '../../Notifications/AlertMessage';
import { twilioFetch, TwilioEndpoints } from '../../../services/twilio';

const ERROR_MESSAGES = {
  twilioDown:
    'There was a communication error, please close this dialog and again.',
  badCode: 'One-time code is incorrect, please try again.'
};

export enum VerifyChannels {
  sms = 'sms',
  call = 'call',
  email = 'email'
}

export type Props = {
  /** Email address or 10-digit US Phone number to send auth code to. (Phone converted to E.164 format on Twilio call) */
  to: string;
  /** How the user can choose to verify. */
  availableChannels: VerifyChannels[];
  /** Call back to show/hide modal. */
  onClose: () => void;
  /** Callback to fire when verify is successful */
  onVerify: () => void;
};

const TwoFactorAuthModal = ({
  to,
  availableChannels,
  onClose,
  onVerify
}: Props) => {
  const isDevEnv = process.env.REACT_APP_SDK_ENV === 'development';

  // @ts-ignore Mui bug with spreading theme variables.
  const classes = useStyles();
  const [error, setError] = useState<string>('');
  const [scene, setScene] = useState<'intro' | 'challenge' | 'success'>(
    'intro'
  );
  const [verifyChannel, setVerifyChannel] = useState<VerifyChannels | ''>('');

  /**
   * phoneToE164
   * Converts a user-friendly US phone number to an E.164 formatted.
   */
  const phoneToE164 = (phone: string) => {
    return `+1${phone.replace(/\D/g, '')}`;
  };

  /**
   * handleClose()
   * Resets scene and fires callback to parent.
   */
  const handleClose = () => {
    onClose();
  };

  /**
   * startVerify()
   * Initiate a 2fa verification with Twilio.
   */
  const startVerify = async (channel: VerifyChannels) => {
    setError('');
    const fetchData = {
      Channel: channel,
      To: channel === VerifyChannels.email ? to : phoneToE164(to)
    };

    twilioFetch(TwilioEndpoints.verify, fetchData)
      .then(() => {
        setVerifyChannel(channel);
        setScene('challenge');
      })
      .catch(() => {
        // Hard failure, comm error.
        setError(ERROR_MESSAGES['twilioDown']);
      });
  };

  /**
   * resendVerify()
   * Attempt to resend the verification.
   */
  const resendVerify = () => {
    startVerify(verifyChannel as VerifyChannels);
  };

  /**
   * challengeVerify()
   * Send verification code to Twilio to… verify.
   */
  const challengeVerify = async (authCode: string) => {
    setError('');
    const fetchData = {
      Code: authCode,
      To: verifyChannel === VerifyChannels.email ? to : phoneToE164(to)
    };

    twilioFetch(TwilioEndpoints.check, fetchData)
      .then((data) => {
        if (data.status === 'approved') {
          onVerify();
          setScene('success');
        } else {
          // Soft failure, 200 http res but invalid code.
          setError(ERROR_MESSAGES['badCode']);
        }
      })
      .catch(() => {
        // Hard failure, comm error.
        setError(ERROR_MESSAGES['twilioDown']);
      });
  };

  const devStartVerify = () => {
    setScene('challenge');
  };

  const devChallengeVerify = (authCode: string) => {
    if (authCode === '999999') {
      onVerify();
      setScene('success');
    } else {
      setError(ERROR_MESSAGES['badCode']);
    }
  };

  return (
    <SimpleModal open handleClose={handleClose}>
      {isDevEnv && (
        <AlertMessage
          severity="warning"
          variant="standard"
          isOpen
          body="SDK_ENV is development, Twilio calls won’t occur. Your OTP is 999999."
        />
      )}

      {scene === 'intro' && (
        <SceneIntro
          to={to}
          availableChannels={availableChannels}
          onChannelSelect={isDevEnv ? devStartVerify : startVerify}
          error={error}
          classes={{
            root: classes.scene,
            scene: classes.sceneIntro,
            button: classes.button,
            footer: classes.footerIntro,
            textDirective: classes.textDirective,
            textError: classes.textError
          }}
        />
      )}
      {scene === 'challenge' && (
        <SceneChallenge
          onChallenge={isDevEnv ? devChallengeVerify : challengeVerify}
          onResend={isDevEnv ? () => {} : resendVerify}
          error={error}
          classes={{
            root: classes.scene,
            scene: classes.sceneChallenge,
            button: classes.button,
            footer: classes.footerChallenge,
            textDirective: classes.textDirective,
            textError: classes.textError
          }}
        />
      )}
      {scene === 'success' && (
        <SceneSuccess
          onContinue={handleClose}
          classes={{
            root: classes.scene,
            scene: classes.sceneSuccess,
            button: classes.button,
            footer: classes.footerSuccess,
            textDirective: classes.textDirective,
            image: classes.imageSuccess
          }}
        />
      )}
    </SimpleModal>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      scene: {
        maxWidth: '100%',
        paddingTop: theme.spacing(2),
        textAlign: 'center'
      },
      sceneIntro: {},
      sceneChallenge: {},
      sceneSuccess: {},

      textDirective: {
        ...theme.typography.h6,
        margin: '0 auto',
        maxWidth: '350px'
      },
      textError: {
        ...theme.typography.caption,
        color: theme.palette.error.main,
        margin: theme.spacing(2, 'auto')
      },

      footerIntro: {
        display: 'inline-grid',
        gridAutoRows: '1fr',
        gridGap: theme.spacing(2),
        gridTemplateColumns: '1fr',
        marginTop: theme.spacing(2)
      },
      footerChallenge: {
        display: 'inline-grid',
        gridGap: theme.spacing(2),
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr'
      },
      footerSuccess: {
        marginTop: theme.spacing(2)
      },

      button: {},
      imageSuccess: {
        marginBottom: theme.spacing(2),
        width: '100px'
      }
    }),
  {
    name: 'Mui-TwoFactorAuthModal'
  }
);

export default TwoFactorAuthModal;
