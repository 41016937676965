declare global {
  interface Window {
    analytics: any;
    isAnalyticsReady: boolean
  }
}

interface ConfigObj {
  [key: string]: string|number|boolean
}

interface TrackingArg {
  [index: number]: (string | ConfigObj | (() => void))
}


let isAnalyticsReady: boolean|null = null;

/**
 * Reset local flag for tests.
 */
const resetIsAnalyticsReady = () => {
  isAnalyticsReady = null;
};


/**
 *  Wait for analytics.js ready.
 * 
 * @returns a promise resolved true when the analytics.js is ready.
 */
const waitForAnalyticsReady = async () => {
  return new Promise<boolean>((resolve, reject) => {
    let i = 0;
    const waitForReadyCallback = function no() {
      if (i === 100) {
        reject(new Error('timeout, analytics.js is probably ad blocked.'));
      }
      if (window.isAnalyticsReady) {
        resolve(true);
      } else {
        i += 1;
        window.setTimeout(waitForReadyCallback, 25);
      }
    };

    waitForReadyCallback();
  });
};


/**
 * See if analytics.js is loaded and ready for use.
 * 
 * @returns bool of isAnalyticsReady
 */
const isReady = async () => {
  if (isAnalyticsReady === null) {
    try {
      isAnalyticsReady = await waitForAnalyticsReady();
    } catch(err) {
      isAnalyticsReady = false;
    }
  }
  return isAnalyticsReady;
};


/**
 * Send a page view tracking event. https://bit.ly/2ITTT0c
 * 
 * @param category  The category of the page. If name isn't passed, this is assumed as name.
 * @param name  The name of the page.
 * @param properties  A dictionary of properties of the page. Note: url, title, referrer and path are collected automatically.
 * @param options  A dictionary of options.
 * @param callback  A function executed after a short timeout.
 */
const page = async (...args: TrackingArg[]) => {
  const ready = await isReady();
  if (!ready) return;
  
  window.analytics.page(...args);
};


/**
 * Send a generic tracking event to record actions users perform.
 * 
 * @param event The name of the event you’re tracking. Required.
 * @param properties A dictionary of properties for the event.
 * @param options A dictionary of options.
 * @param callback A function that is executed after a short timeout.
 */
const track = async (...args: TrackingArg[]) => {
  const ready = await isReady();
  if (!ready) return;

  window.analytics.track(...args);
};


/**
 * Link a user and actions to Segment recognized userId and traits.
 * 
 * @param userId The database ID for the user.
 * @param traits A dictionary of known traits, like email or name.
 * @param options A dictionary of options.
 * @param callback A function that is executed after a short timeout.
 */
const identify = async (...args: TrackingArg[]) => {
  const ready = await isReady();
  if (!ready) return;

  window.analytics.identify(...args);
};


/**
 * Segment.com analytics.js jump start snippet. Inject this into the DOM 
 * head using react-helmet-async.
 * 
 * @param segmentWriteKey  Source key from Segment.com.
 * @param sendFirstPage   Include the first page view ping on ready.
 */
const getSnippet = (segmentWriteKey: string, sendFirstPage: boolean = true) => {
  // stock analytics.js stub from Segment.
  let strOut = '!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1"; \n';

  // callback to detect if loaded. https://bit.ly/36JGCQ4
  strOut += 'analytics.ready(function() {window.isAnalyticsReady = true});\n';

  // trigger the load with write key
  strOut += `analytics.load("${segmentWriteKey}"); \n`;

  // initial page view call
  if (sendFirstPage) {
    strOut += 'analytics.page(); \n';
  }

  strOut += '}}();';
  return strOut;
};


const segmentHelper = {
  page,
  track,
  identify,
  getSnippet,
  resetIsAnalyticsReady
};

export default segmentHelper;