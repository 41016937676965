// Loading screen JSON. Eventually will come from init call

export const DefaultLoadingScreenJSON = {
  Layout: {
    TopNavigation: {
      TopNavigationGrid: {
        children: [
          {
            GridItem06: {
              LogoTopNav: {
                properties: {
                  uuid: 'logoTopNav'
                }
              }
            }
          },
          {
            GridItem06: {
              properties: {
                justify: 'flex-end'
              },
              children: [
                {
                  XOutButton: {
                    properties: {
                      uuid: 'xOutButton',
                      hideXOutButton: true
                    }
                  }
                }
              ]
            }
          }
        ]
      }
    },
    Body: {
      CircularProgressIndicator: {
        properties: {
          size: 80,
          thickness: 2
        }
      }
    }
  }
};

// YC Lottie

// LottiePlayer: {
//   properties: {
//     source: {
//       v: '5.6.10',
//       fr: 24,
//       ip: 0,
//       op: 36,
//       w: 287,
//       h: 260,
//       nm: 'YC_Logo_Icon3.1',
//       ddd: 0,
//       assets: [],
//       layers: [
//         {
//           ddd: 0,
//           ind: 1,
//           ty: 4,
//           nm: 'Ebene 2 Konturen',
//           sr: 1,
//           ks: {
//             o: { a: 0, k: 100, ix: 11 },
//             r: { a: 0, k: 0, ix: 10 },
//             p: { a: 0, k: [143.4, 93.99, 0], ix: 2 },
//             a: { a: 0, k: [29.28, 29.28, 0], ix: 1 },
//             s: { a: 0, k: [100, 100, 100], ix: 6 }
//           },
//           ao: 0,
//           shapes: [
//             {
//               ty: 'gr',
//               it: [
//                 {
//                   ind: 0,
//                   ty: 'sh',
//                   ix: 1,
//                   ks: {
//                     a: 0,
//                     k: {
//                       i: [
//                         [-16.03, 0],
//                         [0, 16.03],
//                         [16.03, 0],
//                         [0, -16.03]
//                       ],
//                       o: [
//                         [16.03, 0],
//                         [0, -16.03],
//                         [-16.03, 0],
//                         [0, 16.03]
//                       ],
//                       v: [
//                         [0, 29.03],
//                         [29.03, 0],
//                         [0, -29.03],
//                         [-29.03, 0]
//                       ],
//                       c: true
//                     },
//                     ix: 2
//                   },
//                   nm: 'Pfad 1',
//                   mn: 'ADBE Vector Shape - Group',
//                   hd: false
//                 },
//                 {
//                   ty: 'fl',
//                   c: {
//                     a: 0,
//                     k: [
//                       0.035294117647,
//                       0.364705882353,
//                       0.301960784314,
//                       1
//                     ],
//                     ix: 4
//                   },
//                   o: { a: 0, k: 100, ix: 5 },
//                   r: 1,
//                   bm: 0,
//                   nm: 'Fläche 1',
//                   mn: 'ADBE Vector Graphic - Fill',
//                   hd: false
//                 },
//                 {
//                   ty: 'tr',
//                   p: { a: 0, k: [29.28, 29.28], ix: 2 },
//                   a: { a: 0, k: [0, 0], ix: 1 },
//                   s: { a: 0, k: [100, 100], ix: 3 },
//                   r: { a: 0, k: 0, ix: 6 },
//                   o: { a: 0, k: 100, ix: 7 },
//                   sk: { a: 0, k: 0, ix: 4 },
//                   sa: { a: 0, k: 0, ix: 5 },
//                   nm: 'Transformieren'
//                 }
//               ],
//               nm: 'Gruppe 1',
//               np: 2,
//               cix: 2,
//               bm: 0,
//               ix: 1,
//               mn: 'ADBE Vector Group',
//               hd: false
//             }
//           ],
//           ip: 0,
//           op: 36,
//           st: 0,
//           bm: 0
//         },
//         {
//           ddd: 0,
//           ind: 2,
//           ty: 4,
//           nm: 'Ebene 1 Konturen 2',
//           td: 1,
//           sr: 1,
//           ks: {
//             o: { a: 0, k: 100, ix: 11 },
//             r: { a: 0, k: 0, ix: 10 },
//             p: { a: 0, k: [143.4, 177.585, 0], ix: 2 },
//             a: { a: 0, k: [71.95, 17.575, 0], ix: 1 },
//             s: { a: 0, k: [100, 100, 100], ix: 6 }
//           },
//           ao: 0,
//           shapes: [
//             {
//               ty: 'gr',
//               it: [
//                 {
//                   ind: 0,
//                   ty: 'sh',
//                   ix: 1,
//                   ks: {
//                     a: 0,
//                     k: {
//                       i: [
//                         [0, 0],
//                         [0, 0],
//                         [0, 0],
//                         [0, 0]
//                       ],
//                       o: [
//                         [0, 0],
//                         [0, 0],
//                         [0, 0],
//                         [0, 0]
//                       ],
//                       v: [
//                         [71.7, 17.325],
//                         [-71.7, 17.325],
//                         [-71.7, -17.325],
//                         [71.7, -17.325]
//                       ],
//                       c: true
//                     },
//                     ix: 2
//                   },
//                   nm: 'Pfad 1',
//                   mn: 'ADBE Vector Shape - Group',
//                   hd: false
//                 },
//                 {
//                   ty: 'fl',
//                   c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
//                   o: { a: 0, k: 100, ix: 5 },
//                   r: 1,
//                   bm: 0,
//                   nm: 'Fläche 1',
//                   mn: 'ADBE Vector Graphic - Fill',
//                   hd: false
//                 },
//                 {
//                   ty: 'tr',
//                   p: { a: 0, k: [71.95, 17.575], ix: 2 },
//                   a: { a: 0, k: [0, 0], ix: 1 },
//                   s: { a: 0, k: [100, 100], ix: 3 },
//                   r: { a: 0, k: 0, ix: 6 },
//                   o: { a: 0, k: 100, ix: 7 },
//                   sk: { a: 0, k: 0, ix: 4 },
//                   sa: { a: 0, k: 0, ix: 5 },
//                   nm: 'Transformieren'
//                 }
//               ],
//               nm: 'Gruppe 1',
//               np: 2,
//               cix: 2,
//               bm: 0,
//               ix: 1,
//               mn: 'ADBE Vector Group',
//               hd: false
//             }
//           ],
//           ip: 0,
//           op: 36,
//           st: 0,
//           bm: 0
//         },
//         {
//           ddd: 0,
//           ind: 3,
//           ty: 4,
//           nm: 'Ebene 1 Konturen',
//           tt: 1,
//           sr: 1,
//           ks: {
//             o: { a: 0, k: 100, ix: 11 },
//             r: { a: 0, k: 0, ix: 10 },
//             p: {
//               a: 1,
//               k: [
//                 {
//                   i: { x: 0.29, y: 1 },
//                   o: { x: 0.54, y: 0 },
//                   t: 0,
//                   s: [-2.1, 177.585, 0],
//                   to: [48.25, 0, 0],
//                   ti: [-48.25, 0, 0]
//                 },
//                 { t: 36, s: [287.4, 177.585, 0] }
//               ],
//               ix: 2
//             },
//             a: { a: 0, k: [71.95, 17.575, 0], ix: 1 },
//             s: { a: 0, k: [100, 100, 100], ix: 6 }
//           },
//           ao: 0,
//           shapes: [
//             {
//               ty: 'gr',
//               it: [
//                 {
//                   ind: 0,
//                   ty: 'sh',
//                   ix: 1,
//                   ks: {
//                     a: 0,
//                     k: {
//                       i: [
//                         [0, 0],
//                         [0, 0],
//                         [0, 0],
//                         [0, 0]
//                       ],
//                       o: [
//                         [0, 0],
//                         [0, 0],
//                         [0, 0],
//                         [0, 0]
//                       ],
//                       v: [
//                         [71.7, 17.325],
//                         [-71.7, 17.325],
//                         [-71.7, -17.325],
//                         [71.7, -17.325]
//                       ],
//                       c: true
//                     },
//                     ix: 2
//                   },
//                   nm: 'Pfad 1',
//                   mn: 'ADBE Vector Shape - Group',
//                   hd: false
//                 },
//                 {
//                   ty: 'fl',
//                   c: {
//                     a: 0,
//                     k: [
//                       0.035294117647,
//                       0.364705882353,
//                       0.301960784314,
//                       1
//                     ],
//                     ix: 4
//                   },
//                   o: { a: 0, k: 100, ix: 5 },
//                   r: 1,
//                   bm: 0,
//                   nm: 'Fläche 1',
//                   mn: 'ADBE Vector Graphic - Fill',
//                   hd: false
//                 },
//                 {
//                   ty: 'tr',
//                   p: { a: 0, k: [71.95, 17.575], ix: 2 },
//                   a: { a: 0, k: [0, 0], ix: 1 },
//                   s: { a: 0, k: [100, 100], ix: 3 },
//                   r: { a: 0, k: 0, ix: 6 },
//                   o: { a: 0, k: 100, ix: 7 },
//                   sk: { a: 0, k: 0, ix: 4 },
//                   sa: { a: 0, k: 0, ix: 5 },
//                   nm: 'Transformieren'
//                 }
//               ],
//               nm: 'Gruppe 1',
//               np: 2,
//               cix: 2,
//               bm: 0,
//               ix: 1,
//               mn: 'ADBE Vector Group',
//               hd: false
//             }
//           ],
//           ip: 0,
//           op: 36,
//           st: 0,
//           bm: 0
//         }
//       ],
//       markers: []
//     },
//     loop: true,
//     width: '30%'
//   }
// }
