import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ComponentWithChildrenAndVariants } from '../../models/models';

const TopNavigation: React.FC<ComponentWithChildrenAndVariants> = ({
  children
  // variant='main'
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.layoutShim} />
      <header className={classes.root}>{children}</header>
    </>
  );
};

export default TopNavigation;

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        background: theme.palette.background.default,
        padding: theme.spacing(0.5, 2),
        height: '56px',
        boxSizing: 'border-box',
        boxShadow: theme.shadows[4],
        gridArea: 'TopNav',
        position: 'fixed',
        width: '100%',
        zIndex: 5
      },
      // YCF-563 - pushes Layout's second row down.
      layoutShim: {
        height: '56px',
        width: '1px'
      }
    }),
  { name: 'Mui-TopNavigation' }
);
