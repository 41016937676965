import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

type StepProps = {
  /**
   * label displayed inside step shape (circle as default)
   */
  defaultLabel?: string;
  /**
   * icon displayed as default state
   */
  defaultIcon?: string;
  /**
   * value located in the top of the card.
   */
  completedIcon?: string;
  /**
    * value located in the bottom of the card.
    */
  completedLabel?: string;
  /**
   * value located in the bottom of the card.
   */
  stepLabel?: string;
  /**
   * boolean for completed/active state of step
   */
  isCompleted: boolean;
};

export interface Props extends ComponentBase {
  /**
   * stepper orientation.
   */
  orientation?: 'vertical' | 'horizontal';
  /**
   * icon displayed as default state
   */
  defaultIcon?: string;
  /**
   * value located in the top of the card.
   */
  completedIcon?: string;
  /**
   * value located in the bottom of the card.
   */
  stepLabelPosition?: string;
  /**
   * arguments for each step
   */
  stepProps: StepProps[];
}

const Stepper: React.FC<Props> = ({
  // TODO: implement this:
  // orientation = 'vertical',
  // defaultIcon,
  // completedIcon,
  // stepLabelPosition = 'right',
  stepProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {
        stepProps.map(step => {
          return(
            <div className={classes.step}>
              <div className={clsx(classes.dot, {
                [classes.completed]: step.isCompleted
              })}
              />
              <div className={classes.line} />
            </div>
          );
        })
      }
    </div>
  );
};

const useStyles = makeStyles<Theme>(
  // @ts-ignore Mui bug with spreading theme variables.
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
      step: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:last-child': {
          flex: 0
        }
      },
      line: {
        width: '2px',
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        flex: 1
      },
      dot: {
        height: '12px',
        width: '12px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        border: `solid 2px ${theme.palette.primary.main}`,
        boxSizing: 'border-box'
      },
      completed: {
        backgroundColor: theme.palette.primary.main
      }
    }),
  { name: 'Mui-Stepper' }
);

export default Stepper;
