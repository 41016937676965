import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ComponentBase } from '../../../models/models';
import CardAtom, { Props as CardAtomProps } from '../../atoms/CardAtom';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';

export interface Props extends ComponentBase {
  /**
   * multi or single select
   */
  selectType?: 'single' | 'multiple' | 'none';
  /**
   * variant changes the way the card list is displayed
   */
  variant?: 'single' | 'grouped' | 'stacked' | 'expandedGroup';
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid: string;
  /**
   * array of cards, each with a uuid, values, chip props, etc.
   */
  cardOptions: CardAtomProps[];
  /**
   * If false (default) & single select, finishStep will be triggered onClick
   */
  confirmationRequired?: boolean;
}

// You can use this type in the api translation
export interface ResponseValue extends CardAtomProps {
  value: boolean;
}

const CardList: React.FC<Props> = ({
  selectType = 'none',
  variant = 'single',
  uuid,
  cardOptions,
  confirmationRequired = false
}) => {
  const {
    registerResponseComponent,
    logResponse,
    finishStep
  } = useRadioDispatch();
  const radioState = useRadioState();
  const initValue = React.useMemo(() => {
    return (radioState.apiData.responses?.[uuid] as Record<string, ResponseValue>) || {};
  }, [radioState.apiData.responses, uuid]);

 
  // hook is a Set type in order to make it easy & fast to add/remove selected strings and have no duplicates
  const [selectValue, setSelectValue] = React.useState<Record<string, ResponseValue>>(
    initValue
  );
  const classes = useStyles();

  React.useEffect(() => {
    if (selectType !== 'none' && uuid) {
      const responseObjInit: Record<string, ResponseValue> = {};
      if (selectType === 'multiple') {
        cardOptions.forEach((card) => {
          responseObjInit[card.uuid] = {
            ...card,
            value: false
          };
        });
        if (Object.keys(initValue).length === 0) {
          setSelectValue(responseObjInit);
        }
      }
      const responseValueInit =
        selectType === 'multiple' ? responseObjInit : null;

      registerResponseComponent({
        componentType: 'selectCardList_TEMP', // CardList works just like select
        componentId: uuid,
        value: Object.keys(initValue).length > 0 ? initValue : responseValueInit
      });
    }
  }, [registerResponseComponent, uuid, selectType, initValue, cardOptions]);

  const handleClick = (cardUuid: string) => {
    if (selectType === 'multiple') {
      setSelectValue((prevState) => {
        const newState = {
          ...prevState,
          [cardUuid]: {
            ...prevState[cardUuid],
            value: !prevState[cardUuid].value
          }
        };
        logResponse({
          componentId: uuid,
          value: newState
        });
        return newState;
      });
    } else {
      // if single select only allow one response:
      setSelectValue((prevState) => {
        const newState: Record<string, ResponseValue> = {};
        if (!prevState.cardUuid) {
          cardOptions.forEach((card) => {
            if (card.uuid === cardUuid) {
              newState[cardUuid] = {
                ...card,
                value: true
              };
            }
          });
        }

        logResponse({
          componentId: uuid,
          value: newState
        });
        return newState;
      });

      // Trigger complete call if confirmationRequired === false
      !confirmationRequired && finishStep();
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.grouped]: variant === 'grouped',
        [classes.expandedGroup]: variant === 'expandedGroup'
      })}
    >
      {cardOptions.map((card, index) => {
        return (
          <div
            className={clsx(classes.cardContainer, {
              [classes.single]: variant === 'single',
              [classes.stacked]: variant === 'stacked',
              [classes.groupedCardContainer]:
                (variant === 'expandedGroup' || variant === 'grouped') &&
                index < cardOptions.length - 1
            })}
            key={card.uuid}
          >
            <CardAtom
              {...card}
              isSelectable={selectType !== 'none'}
              variant={variant}
              tabIndex={index}
              handleClick={
                selectType !== 'none' ? () => handleClick(card.uuid) : undefined
              }
              checked={selectValue[card.uuid]?.value}
            />
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: {},
      cardContainer: {},
      single: {
        paddingBottom: '12px'
      },
      grouped: {
        border: `1px solid ${theme.palette.grey[600]}`,
        padding: '0 16px',
        borderRadius: '8px'
        // marginTop: '-1px',
        // '&:first-child': {
        //   '& div': {
        //     borderRadius: '8px 8px 0 0'
        //   }
        // },
        // '&:last-child': {
        //   '& div': {
        //     borderRadius: '0 0 8px 8px'
        //   }
        // }
      },
      stacked: {
        paddingBottom: '8px'
      },
      expandedGroup: {
        border: `1px solid ${theme.palette.grey[600]}`,
        padding: '0 16px',
        borderRadius: '0 0 8px 8px'
        // marginTop: '-2px',
        // '&:last-child': {
        //   '& div': {
        //     borderRadius: '0 0 8px 8px'
        //   }
        // }
      },
      groupedCardContainer: {
        borderBottom: `1px solid ${theme.palette.grey[600]}`
      }
    }),
  { name: 'Mui-CardList' }
);

export default CardList;
