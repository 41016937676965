import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import parse from 'html-react-parser';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { ComponentBase } from '../../models/models';


export interface Props extends ComponentBase {
  /**
   * If true accordion is extended
   */
  isOpen?: boolean;
  /**
   * SVG element use as an icon
   */
  expandIcon?: string;
  /**
   * Text as headline to accordion
   */
  headline: string;
  /**
   * Text inside Accordion
   */
  body?: string;
  /**
   * Function triggered when expand button is clicked
   */
  handleClick?: () => void;
  /**
   * If true button will be disabled.
   */
  disabled?: boolean;
  /**
   * If true rounded corners are disabled.
   */
  square?: boolean;
}

const AccordionAtom: React.FC<Props> = ({
  isOpen,
  expandIcon,
  headline,
  body,
  handleClick,
  disabled,
  square
}) => {
  const classes = useStyles();

  return (
    <Accordion
      classes={{
        disabled: classes.disabled,
        expanded: classes.expanded,
        rounded: classes.corners
      }}
      className={classes.root}
      disabled={disabled}
      onClick={handleClick}
      square={square}
      expanded={isOpen}
    >
      <AccordionSummary
        expandIcon={expandIcon ? parse(expandIcon) : <ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.headlineContainer}
      >
        <p className={classes.headline}>
          {headline}
        </p>
      </AccordionSummary>
      <AccordionDetails className={classes.bodyContainer}>
        <p className={classes.body}>
          {body && parse(body)}
        </p>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles<Theme>(
  // @ts-ignore Mui bug with spreading theme variables.
  (theme) =>
    createStyles({
      root: {
        color: theme.palette.primary.main
      },
      disabled: {
      },
      expanded: {},
      corners: {},
      headlineContainer: {},
      headline: {
        margin: 4,
        ...theme.typography.body1,
      },
      bodyContainer: {},
      body: {
        margin: 0,
        ...theme.typography.body2,
      },
    }),
  { name: 'Mui-AccordionAtom' }
);

export default AccordionAtom;
