import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';
import CardAtom, { Props as CardAtomProps } from './CardAtom';
import CardList from '../Response/CardList/CardList';

export interface Props extends ComponentBase {
  /**
   * multi or single select
   */
  selectType?: 'single' | 'multiple' | 'none';
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid: string;
  /**
   * Properties for the cover card.
   */
  cardCover: CardAtomProps;
  /**
   * array of cards, each with a uuid, values, chip props, etc.
   */
  cardGroup: CardAtomProps[];
  /**
   * Function triggered when card is selected
   */
  handleSelectClick?: () => void;
  /**
   * tabIndex for accessibility
   */
  tabIndex?: number;
  /**
   * If true component is checked. Only applicable if isMultiSelect is true
   */
  checked?: boolean;
  /**
   * If true component will render in its expanded state
   */
  defaultStateOpened?: boolean;
  /**
   * If true component will render expanded and without a button to collapse
   */
  isNonCollapsible?: boolean;
}

const ArrowDown = () => (
  <svg
    data-testid="arrowDown"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    style={{ paddingTop: '2px' }}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
  </svg>
);

const ArrowUp = () => (
  <svg
    data-testid="arrowUp"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
  </svg>
);

type ExpandedType = {
  isExpanded: boolean;
};

const ExpandableCard: React.FC<Props> = ({
  selectType = 'none',
  uuid,
  cardGroup,
  cardCover,
  handleSelectClick,
  tabIndex,
  checked,
  defaultStateOpened = false,
  isNonCollapsible = false
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(defaultStateOpened || isNonCollapsible);
  const classes = useStyles({ isExpanded });

  const handleExpandClick = () => setIsExpanded(!isExpanded);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      handleExpandClick();
    }
  };

  return (
    <div className={clsx(classes.root, {
      [classes.expandedRootContainer]: isExpanded
    })}
    >
      <div
        className={clsx(classes.coverCardContainer, {
          [classes.clickable]: (selectType === 'none' && !isNonCollapsible)
        })}
        // whole container is clickable if the cover card is not, otherwise only expand button is clickable
        role={(selectType === 'none' && !isNonCollapsible) ? 'button' : undefined}
        tabIndex={(selectType === 'none' && !isNonCollapsible) ? tabIndex : undefined}
        onKeyPress={(selectType === 'none' && !isNonCollapsible) ? handleKeyPress : undefined}
        onClick={(selectType === 'none' && !isNonCollapsible) ? handleExpandClick : undefined}
      >
        <CardAtom
          {...cardCover}
          uuid={uuid}
          handleClick={selectType !== 'none' ? handleSelectClick : undefined}
          checked={checked}
          isSelectable={selectType !== 'none'}
          variant={isExpanded ? 'cover' : 'stacked'}
          tabIndex={tabIndex}
        />
        <button
          type="button"
          aria-label={isExpanded ? 'close button' : 'open button'}
          className={clsx(classes.expandButton, {
            [classes.hiddenButton]: (isNonCollapsible)
          })}
          // whole container is clickable if the cover card is not, otherwise only expand button is clickable
          onClick={selectType !== 'none' ? handleExpandClick : undefined}
        >
          {isExpanded ? <ArrowUp /> : <ArrowDown />}
        </button>
      </div>
      {isExpanded && (
        <div className={classes.expandedGroupContainer}>
          <CardList
            variant="expandedGroup"
            uuid={uuid}
            cardOptions={cardGroup}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, ExpandedType>(
  (theme) =>
    createStyles({
      root: {
        marginBottom: ({ isExpanded }) => (isExpanded ? '14px' : '4px')
      },
      expandedRootContainer: {},
      coverCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      clickable: {
        cursor: 'pointer'
      },
      expandButton: {
        // remove default styles
        padding: '0',
        cursor: 'pointer',
        outline: 'inherit',
        // actual styles
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.grey[600]}`,
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        marginTop: '-16px'
      },
      hiddenButton: {
        visibility: 'hidden'
      },
      expandedGroupContainer: {
        marginTop: '-17px'
      }
    }),
  { name: 'Mui-ExpandableCard' }
);

export default ExpandableCard;
