import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import { VerifyChannels } from './index';

type Props = {
  to: string;
  availableChannels: VerifyChannels[];
  onChannelSelect: (channel: VerifyChannels) => void;
  error?: string;
  classes?: Record<string, string>;
};

const CHANNEL_TO_LABEL = {
  sms: 'Verify by text message',
  call: 'Verify by phone call',
  email: 'Verify by email'
};

const SceneIntro: React.FC<Props> = ({
  to,
  availableChannels,
  onChannelSelect,
  error = '',
  classes = {}
}) => {
  return (
    <div className={clsx(classes.root, classes.scene)}>
      <p className={classes.textDirective}>
        {`To verify ${to} we'll need to send you a one-time use 6-digit code.`}
      </p>

      {error && <p className={classes.textError}>{error}</p>}

      <footer className={classes.footer}>
        {availableChannels.map((channel) => (
          <Button
            variant="contained"
            color="primary"
            disableRipple
            key={channel}
            onClick={() => onChannelSelect(channel)}
            className={classes.button}
          >
            {CHANNEL_TO_LABEL[channel]}
          </Button>
        ))}
      </footer>
    </div>
  );
};

export default SceneIntro;
