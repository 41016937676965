import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';
import { useRadioDispatch } from '../Core/radioContext';

export interface Props extends ComponentBase {
  /**
   * Description text.
   */
  description?: string;
  /**
   * Link label text.
   */
  linkLabel?: string;
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid: string;
}

const ResponseLink: React.FC<Props> = (props) => {
  const { description, uuid, linkLabel } = props;

  const { logResponseAndComplete } = useRadioDispatch();

  // @ts-ignore Mui bug with spreading theme variables.
  const classes = useStyles();

  const handleClick = () => {
    logResponseAndComplete({
      componentId: uuid
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      logResponseAndComplete({
        componentId: uuid
      });
    }
  };

  return (
    <p className={classes.root}>
      {description && description}
      {linkLabel && (
        <span
          role="link"
          tabIndex={0}
          onKeyPress={handleKeyPress}
          onClick={handleClick}
          className={classes.link}
        >
          {linkLabel}
        </span>
      )}
    </p>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        ...theme.typography.body1,
        width: '100%'
      },
      link: {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        pointerEvents: 'all',
        '&:focus-visible': {
          outline: '3px solid #005FCC' // dev-defined a11y color
        },
        '&:-moz-focusring': {
          outline: '3px solid #005FCC' // dev-defined a11y color
        }
      }
    }),
  { name: 'Mui-ResponseLink' }
);

export default ResponseLink;
