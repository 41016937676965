import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { ComponentBase } from '../../models/models';



export interface Props extends ComponentBase {
  /**
   * Body text.
   */
  body: string,
  /**
   * Text on button.
   */
  buttonLabel: string,
  /**
   * On click action.
   */
  handleClick: ()=>void,
  /**
   * Notification open state.
   */
  isOpen: boolean,
  /**
   * On close action.
   */
  handleCloseNotification: ()=>void,
}


const SnackbarLongBase: React.FC<Props> = (props) => {
  const { body, buttonLabel, handleClick, isOpen, handleCloseNotification
  } =  props;
  const classes = useStyles();

  const action = (
    <Button variant="outlined" size="small" className={classes.actionButton} onClick={() => handleClick()}>
      {buttonLabel}
    </Button>
  );

  return (
    <Snackbar 
      open={isOpen}
      onClose={handleCloseNotification}
      className={classes.root}
    >
      <SnackbarContent
        className={classes.snackbar}
        message={(
          <div className={classes.body}>
            <ReportProblemOutlinedIcon className={classes.bodyIcon} />
            <p className={classes.bodyMessage}>{body}</p>
          </div>
        )}
        action={action}
      />
    </Snackbar>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    snackbar:{
      padding: theme.spacing(2)
    },
    body: {
      display: 'flex',
      alignItems: 'center'
    },
    bodyIcon: {
      padding: theme.spacing(1, 1.5, 1, 0)
    },
    bodyMessage: {
      margin: 0
    },
    actionButton: {
      color: 'inherit',
      borderColor: 'currentcolor',
      textTransform: 'none',
      cursor: 'pointer',
      marginRight: theme.spacing(1)
    }
  }), { name: 'Mui-SnackbarLongBase' }
);

export default SnackbarLongBase;